import { getCurrentUserCards } from '../../util/api';

const initialState = {
  cards: [],
  cardsInProgress: false,
  cardsError: null,
};

const FETCH_CARDS = 'app/CardsPage/FETCH_CARDS';
const FETCH_CARDS_SUCCESS = 'app/CardsPage/FETCH_CARDS_SUCCESS';
const FETCH_CARDS_FAIL = 'app/CardsPage/FETCH_CARDS_FAIL';

export default function cardsPageReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_CARDS:
      return {
        ...state,
        cardsInProgress: true,
        cardsError: null,
      };
    case FETCH_CARDS_SUCCESS:
      return {
        ...state,
        cards: payload,
        cardsInProgress: false,
        cardsError: null,
      };
    case FETCH_CARDS_FAIL:
      return {
        ...state,
        cardsInProgress: false,
        cardsError: payload,
      };
    default:
      return state;
  }
}

export const fetchCards = (params, search) => dispatch => {
  const isBrowser = typeof window != 'undefined';
  if (!isBrowser) return Promise.resolve();

  dispatch({ type: FETCH_CARDS });
  return getCurrentUserCards()
    .then(res => {
      console.log('res', res);
      dispatch({ type: FETCH_CARDS_SUCCESS, payload: res.cards });
    })
    .catch(err => {
      const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
      dispatch({ type: FETCH_CARDS_FAIL, payload: errors });
    });
};

export const loadData = (params, search) => {
  return fetchCards(params, search);
};
