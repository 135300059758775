import React, { useLayoutEffect, useState } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  IconSpinner,
  Loading,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import css from './MangopayPaymentMethodsPage.module.css';
import UserInfoForm from './forms/UserInfoForm';
import {
  registerCard,
  setUpMangoPayPaymentMethodWithAPI,
  updateCardRegistrationData,
} from '../../util/api';
import Steps from 'rc-steps';
import AddCardForm from './forms/AddCardForm';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import SuccessTip from '../MangopayPayoutPage/SuccessTip';
import { fetchCards } from '../CardsPage/CardsPage.duck';

export const PAYMENT_STEPS = {
  CREATE_USER: 'CREATE_USER',
  CREATE_WALLET: 'CREATE_WALLET',
  ADD_CARD: 'ADD_CARD',
};

const MangopayPaymentMethodsPageComponent = props => {
  const {
    currentUser: user,
    scrollingDisabled,
    userLoading,
    onManageDisableScrolling,
    intl,
    cards,
    cardsInProgress,
  } = props;

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [mangopayConnectError, setMangopayConnectError] = useState(null);
  const currentUser = ensureCurrentUser(user);
  const dispatch = useDispatch();

  const steps_completed = currentUser.attributes?.profile?.metadata?.payer_steps_completed ?? [];
  const mangopay_address =
    currentUser.attributes?.profile?.privateData?.payer_mangopay_address ?? {};

  const initialValues = { ...mangopay_address };

  const lastStep = steps_completed.length > 0 ? steps_completed[steps_completed.length - 1] : null;

  useLayoutEffect(() => {
    switch (lastStep) {
      case PAYMENT_STEPS.CREATE_WALLET:
        setActive(1);
        break;
      case PAYMENT_STEPS.ADD_CARD:
        setActive(1);
        break;
      default:
        setActive(0);
        break;
    }
  }, [user]);

  const cardAdded = steps_completed.includes(PAYMENT_STEPS.ADD_CARD);

  const title = intl.formatMessage({ id: 'PaymentMethodsPage.title' });

  const currentUserLoaded = !!currentUser.id;

  const step1Label = intl.formatMessage({ id: 'MangopayPaymentMethodsPage.step1Label' });
  const step2Label = intl.formatMessage({ id: 'MangopayPaymentMethodsPage.step2Label' });
  const cardAddedMessage = intl.formatMessage({
    id: 'MangopayPaymentMethodsPage.cardAddedMessage',
  });
  const loadingText = intl.formatMessage({ id: 'MangopayPaymentMethodsPage.loadingText' });
  const loadingCardsText = intl.formatMessage({
    id: 'MangopayPaymentMethodsPage.loadingCardsText',
  });

  const onSubmitToAPI = values => {
    setLoading(true);
    setMangopayConnectError(null);
    setUpMangoPayPaymentMethodWithAPI(values)
      .then(res => {
        setActive(prev => prev + 1);
        dispatch(fetchCurrentUser());
      })
      .catch(err => {
        const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
        setMangopayConnectError(errors);
      })
      .finally(() => setLoading(false));
  };

  const registerCardWithAPI = async values => {
    try {
      const response = await registerCard();
      return Promise.resolve({ ...values, ...response });
    } catch (err) {
      return Promise.reject(err);
    }
  };
  const submitCardDetailsToMangopayAPI = async values => {
    let {
      Id,
      AccessKey,
      PreregistrationData,
      CardRegistrationURL,
      cardNumber,
      cardExpirationDate,
      cardCvx,
    } = values;
    cardNumber = cardNumber.replace(/ /g, '');
    cardExpirationDate = cardExpirationDate.replace(/\//g, '');

    const formBody = new URLSearchParams({
      cardNumber,
      cardExpirationDate,
      cardCvx,
      accessKeyRef: AccessKey,
      data: PreregistrationData,
    }).toString();

    try {
      const apiResp = await fetch(CardRegistrationURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody,
      });
      if (apiResp.status >= 400) {
        return apiResp.json().then(data => {
          let e = new Error();
          e = Object.assign(e, data);

          throw e;
        });
      }
      const data = await apiResp.text();
      return Promise.resolve({ RegistrationData: data, Id });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const updatePreRegistrationDataWithAPI = values =>
    updateCardRegistrationData(values)
      .then(res => Promise.resolve(res))
      .catch(err => Promise.reject(err));

  const cardRelatedstepsForPayerWallet = values => {
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handleCardRegistration = composeAsync(
      registerCardWithAPI,
      submitCardDetailsToMangopayAPI,
      updatePreRegistrationDataWithAPI
    );

    return handleCardRegistration(values);
  };

  const onAddCardSubmitToAPI = values => {
    setLoading(true);
    setMangopayConnectError(null);
    cardRelatedstepsForPayerWallet(values)
      .then(() => {
        dispatch(fetchCurrentUser());
        dispatch(fetchCards());
        setShowForm(false);
      })
      .catch(err => {
        const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
        setMangopayConnectError(errors);
      })
      .finally(() => setLoading(false));
  };

  const step2Form = cardAdded ? (
    cardsInProgress ? (
      <Loading loadingText={loadingCardsText} />
    ) : (
      <>
        {cards.length > 0 ? (
          <div className="shadow-md rounded-md bg-white border border-gray-200 border-solid p-4">
            <h3 className="my-0 text-base text-black font-bold tracking-wide leading-snug">
              {intl.formatMessage({ id: 'MangopayPaymentMethodsPage.cardsAddedTitle' })}
            </h3>
            <select className={css.cardSelector} id="card-selector" name="card-selector">
              {cards.map(card => (
                <option value={card.card_id} key={card.card_id}>
                  {card.label}
                </option>
              ))}
            </select>
            {!showForm && (
              <button
                type="button"
                onClick={() => setShowForm(true)}
                className="bg-marketplaceColor text-white border-0 px-4 py-2 font-semibold shadow-md rounded hover:bg-marketplaceColorDark transition-colors ease-in-out duration-150 hover:cursor-pointer"
              >
                {intl.formatMessage({ id: 'MangopayPaymentMethodsPage.addAnotherCard' })}
              </button>
            )}
          </div>
        ) : null}
        {showForm && (
          <div className="rounded-md bg-white border border-gray-200 border-dashed p-4 mt-4">
            <h3 className="my-0 text-base mb-2 text-black font-bold tracking-wide leading-snug">
              {intl.formatMessage({ id: 'MangopayPaymentMethodsPage.cardDetailsFormHeading' })}
            </h3>
            <AddCardForm
              onSubmit={onAddCardSubmitToAPI}
              inProgress={loading}
              mangopayConnectError={mangopayConnectError}
            />
          </div>
        )}
      </>
    )
  ) : // <SuccessTip message={cardAddedMessage} />
  active == 1 ? (
    <AddCardForm
      onSubmit={onAddCardSubmitToAPI}
      inProgress={loading}
      mangopayConnectError={mangopayConnectError}
    />
  ) : null;

  const steps = (
    <Steps current={active} direction="vertical">
      <Steps.Step
        title={step1Label}
        description={
          active == 0 ? (
            <UserInfoForm
              onSubmit={onSubmitToAPI}
              inProgress={loading}
              mangopayConnectError={mangopayConnectError}
              intl={intl}
            />
          ) : null
        }
      />
      <Steps.Step title={step2Label} description={step2Form} />
    </Steps>
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="MangopayPaymentMethodsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="MangopayPaymentMethodsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="MangopayPaymentMethodsPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="PaymentMethodsPage.heading" />
            </h1>
            {userLoading ? (
              <div className="flex items-center gap-4">
                <IconSpinner /> <span>{loadingText}</span>
              </div>
            ) : (
              steps
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

MangopayPaymentMethodsPageComponent.defaultProps = {
  currentUser: null,
};

MangopayPaymentMethodsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, userLoading } = state.user;
  const { cards, cardsInProgress } = state.CardsPage;

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    userLoading,
    cards,
    cardsInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const MangopayPaymentMethodsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MangopayPaymentMethodsPageComponent);

export default MangopayPaymentMethodsPage;

// registerCard()
//       .then(res => {
//         const { Id, AccessKey, PreregistrationData, CardRegistrationURL } = res;
//         let { cardNumber, cardExpirationDate, cardCvx } = values;
//         cardNumber = cardNumber.replace(/ /g, '');
//         cardExpirationDate = cardExpirationDate.replace(/\//g, '');

//         const formBody = new URLSearchParams({
//           cardNumber,
//           cardExpirationDate,
//           cardCvx,
//           accessKeyRef: AccessKey,
//           data: PreregistrationData,
//         }).toString();
//         fetch(CardRegistrationURL, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//           },
//           body: formBody,
//         })
//           .then(apiResp => {
//             if (apiResp.status >= 400) {
//               return apiResp.json().then(data => {
//                 let e = new Error();
//                 e = Object.assign(e, data);

//                 throw e;
//               });
//             }

//             return apiResp.text();
//           })
//           .then(cardData => {
//             updateCardRegistrationData({ RegistrationData: cardData, Id })
//               .then(finalResp => {
//                 console.log(finalResp);
//               })
//               .catch(err => {
//                 console.log(err);
//               });
//           })
//           .catch(err => {
//             console.log(err);
//           });
//       })
//       .catch(err => {
//         console.log(err);
//         const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
//         setMangopayConnectError(errors);
//       })
//       .finally(() => setLoading(false));
