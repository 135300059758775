import React, { useState, useEffect } from 'react';
import { injectIntl } from '../../util/reactIntl';
import css from './ChangeLanguageForm.module.css';
import { useDispatch } from 'react-redux';
import { updateProfileLanguage } from '../../ducks/user.duck';
import { Helmet } from 'react-helmet';
const ChangeLanguageFormComponent = props => {
  const { intl } = props;
  const [value, setValue] = useState('french');
  const { LandingPage } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'french';
    if (cookieValue == 'english') {
      setValue('english');
    } else {
      setValue('french');
    }
  }, []);

  const handleOnChange = e => {
    const selectedOption = e.target.value;
    setValue(selectedOption);
    dispatch(updateProfileLanguage(selectedOption));
    // document.cookie = 'language=' + selectedOption;
    // window.location.reload(true);
  };
  const englishLanguage = intl.formatMessage({
    id: 'ChangeLanguageForm.englishLanguage',
  });
  const frenchLanguage = intl.formatMessage({
    id: 'ChangeLanguageForm.frenchLanguage',
  });
  return (
    <div>
      <Helmet htmlAttributes={{ lang: value == 'english' ? 'en' : 'fr' }} />
      <select
        className={LandingPage ? css.normalSelectLandingPage : css.normalSelect}
        onChange={e => handleOnChange(e)}
      >
        <option value="english" selected={value == 'english' ? true : false}>
          {englishLanguage}
        </option>
        <option value="french" selected={value === 'french' ? true : false}>
          {frenchLanguage}
        </option>
      </select>
    </div>
  );
};

const ChangeLanguageForm = injectIntl(ChangeLanguageFormComponent);

export default ChangeLanguageForm;
