import { fetchCurrentUser } from '../../ducks/user.duck';
import { showFavouriteSellers } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

export const FETCH_FAVOURITES_REQUEST = 'app/SellerFavouritePage/FETCH_FAVOURITES_REQUEST';
export const FETCH_FAVOURITES_SUCCESS = 'app/SellerFavouritePage/FETCH_FAVOURITES_SUCCESS';
export const FETCH_FAVOURITES_ERROR = 'app/SellerFavouritePage/FETCH_FAVOURITES_ERROR';

const initialState = {
  favouritiesLoading: false,
  favouritiesError: null,
  favourities: [],
  pagination: null,
};

const SellerFavouritePage = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FAVOURITES_REQUEST:
      return {
        ...state,
        favouritiesLoading: true,
        favouritiesError: null,
      };
    case FETCH_FAVOURITES_SUCCESS:
      return {
        ...state,
        favouritiesLoading: false,
        favourities: payload?.data,
        pagination: payload?.data?.meta,
      };
    case FETCH_FAVOURITES_ERROR:
      return {
        ...state,
        favouritiesLoading: false,
        favouritiesError: payload,
      };
    default:
      return state;
  }
};

export default SellerFavouritePage;

export const fetchFavouritesRequest = () => ({
  type: FETCH_FAVOURITES_REQUEST,
});

export const fetchFavouritesSuccess = payload => ({
  type: FETCH_FAVOURITES_SUCCESS,
  payload,
});

export const fetchFavouritesError = payload => ({
  type: FETCH_FAVOURITES_ERROR,
  payload,
});

export const fetchFavourites = search => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1 } = queryParams;
  dispatch(fetchFavouritesRequest());
  return showFavouriteSellers({ page })
    .then(response => {
      console.log('response', response);
      dispatch(fetchFavouritesSuccess(response));
      dispatch(fetchCurrentUser());
      return response;
    })
    .catch(e => {
      dispatch(fetchFavouritesError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  if (typeof window !== 'undefined') {
    return dispatch(fetchFavourites(search));
  }
};
