import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSuccess } from '../../components';
import css from './SuccessStep.module.css';

function SuccessStep({ message }) {
  return (
    <div>
      <div className={classNames(css.verificiationBox, css.verficiationSuccessBox)}>
        <div
          className={classNames(
            css.verificiationBoxTextWrapper,
            css.verificationBoxSuccessTextWrapper
          )}
        >
          <div className={css.verificationBoxTitle}>
            <IconSuccess className={css.icon} />
            <span>{message}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessStep;
