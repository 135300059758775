import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './MangopayPolicy.module.css';
import MangoEng from './MangoEng';
import MangoFr from './MangoFr';

const MangopayPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  let cookieValue;

  if (typeof window !== 'undefined') {
    cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'french';
  } else {
    return null;
  }
  return (
    <div className={classes}>
      {cookieValue == 'english' ? <MangoEng classes={classes} /> : <MangoFr />}
    </div>
  );
};

MangopayPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

MangopayPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default MangopayPolicy;
