import React from 'react';
import css from './MaintenancePage.module.css';
import imageOne from '../../assets/maintainceOne.png';
import imageTwo from '../../assets/maintainaceTwo.png';
import { BsInstagram } from 'react-icons/bs';
const MaintenancePage = () => {
  return (
    <div className={css.container}>
      <div className={css.imageOne}>
        <img src={imageOne} alt="imageOne" className={css.mainImage} />
      </div>
      <div>
        <h1 className={css.heading}>Ouverture prochaine ?</h1>
        <p className={css.subHeading}>Vous êtes passionné(e)s de couture ?</p>
      </div>
      <div className={css.imageTwo}>
        <img src={imageTwo} alt="imageTwo" className={css.mainImage} />
      </div>
      <div>
        <h3 className={css.instagramText}>Rendez-vous sur Instagram pour le lancement ...</h3>
        <a href="https://www.instagram.com/sewtoo_officiel" className={css.link}>
          <BsInstagram className={css.icon} />
        </a>
      </div>
    </div>
  );
};

export default MaintenancePage;
