import React from 'react';
import Popup from 'reactjs-popup';

import { GoInfo } from 'react-icons/go';
import { Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './Popup.module.css';
function PopupContainer(props) {
  const { body, showButton = false } = props;
  const contentStyle = {
    maxWidth: '600px',
    width: '90%',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
  };

  return (
    <Popup
      trigger={
        <button className={css.button} type="button">
          <GoInfo className={css.icon} />
        </button>
      }
      modal
      contentStyle={contentStyle}
    >
      {close => (
        <div>
          {showButton ? null : (
            <a className={css.close} onClick={close}>
              &times;
            </a>
          )}
          <div className={css.body}>
            {body}
            {showButton ? (
              <Button
                type="button"
                onClick={() => {
                  close();
                }}
                className={css.toolTipButton}
              >
                <FormattedMessage id="PopupContainer.close" />
                {/* Got it */}
              </Button>
            ) : null}
          </div>
        </div>
      )}
    </Popup>
  );
}

export default PopupContainer;
