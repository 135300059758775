import React from 'react';
import css from './MangopayPolicy.module.css';
const MangoFr = props => {
  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>
        CONTRAT-CADRE D’EMISSION, GESTION, MISE A DISPOSITION DE MONNAIE ELECTRONIQUE MANGOPAY
      </h2>
      <h3 style={{ textAlign: 'center' }}>
        Conditions Générales d’Utilisation de la Monnaie Electronique
      </h3>
      <p style={{ textAlign: 'center' }}>(version pour la France)</p>
      <p style={{ textAlign: 'right' }}>Version du 27/11/2020</p>
      <p>Conclu entre :</p>
      <p>
        Le client du Site, personne morale ou personne physique immatriculée au RCS (ou au
        répertoire national des métiers ou à tout organisme professionnel équivalent) dans un Etat
        membre de l’Union Européenne ou dans un Etat partie à l’Espace Economique européen ou dans
        un pays tiers imposant des obligations équivalentes en termes de lutte contre le blanchiment
        et le financement du terrorisme, agissant pour son compte exclusivement à des fins
        professionnelles (commerciales, industrielles, artisanales ou libérales),
      </p>
      <p style={{ marginLeft: '14rem' }}>
        ci-après dénommé(e) « l’<b>Utilisateur</b> », ou «<b>Utilisateur professionnel</b> »,
      </p>
      <p>ou</p>
      <p>
        Le client du Site, personne physique résidente dans un Etat membre de l’Union Européenne ou
        dans un Etat partie à l’Espace Economique européen ou dans un pays tiers imposant des
        obligations équivalentes en termes de lutte contre le blanchiment et le financement du
        terrorisme, agissant pour son propre compte exclusivement à des fins non professionnelles,
      </p>
      <p style={{ marginLeft: '7rem' }}>
        ci-après dénommé(e) l’« <b>Utilisateur</b> » ou « <b>Utilisateur consommateur</b> », d’une
        part,
      </p>
      <p>et</p>
      <p>
        <b>MANGOPAY SA,</b> société anonyme de droit luxembourgeois, dont le siège social est situé
        2, Avenue Amélie, L-1125 Luxembourg et immatriculée au Registre du commerce et des sociétés
        luxembourgeois sous le numéro B173459, (le montant du capital social est consultable ici :
        <a href="https://www.mangopay.com/fr/mentions-legales/">
          https://www.mangopay.com/fr/mentions-legales/
        </a>
        ) habilitée à exercer son activité au sein de l’Espace Economique Européen, en qualité
        d’établissement de monnaie électronique agréé par la Commission de Surveillance du Secteur
        Financier, 283 route d’Arlon L-1150 Luxembourg, www.cssf.lu,
      </p>
      <p>
        ci-après dénommée l’ « <b>ci-après dénommée l’ </b> », , d’autre part
      </p>
      <p>
        ci-après dénommées séparément une « <b>Partie</b> » ou ensemble les « <b>Parties</b> ». .
      </p>
      <p
        style={{
          backgroundColor: 'rgb(222, 237, 242)',
          border: '1px solid black',
          padding: '10px',
        }}
      >
        <span>
          <u>Avertissement</u>
        </span>
        <br />
        <span>
          L’Utilisateur est invité à lire attentivement le présent Contrat-Cadre qui lui a été
          communiqué par le Distributeur avant de l’accepter. Il est invité à le télécharger sur
          Support durable, selon le procédé qui lui est proposé par le Distributeur. Par défaut, les
          communications avec l’Emetteur sont toujours réalisées par l’intermédiaire du
          Distributeur, selon les modalités prévues dans les Conditions Générales du Site, sauf
          lorsqu’un mode de communication distinct est prévu dans le Contrat.
        </span>
      </p>
      <h2>1. Définitions</h2>
      <p>Pour les besoins des présentes, les termes ci-après sont définis comme suit:</p>
      <p>
        <b>« Authentification » </b>: désigne les procédures définies par le Distributeur afin de
        vérifier l’identité de l’Utilisateur ou la validité d’un Ordre de paiement. Ces procédures
        comprennent l’utilisation des Données de sécurité personnalisées et de Données
        d’identification.
      </p>
      <p>
        <b>« Authentification forte »</b>: désigne les procédures d’Authentification définies par le
        Distributeur et répondant aux exigences de la Directive européenne 2015/2366 du 25 novembre
        2015. Cette Authentification forte comporte notamment des éléments permettant d’établir un
        lien dynamique entre l’Opération, le montant et le Bénéficiaire.
      </p>
      <p>
        <b>« Banques »</b>: désignent les établissements de crédit qui conservent les fonds
        collectés par l’Emetteur correspondant à la Monnaie Electronique en circulation. Les
        établissements retenus sont à ce jour le Crédit Mutuel Arkéa et ING Luxembourg. L’Emetteur
        se réserve la possibilité de sélectionner tout autre établissement de crédit agréé dans un
        Etat membre de l’Union Européenne ou partie à l’accord relatif à l’Espace Economique
        Européen.
      </p>
      <p>
        <b>« Bénéficiaire »</b> : désigne une personne physique ou morale agissant pour son compte,
        désignée par l’Utilisateur parmi les personnes clientes du Site, au profit de laquelle est
        transférée la Monnaie Electronique dans le cadre d’une Opération de Paiement. Tout
        Bénéficiaire peut devenir un Utilisateur au sens des présentes à compter de son acceptation
        du Contrat-cadre sous réserve d’acceptation par l’Emetteur. Dans certain cas, le
        Bénéficiaire peut être le Distributeur conformément aux Conditions Particulières.
      </p>
      <p>
        <b>« Carte »</b>: désigne la carte bancaire, de paiement ou de crédit utilisée par
        l’Utilisateur pour remettre des fonds à l’Emetteur en contrepartie de l’émission de Monnaie
        électronique. Cette carte est rattachée à l’un des réseaux suivants : Visa, MasterCard, CB,
        Amex.
      </p>
      <p>
        <b>« Compte »</b>: désigne une référence interne permettant à l’Emetteur d’identifier dans
        ses livres les différentes opérations d’acquisition, d’utilisation et de remboursement de
        Monnaie électronique réalisées au nom d’un même Utilisateur et de déterminer, à tout moment,
        le montant en euro de Monnaie Electronique disponible détenue par ce dernier. Le Compte ne
        peut en aucun cas être assimilé à un compte de dépôt, un compte courant ou à un compte de
        paiement.
      </p>
      <p>
        <b>« Conditions Générales du Site » </b>: désignent les conditions générales d’utilisation
        du Site conclues entre l’Utilisateur agissant en qualité de client du Site et le
        Distributeur, régissant notamment l’accès au Site.
      </p>
      <p>
        <b>« Contrat-cadre »</b>: désigne les présentes Conditions Générales d’Utilisation de la
        Monnaie Electronique, accompagnées du Formulaire de souscription et des Conditions
        tarifaires, régissant l’utilisation des Services ainsi que la gestion du Compte par
        l’Emetteur.
      </p>
      <p>
        <b>«Conditions Tarifaires»</b>: désignent les modalités financières convenues entre
        l’Utilisateur et le Distributeur, incluant les frais dus au titre du présent Contrat-cadre.
        Ce document comprend l’ensemble des frais versés pour l’acquisition, l’utilisation et la
        gestion de la Monnaie électronique telles que prévues dans les Conditions Générales du Site.
      </p>
      <p>
        <b>« Distributeur »</b>: désigne l’entité, dûment mandatée par l’Emetteur, qui exploite le
        Site, dont les coordonnées sont indiquées dans les Conditions Générales du Site. Le
        Distributeur prépare, facilite et conseille ses clients, en vue de la conclusion du
        Contrat-cadre par l’intermédiaire de son Site. Il accompagne ses clients tout au long de
        leur relation avec l’Emetteur dans le cadre de la réalisation de leurs Opérations de
        paiement et Remboursement. A cet effet, le Distributeur met à la disposition de tout client
        un service client dédié aux Opérations de paiement exécutées en application des présentes.
        Le Distributeur ne collecte pas les fonds à l’exception des frais convenus dans les
        Conditions Tarifaires.
      </p>
      <p>
        <b>« Données de sécurité personnalisées »</b>: désignent les données personnalisées fournies
        par le Distributeur à l’Utilisateur à des fins d’Authentification. Elles comprennent les
        Données d’identification, ainsi qu’éventuellement toute autre donnée liée à la procédure
        d’Authentification ou d’Authentification forte.
      </p>
      <p>
        <b>« Données d’identification »</b>: désignent l’identifiant unique et le mot de passe de
        l’Utilisateur, lui permettant d’accéder à son Espace Personnel.
      </p>
      <p>
        <b>« Données personnelles » </b>: désigne toute information se rapportant à l’Utilisateur
        personne physique, ou à une personne physique en lien avec l’Utilisateur personne morale
        (notamment un mandataire social, un bénéficiaire effectif, une Personne habilitée), au sens
        du Règlement européen 2016/679 relatif à la protection des données à caractère personnel.
      </p>
      <p>
        <b>« Emetteur »</b>: désigne MANGOPAY. SA, émetteur de la Monnaie Electronique agréé à ce
        titre au Luxembourg par la Commission de Surveillance du Secteur Financier sous les
        références n°3812 et habilité à exercer son activité dans l’ensemble des pays de l’union
        Européenne. L’Emetteur figure sur la liste des établissements de monnaie électronique
        consultable sur{' '}
        <a href="http://supervisedentities.cssf.lu/index.html?language=fr#Home">
          http://supervisedentities.cssf.lu/index.html?language=fr#Home
        </a>
      </p>
      <p>
        <b>« Espace personnel »</b>: désigne l’environnement dédié de l’Utilisateur, accessible sur
        le Site du Distributeur, lui permettant d’utiliser les Services.
      </p>
      <p>
        <b>« Formulaire de souscription »</b>: désigne le formulaire à remplir par tout prospect
        souhaitant souscrire aux Services, accessible sur le Site lors de l’inscription ou mis à
        disposition par le Distributeur.
      </p>
      <p>
        <b> « Identifiant »</b>: désigne les données nécessaires à l’identification d’un Utilisateur
        par l’Emetteur pour la réalisation d’une Opération de paiement se composant d'un User
        (adresse email valide)
      </p>
      <p>
        <b>« Jour Ouvrable »</b>: désigne un jour calendaire à l’exception des samedis, dimanches,
        et jours fériés en France métropolitaine et au Luxembourg et tout autre jour désigné comme
        tel par l’Emetteur.
      </p>
      <p>
        <b>«Monnaie Electronique»</b>: désigne la valeur monétaire disponible représentant une
        créance de l’Utilisateur sur l’Emetteur. La Monnaie Electronique est émise par l’Emetteur
        contre la remise des fonds en euro (€) correspondants par l’Utilisateur et constitue un
        moyen de paiement accepté exclusivement par les Bénéficiaires. L’Emetteur la conserve sous
        une forme électronique sur son serveur par l’inscription sur un Compte ouvert à cet effet.
      </p>
      <p>
        <b>« Moyen de paiement »</b>: désigne les moyens de paiement autres que la Carte, listés sur
        le Site, proposés sur option par l’Emetteur afin de permettre à l’Utilisateur de lui
        remettre des fonds en contrepartie de l’émission de Monnaie électronique.
      </p>
      <p>
        <b>« Opération de paiement »</b>: désigne le transfert de Monnaie Electronique au profit
        d’un Bénéficiaire désigné sur le Site par un Utilisateur.
      </p>
      <p>
        <b>« Ordre » </b>: désigne l’instruction donnée par l’Utilisateur à l’Emetteur conformément
        à la procédure prévue au Contrat-Cadre en vue d’exécuter une Opération de paiement et/ou un
        Remboursement.
      </p>
      <p>
        <b>« Page de paiement »</b>: désigne la page sécurisée par Payline, le prestataire monétique
        de l’Emetteur. Seule cette adresse doit être considérée comme valable par l’Utilisateur.
        L’Utilisateur est invité à vérifier systématiquement l’adresse figurant en haut de sa page.
      </p>
      <p>
        <b>« Personne concernée »</b>: désigne l’Utilisateur personne physique ou toute personne
        physique liée à l’Utilisateur (notamment un mandataire social, un bénéficiaire effectif),
        dont les Données personnelles sont traitées dans le cadre de l’exécution du présent
        Contrat-cadre.
      </p>
      <p>
        <b>« Remboursement » </b>: désigne le transfert par l’Emetteur sur Ordre de l’Utilisateur,
        des fonds scripturaux correspondant à tout ou partie de la Monnaie Electronique disponible
        détenue par lui diminuée des éventuels frais dus.
      </p>
      <p>
        <b>« Services » </b>: Désignent les services d’émission, de gestion et de mise à
        dispositions de la Monnaie Electronique, fournis par l’Emetteur à l’Utilisateur tels que
        décrits dans l’article 5 des présentes.
      </p>
      <p>
        <b>« Service client du Distributeur »</b>: désigne le service dont les coordonnées sont
        mentionnées sur le Site, auprès duquel l’Utilisateur pourra obtenir les informations
        relatives au présent Contrat-cadre.
      </p>
      <p>
        <b>« Site » </b>: désigne le site internet exploité par le Distributeur dont l’objet
        consiste à vendre des biens ou services à des Utilisateurs ou à collecter des fonds auprès
        d’eux, ou à mettre en relation des Bénéficiaires avec des Utilisateurs. Le Site a intégré
        l’API aux couleurs de la Marque pour permettre à l’Utilisateur de disposer d’un moyen de
        paiement émis et géré par l’Emetteur pour transférer des fonds au Bénéficiaire désigné.
      </p>
      <p>
        <b>« Support durable »</b>: désigne tout instrument permettant à l’Utilisateur de stocker
        des informations qui lui sont adressées personnellement afin de pouvoir s'y reporter
        ultérieurement pendant un laps de temps adapté aux fins auxquelles les informations sont
        destinées et qui permet la reproduction à l'identique des informations stockées. Il se
        présente en général sous la forme d’un fichier PDF.
      </p>
      <p>
        <b>« Utilisateur » </b>: désigne toute personne physique ou morale agissant pour son compte
        détenant de la Monnaie Electronique inscrite sur un Compte ouvert à son nom, afin de
        réaliser une ou des Opérations de paiement.
      </p>
      <h2>2. Objet</h2>
      <p>
        Le Contrat-cadre a pour objet de définir les conditions dans lesquelles l’Emetteur fournit à
        l’Utilisateur un moyen de paiement exclusivement accepté par les Bénéficiaires dans le cadre
        de leurs relations établies par l’intermédiaire du Site, en contrepartie des frais décrits à
        l’article 9 des présentes.
      </p>
      <p>
        Le moyen de paiement proposé doit être systématiquement prépayé par l’Utilisateur et ne fera
        l’objet d’aucune avance, crédit, ou escompte. Il repose sur la Monnaie électronique émise et
        gérée par l’Emetteur.
      </p>
      <p>
        L’Emetteur a mandaté le Distributeur pour proposer aux clients du Site ce moyen de paiement,
        faciliter la conclusion des présentes et accompagner les Utilisateurs tout au long de leurs
        relations avec l’Emetteur.
      </p>
      <p>Seul le Contrat-cadre fera foi entre les Parties en cas de litige.</p>
      <h2>3. Souscription aux Services</h2>
      <h3>3.1. Modalités de souscription</h3>

      <p>
        Le Contrat-cadre est conclu à distance, selon les modalités prévues par le Distributeur dans
        les Conditions générales du Site. Pour pouvoir conclure le Contrat-cadre en ligne, le client
        doit impérativement disposer d’équipements (matériels et logiciels), dont il est seul
        responsable, compatibles avec ce mode de conclusion.
      </p>
      <p>
        Par défaut, l’acceptation du Contrat-cadre est réalisée à distance via le Site et est
        matérialisée par une signature électronique. Le client a la possibilité de demander à signer
        le Contrat-cadre de manière manuscrite. A cet effet, il doit imprimer le présent
        Contrat-cadre, le signer, et le retourner par voie électronique ou postale au Service client
        du Distributeur dont les coordonnées sont indiquées dans les Conditions générales du Site.
      </p>
      <p>
        En cas de signature manuscrite, la date de conclusion du Contrat-cadre est réputée être la
        date indiquée sur celui-ci et en l’absence de date, elle sera réputée être la date de
        réception du Contrat-Cadre par le Distributeur.
      </p>
      <p>
        La signature électronique du Contrat-cadre est effectuée par l’intermédiaire du Site. La
        date de conclusion du Contrat-Cadre correspond à la date à laquelle le client finalise le
        processus de signature électronique qui lui est indiqué sur le Site.
      </p>
      <p>
        Le Contrat-cadre conclu entre les Parties par voie électronique a la même force probante
        qu’un Contrat- cadre sur support papier.
      </p>
      <h3>3.2. Documents contractuels</h3>
      <p>Le Contrat-cadre est composé :</p>
      <p style={{ marginLeft: '8px' }}>
        - des présentes Conditions Générales d’Utilisation de la Monnaie électronique ;
      </p>
      <p style={{ marginLeft: '8px' }}>- du Formulaire de souscription disponible sur le Site ;</p>
      <p style={{ marginLeft: '8px' }}>
        - des Conditions tarifaires communiquées par le Distributeur.
      </p>
      <p>
        Les présentes Conditions Générales d’utilisation de la Monnaie Electronique, ainsi que les
        Conditions tarifaires, sont mises à disposition de l’Utilisateur sur le Site et sont
        téléchargeables sur Support durable selon les modalités indiquées sur le Site. À tout moment
        de la relation contractuelle, l’Utilisateur peut, s’il en fait la demande, recevoir ces
        documents sur support papier.
      </p>
      <p>
        L’Emetteur maintient l’accès aux documents contractuels pendant une durée de cinq (5) ans à
        compter de la fin de la relation contractuelle. L’Emetteur cessera de fournir ce service à
        l’expiration du délai de cinq (5) ans précité.
      </p>
      <h2>4. Ouverture du Compte</h2>
      <h3>4.1. Conditions nécessaires et préalables à l’inscription de l’Utilisateur</h3>
      <p>
        Toute personne physique âgée d’au moins 18 (dix- huit) ans, juridiquement capable et toute
        personne morale, résidente et/ou immatriculées dans un Etat membre de l’Union Européenne ou
        dans un Etat partie à l’accord relatif à l’Espace Economique européen ou dans un pays tiers
        imposant des obligations équivalentes en termes de lutte contre le blanchiment et le
        financement du terrorisme, peuvent transmettre une demande d’ouverture de Compte sous
        réserve que la personne physique soit référencée sur le Site en qualité de consommateur ou
        en qualité de professionnel. La personne morale ne peut être référencée qu’en tant que
        professionnel.
      </p>
      <p>
        L’Utilisateur déclare au moment de la transmission de sa demande d’inscription au
        Distributeur et pendant toute la durée du Contrat-cadre :
      </p>
      <p style={{ marginLeft: '8px' }}>
        - qu’il est âgé d'au moins 18 (dix-huit) ans et juridiquement capable ;
      </p>
      <p style={{ marginLeft: '8px' }}>- qu’il agit pour son propre compte ;</p>
      <p style={{ marginLeft: '8px' }}>
        - que toutes les informations fournies lors de son inscription sont sincères, exactes et à
        jour.
      </p>
      <h3>4.2. Procédure d’inscription et d’ouverture d’un Compte</h3>
      <p>4.2.1. Informations et pièces justificatives</p>
      <p>
        Tout prospect devra transmettre au Distributeur les informations et documents listés
        ci-après, au moyen du Formulaire de souscription, dans le cas où ces informations et
        documents ne seraient pas déjà en possession du Distributeur.
      </p>
      <p>
        Le prospect s’engage à transmettre les informations et documents correspondant à sa qualité,
        soit de professionnel, soit de consommateur.
      </p>
      <p>Pour l’Utilisateur personne physique consommateur :</p>
      <li>
        sesnom, prénom, adressemail, date et lieu de naissance,nationalité et pays de résidence;
      </li>
      <li>
        une copie d’un document officiel d’identité en cours de validité de l’Utilisateur(ex:carte
        d’identité, permis de conduire, et pour les nationaux de pays tiers à l’Union Européenne un
        passeport) ;
      </li>
      <p>Pour l’Utilisateur professionnel :</p>
      <p>- pour les personnes physiques :</p>
      <li>ses nom, prénom, adresse mail, date de naissance et nationalité et pays de résidence;</li>
      <li>
        un original ou une copie d’un extrait du registre officiel datant de moins de trois mois
        constatant l’inscription en tant que commerçant ou au répertoire national des métiers ou à
        tout autre organisme professionnel dont dépend l’Utilisateur ;
      </li>
      <li>
        un ecopie d’un document officiel d’identité en cours de validité de l’Utilisateur(ex:carte
        d’identité, permis de conduire, et pour les nationaux de pays tiers à l’Union Européenne un
        passeport).
      </li>
      <p>- pour les personnes morales :</p>
      <li>
        sa dénomination,sa forme sociale,son capital,l’adresse de son siège social,la description de
        son activité, l’identité des associés et dirigeants sociaux, ainsi que la liste des
        bénéficiaires effectifs tels que définis par la réglementation ;
      </li>
      <li>
        un extrait Kbis ou un document équivalent de moins de trois mois justifiant de son
        immatriculation au RCS d’un Etat membre de l’Union Européenne ou d’un Etat partie à l’accord
        relatif à l’Espace Economique européen ou d’un pays tiers imposant des obligations
        équivalentes en termes de lutte contre le blanchiment et le financement du terrorisme et ses
        statuts. Ce document doit constater la dénomination, la forme juridique, l’adresse du siège
        social et l’identité des associés et dirigeants sociaux mentionnés aux 1° et 2° de l’article
        R. 123-54 du Code de Commerce ou de leurs équivalents en droit étranger ;
      </li>
      <li>
        une copie de statuts et des éventuelles décisions nommant le représentant légal certifiée
        conforme ;
      </li>
      <li>
        une copie de la carte d’identité ou du passeport du représentant légal et le cas échéant du
        ou des bénéficiaire(s) effectif(s) ;
      </li>
      <li>
        La déclaration des bénéficiaires effectifs de la personne morale détenant plus de 25%,dans
        le cas où le prospect n’a pas déclaré ses bénéficiaires effectifs au registre national, ou
        s’il n’est pas assujetti à cette obligation.
      </li>
      <p>
        Il pourra également être demandé à l’Utilisateur de communiquer un relevé d’identité
        bancaire d’un compte ouvert à son nom auprès d’une personne mentionnée aux 1° à 6° bis de
        l’article L. 561-2 du Code monétaire et financier établie dans un Etat membre de l’Union
        Européenne ou dans un Etat partie à l’accord sur l’Espace Economique Européen ou dans un
        pays tiers imposant des obligations équivalentes en termes de lutte contre le blanchiment et
        le financement du terrorisme.
      </p>
      <p>
        Il est expressément prévu que l’Emetteur se garde la possibilité de demander avant toute
        inscription et à tout moment de la durée du Contrat-Cadre, des documents complémentaires
        concernant l’Utilisateur, le bénéficiaire effectif ou une Opération de paiement spécifique.
      </p>
      <p>4.2.2. Limitation du Compte</p>
      <p>
        Suivant la libre appréciation de l’Emetteur, l’usage d’un Compte peut être limité sans que
        l’Emetteur ne justifie sa décision auprès de l’Utilisateur concerné. Le fonctionnement du
        Compte sera notamment limité dès lors que l’Utilisateur n’a pas transmis l’ensemble des
        informations et documents requis par l’Emetteur, tels que listés ci-dessus. Ces limites sont
        indiquées à l’Utilisateur par le Distributeur.
      </p>
      <p>4.2.3. Finalisation de l’inscription</p>
      <p>
        Après avoir conclu le Contrat-cadre, l’Utilisateur devra fournir toutes les informations et
        justificatifs qui lui sont demandées par le Distributeur. En donnant son accord sur les
        termes du Contrat-cadre, l’Utilisateur accepte que le Distributeur transmette à l’Emetteur
        sa demande d’inscription en qualité d’Utilisateur et toutes les pièces justificatives reçues
        par lui.
      </p>
      <p>
        L’Emetteur est le seul à pouvoir accepter l’inscription d’un prospect en qualité
        d’Utilisateur d’un Compte en son nom. Cet accord sera notifié à l’Utilisateur par le
        Distributeur par tout moyen suivant les modalités prévues sur le Site.
      </p>
      <p>
        L’Emetteur pourra, sans motivation, ni droit à indemnité en faveur de l’Utilisateur, refuser
        une demande d’ouverture d’un Compte. Ce refus sera notifié à l’Utilisateur par le
        Distributeur par tout moyen suivant les modalités prévues sur le Site.
      </p>
      <h2>5. Fonctionnement du Compte</h2>
      <h3>5.1. Achat de Monnaie Electronique</h3>
      <p>
        L’acquisition de Monnaie Electronique peut être réalisée, par Carte (ou tout autre Moyen de
        paiement accepté par l’Emetteur), en une ou plusieurs fois.
      </p>
      <p>
        Lorsqu’il souhaite effectuer une telle opération, l’Utilisateur procède à son identification
        sur le Site en indiquant son User (adresse mail valide) et son mot de passe ou en se
        connectant par l’intermédiaire de son compte Facebook.
      </p>
      <p>
        L’ordre de transfert de fonds est transmis sur une Page de paiement dédiée à cet effet.
        L’Emetteur pourra exiger pour tout paiement la saisie d’un code à usage unique notifié sur
        le téléphone portable de l’Utilisateur par l’établissement émetteur de la Carte. Le cas
        échéant, il appartient à l’Emetteur de refuser tout paiement suivant sa libre appréciation
        et sans que cette décision ne puisse donner lieu à une quelconque indemnisation. L’opération
        de transfert de fonds est exécutée par l’établissement émetteur de la Carte. Toute
        contestation d’un tel transfert doit être notifiée audit établissement. L’Emetteur n’est pas
        habilité à annuler un tel transfert. Nonobstant ce qui précède, l’Utilisateur peut obtenir
        le Remboursement de la Monnaie électronique conformément à l’article 5.4.
      </p>
      <p>
        L’inscription de la Monnaie électronique au nom de l’Utilisateur peut être conditionnée à la
        réception effective des fonds collectés déduction faite des frais convenus dans les
        Conditions Tarifaires.
      </p>
      <p>
        Dans l’hypothèse où le transfert des fonds est annulé par l’émetteur de la Carte à la suite
        d’une contestation, quel que soit le motif invoqué, l’Emetteur pourra dès réception de
        l’information suspendre ou annuler toute Opération de paiement, clôturer le Compte concerné,
        débiter à tout moment le Compte du montant de Monnaie Electronique correspondant aux fonds
        dont le transfert est annulé et procéder au recouvrement des sommes dues par l’Utilisateur
        par tout moyen.
      </p>
      <h3>5.2. Fonctionnement du compte</h3>
      <p>
        La Monnaie Electronique est stockée pour une durée indéterminée sur le Compte de
        l’Utilisateur par l’Emetteur dans les Conditions Tarifaires convenues.
      </p>
      <p>
        Le Compte est crédité par l’acquisition de Monnaie électronique contre la remise de fonds
        ordonnée par l’Utilisateur directement auprès de l’Emetteur par Carte (ou tout autre Moyen
        de paiement accepté par l’Emetteur) déduction faite des frais correspondants tels que prévus
        par les Conditions Tarifaires. Ce Compte est débité sur Ordre de l’Utilisateur de Monnaie
        électronique du montant de l’Opération de paiement ou de Remboursement réalisé et des frais
        y afférents tels que prévus par les Conditions Tarifaires. L’Emetteur est habilité à débiter
        à tout moment ce Compte du montant de Monnaie électronique correspondant aux frais dus et
        exigibles prévus dans les Conditions Tarifaires.
      </p>
      <p>
        Le montant de Monnaie Electronique disponible sur le Compte est immédiatement ajusté en
        fonction :
      </p>
      <p style={{ marginLeft: '8px' }}>
        - des frais dus et exigibles, par l’Utilisateur du Compte, dont le paiement est réalisé en
        Monnaie Electronique ;
      </p>
      <p style={{ marginLeft: '8px' }}>
        - des Ordres transmis (ou en cours de transmission) à l’Emetteur et portant sur la Monnaie
        Electronique stockée sur le Compte ;
      </p>
      <p style={{ marginLeft: '8px' }}>
        - des fonds reçus par l’Emetteur en contrepartie de l’acquisition de Monnaie Electronique
        sur le Compte ;
      </p>
      <p style={{ marginLeft: '8px' }}>
        - et toute annulation portant sur l’une des opérations précitées en application des
        présentes.
      </p>
      <h3>
        5.3. Utilisation de la Monnaie Electronique en vue de réaliser une Opération de Paiement
      </h3>
      <p>
        Avant de transmettre un Ordre, l’Utilisateur devra s’assurer qu’il dispose d’un montant de
        Monnaie Electronique disponible suffisant pour couvrir le montant de l’Opération de paiement
        et les frais y afférents tels que convenus dans les Conditions Tarifaires.
      </p>
      <p>
        Le cas échéant, il devra acquérir la Monnaie électronique suffisante conformément à
        l’article 5.1 avant que l’Ordre ne puisse être valablement transmis à l’Emetteur pour
        exécution. La Monnaie électronique ne peut être détenue par l’Utilisateur que sous réserve
        de la remise effective des fonds correspondant. La Monnaie électronique ne peut en aucun
        être émise à crédit. Ainsi, lorsque le montant de Monnaie électronique disponible à la date
        d’exécution de l’Ordre par l’Emetteur est inférieur au montant de l’Opération de Paiement
        (frais inclus), l’Ordre est automatiquement refusé par l’Emetteur. L’information portant sur
        ce refus est mise à la disposition de l’Utilisateur sur le Site. Ce refus pourra donner lieu
        à des frais complémentaires conformément aux Conditions Tarifaires.
      </p>
      <p>Les modalités de transmission d’un Ordre par l’Utilisateur sont les suivantes :</p>
      <p>
        Lorsqu’il souhaite effectuer une Opération de paiement, l’Utilisateur procède à son
        identification sur son Espace personnel en indiquant ses Données d’identification, et, le
        cas échant, en suivant la procédure d’Authentification forte qui lui est indiquée. Il
        remplit le formulaire adéquat sur la Page de paiement et communique le cas échéant les
        pièces justificatives demandées par l’Emetteur. Le formulaire devra indiquer les éléments
        suivants : le montant exprimé en euro (€) de l’Opération de paiement, les éléments
        permettant d’identifier le Bénéficiaire, la date d’exécution de l’Ordre et toute autre
        information requise.
      </p>
      <p>
        L’Utilisateur donne son consentement irrévocable à l’Ordre de paiement en cliquant sur
        l’onglet « validation » (« Date de réception »). La réception de l’Ordre de paiement est
        confirmée sur l’Espace personnel de l’Utilisateur ou par la réception d’un email. Aucun
        Ordre ne peut être retiré par l’Utilisateur après la date à laquelle il est réputé
        irrévocable, c’est-à-dire à compter de la Date de réception.
      </p>
      <p>
        Dans certains cas, l’Utilisateur pourra remplir un formulaire unique contenant un ordre de
        transfert des fonds tel que prévu à l’article 5.1 et un Ordre permettant la réalisation d’un
        Opération de paiement conformément au paragraphe précédent.
      </p>
      <p>Exécution de l’Ordre :</p>
      <p>
        Le montant des Opérations de Paiement vient s’imputer sur la Monnaie Electronique disponible
        inscrite sur le Compte pour être créditée en faveur du Bénéficiaire suivant les instructions
        de l’Utilisateur. Ainsi, le Bénéficiaire pourra procéder à l’ouverture d’un Compte
        conformément à l’article 4.2 pour recevoir la Monnaie électronique s’il n’est pas déjà
        Utilisateur. Les fonds correspondant à la Monnaie électronique utilisée pourront le cas
        échéant être directement transférés sur un compte bancaire ou de paiement ouvert au nom du
        Bénéficiaire dès réception des coordonnées de ce compte par l’Emetteur. Le Bénéficiaire doit
        à cet effet fournir le numéro IBAN et le code SWIFT du compte bancaire ou de paiement dont
        il est titulaire ainsi que son adresse. Ce compte devra être ouvert par un établissement
        bancaire ou de paiement établi dans un Etat membre de l’Union Européenne, d’un Etat partie à
        l’accord sur l’Espace Economique Européen ou dans un pays tiers imposant des obligations
        équivalentes en termes de lutte contre le blanchiment et le financement du terrorisme.
      </p>
      <p>
        Il est expressément convenu que les Ordres seront exécutés au plus tard à la fin du Jour
        Ouvrable suivant la Date de réception de l’Ordre par l’Emetteur (et à la date d’exécution
        convenue pour les Ordres à terme ou permanents), si le Bénéficiaire dispose d’un Compte. Le
        cas échéant, la Date de réception devra être reportée à l’ouverture du Compte ou au jour de
        la collecte par l’Emetteur des coordonnées du compte bancaire ou de paiement du Bénéficiaire
        destinataire des fonds.
      </p>
      <p>
        Tout Ordre reçu après 16h par le Prestataire est réputé reçu le Jour Ouvrable suivant. Si la
        Date de réception n’est pas un Jour Ouvrable, l’Ordre est réputé avoir été reçu le Jour
        Ouvrable suivant.
      </p>
      <h3>5.4. Transmission et exécution d’un Ordre de Remboursement</h3>
      <p>
        Lorsqu’il souhaite transmettre un Ordre de Remboursement, l’Utilisateur procède à son
        identification sur le Site en indiquant son ses Données d’indentification ou en se
        connectant par l’intermédiaire de son compte Facebook. Il remplit le formulaire adéquat sur
        la Page de paiement et communique le cas échéant les pièces justificatives demandées par
        l’Emetteur. L’Utilisateur devra indiquer sur le formulaire les éléments suivants : le
        montant du Remboursement, la date d’exécution de l’Ordre et toute autre information requise.
      </p>
      <p>
        L’Utilisateur donne son consentement irrévocable à l’Ordre de paiement en cliquant sur
        l’onglet « validation » (« Date de réception »). La réception de l’Ordre de paiement est
        confirmée sur l’Espace personnel de l’Utilisateur ou par la réception d’un email. Aucun
        Ordre ne peut être retiré par l’Utilisateur après la date à laquelle il est réputé
        irrévocable, c’est-à-dire à compter de la Date de réception.
      </p>
      <p>
        Le Remboursement interviendra par crédit de la Carte (ou du Moyen de paiement) ayant servi à
        l’Utilisateur pour acquérir de la Monnaie électronique. Le cas échéant, il sera réalisé par
        transfert des fonds sur le compte bancaire ou de paiement du Bénéficiaire dont les
        coordonnées auront été notifiées à l’Emetteur (« Date de notification »). Il est convenu
        entre les Parties que le Remboursement sera exécuté au plus tard à la fin du Jour Ouvrable
        suivant la Date de réception ou de notification suivant le cas.
      </p>
      <p>
        Tout Ordre de remboursement reçu après 16h par le Prestataire est réputé reçu le Jour
        Ouvrable suivant. Si la Date de réception n’est pas un Jour Ouvrable, l’Ordre est réputé
        avoir été reçu le Jour Ouvrable suivant.
      </p>
      <h3>5.5. Retrait d’un Ordre</h3>
      <p>
        Aucun Ordre ne peut être retiré par l’Utilisateur après la date à laquelle il est réputé
        irrévocable comme indiqué ci-dessus.
      </p>
      <h2>6. Reporting</h2>
      <p>
        L’Utilisateur dispose dans son Espace personnel d’un état des opérations sur le Compte. Il
        est invité à prendre connaissance avec attention de la liste de ces opérations.
        L’Utilisateur reconnaît que seul le relevé d’Opérations de paiement validé par l’Emetteur
        vaut reconnaissance par ce dernier du montant de Monnaie électronique disponible sur le
        Compte arrêté à la date indiquée sur le relevé. L’Utilisateur peut accéder à tout moment sur
        sa page personnelle accessible sur le Site au montant indicatif de Monnaie Electronique
        disponible sur son Compte.
      </p>
      <p>
        Il est précisé que pour chaque Opération réalisée sur son Compte, l’Utilisateur disposera
        des informations suivantes : la référence de l’Opération, l’identification du Bénéficiaire,
        le montant de l’Opération, la date de réception de l’Ordre, et le cas échéant, les frais
        relatifs à l’exécution de cette Opération.
      </p>
      <p>
        L’Emetteur met à la disposition de l’Utilisateur sur demande les relevés mensuels du Compte
        couvrant les treize (13) mois précédents.
      </p>
      <h2>7. Opposition aux Données de sécurité personnalisées</h2>
      <p>
        L’Utilisateur doit informer le Distributeur de la perte ou du vol de ses Données de sécurité
        personnalisées, du détournement ou de toute utilisation non autorisée de son Espace
        personnel ou de ses données dès qu’il en a connaissance afin d’en demander le blocage. Cette
        déclaration doit être réalisée :
      </p>
      <p style={{ marginLeft: '8px' }}>
        - par appel téléphonique au service client du Distributeur au numéro indiqué dans les
        Conditions Générales du Site, ou ;
      </p>
      <p style={{ marginLeft: '8px' }}>
        - directement par message électronique par l’intermédiaire du formulaire de contact
        accessible sur le Site.
      </p>
      <p>
        L’Emetteur par l’intermédiaire du Distributeur exécutera immédiatement la demande de mise en
        opposition de l’Identifiant concerné. L’événement sera enregistré et horodaté. Un numéro
        d’opposition avec horodatage sera communiqué à l’Utilisateur. Une confirmation écrite de
        cette mise en opposition sera adressée par le Distributeur à l’Utilisateur concerné par
        message électronique. L’Emetteur prend en charge le dossier sur le plan administratif et
        conserve toutes les traces pendant 18 (dix-huit) mois. Sur demande écrite de l’Utilisateur
        et avant l’expiration de ce délai, l’Emetteur communiquera une copie de cette opposition.
      </p>
      <p>
        Toute demande d’opposition doit être confirmée sans délai par l’Utilisateur concerné, par
        lettre signée de ce dernier, remise ou expédiée sous pli recommandé, ou email, à l’Emetteur
        à l’adresse postale en tête des présentes.
      </p>
      <p>
        L’Emetteur et le Distributeur ne sauraient être tenus pour responsable des conséquences
        d’une opposition par télécopie ou courriel, qui n’émanerait pas de l’Utilisateur.
      </p>
      <p>
        Une demande d’opposition est réputée faite à la date et à l’heure de réception effective de
        la demande par le Distributeur. En cas de vol ou d’utilisation frauduleuse de l’Identifiant,
        l’Emetteur est habilité à demander par l’intermédiaire du Distributeur, un récépissé ou une
        copie du dépôt de plainte à l’Utilisateur qui s’engage à y répondre dans les plus brefs
        délais.
      </p>
      <h2>8. Contestation d’une Opération</h2>
      <h3>8.1. Dispositions communes à tous les Utilisateurs</h3>
      <p>
        Pour toute réclamation relative aux Opérations de paiement ou de Remboursement réalisées par
        l’Emetteur dans le cadre des présentes, l’Utilisateur est invité à s’adresser au service
        client du Distributeur ou à l’adresse indiquée à cet effet dans les Conditions Générales du
        Site.
      </p>
      <p>
        Si un Ordre est exécuté par l’Emetteur avec des erreurs dues à une faute de ce dernier, la
        contestation doit être transmise dans les plus brefs délais au Prestataire, l’Ordre est
        annulé et le Compte est rétabli dans la situation dans laquelle il se trouvait avant la
        réception de l’ordre de paiement. Par la suite, l’Ordre est représenté correctement.
      </p>
      <p>
        Des frais indiqués dans les Conditions tarifaires pourront être perçus en cas de
        contestation non justifiée d’une Opération.
      </p>
      <h3>8.2. Dispositions applicables à l’Utilisateur professionnel</h3>
      <p>
        L’Utilisateur professionnel qui souhaite contester une Opération de virement non autorisée
        par lui ou mal exécutée doit contacter par téléphone le Service client du Distributeur dans
        les plus brefs délais suivant sa prise de connaissance de l’anomalie et au plus tard dans
        les huit (8) semaines suivant l’inscription en compte de l’opération, à charge pour elle de
        transmettre la contestation à l’Emetteur dans les plus brefs délais. Sauf s’il a de bonnes
        raisons de soupçonner une fraude de l’Utilisateur, l’Emetteur rembourse à l’Utilisateur le
        montant de l’Opération immédiatement après avoir reçu la demande de contestation, et en tout
        état de cause au plus tard à la fin du premier Jour Ouvrable suivant. L’Emetteur rétablit le
        Compte dans l’état dans lequel il se serait trouvé si l’Opération de paiement non autorisée
        n’avait pas eu lieu.
      </p>
      <p>
        En cas de perte ou de vol des Données de sécurité personnalisées, les Opérations non
        autorisées effectuées avant la notification de l’opposition sont à la charge de
        l’Utilisateur. Les Opérations réalisées après l’opposition sont supportées par l’Emetteur
        sauf en cas de fraude de l’Utilisateur.
      </p>
      <h3>8.3. Dispositions applicables à l’Utilisateur consommateur</h3>
      <p>
        L’Utilisateur consommateur qui souhaite contester une Opération de virement non autorisée
        par lui ou mal exécutée doit contacter par téléphone le Service client du Distributeur dans
        les plus brefs délais suivant sa prise de connaissance de l’anomalie et au plus tard dans
        les treize (13) mois suivant la date de débit, à charge pour elle de transmettre la
        contestation à l’Emetteur dans les plus brefs délais. Sauf s’il a de bonnes raisons de
        soupçonner une fraude de l’Utilisateur, l’Emetteur rembourse à l’Utilisateur le montant de
        l’Opération immédiatement après avoir reçu la demande de contestation, et en tout état de
        cause au plus tard à la fin du premier Jour Ouvrable suivant. L’Emetteur rétablit le Compte
        dans l’état dans lequel il se serait trouvé si l’Opération de paiement non autorisée n’avait
        pas eu lieu.
      </p>
      <p>
        En cas de contestation, la charge de la preuve que l’Opération a été authentifiée, dûment
        enregistrée et comptabilisée, et qu’elle n’a pas été affectée par une déficience technique
        ou autre incombe à l’Emetteur.
      </p>
      <p>
        En cas d’opération de paiement non autorisée consécutive à la perte ou au vol des Données de
        sécurité personnalisées, l’Utilisateur supporte, avant la notification de l’opposition, les
        pertes liées à l’utilisation de Données de sécurité personnalisées, dans la limite d’un
        plafond de cinquante (50) euros. Les Opérations réalisées après l’opposition sont supportées
        par l’Emetteur sauf en cas de fraude de l’Utilisateur. Toutefois, la responsabilité de
        l’Utilisateur n’est pas engagée en cas :
      </p>
      <p style={{ marginLeft: '8px' }}>
        - D’Opération de paiement non autorisée effectuée sans utilisation des Données de sécurité
        personnalisées ;
      </p>
      <p style={{ marginLeft: '8px' }}>
        - De perte ou de vol des Données de sécurité personnalisées ne pouvant être détecté par
        l’Utilisateur avant le paiement ;
      </p>
      <p style={{ marginLeft: '8px' }}>
        - De perte due à des actes ou à une carence d’un salarié, d’un agent ou d’une succursale
        d’un PSP ou d’une entité vers laquelle ses activités ont été externalisées.
      </p>
      <p>La responsabilité de l’Utilisateur n’est pas non plus engagée :</p>
      <p style={{ marginLeft: '8px' }}>
        - si l’Opération de paiement non autorisée a été effectuée en détournant, à l’insu de
        l’Utilisateur, les Données de sécurité personnalisées ;
      </p>
      <p style={{ marginLeft: '8px' }}>
        - en cas de contrefaçon des Données de sécurité de personnalisées, si, au moment de
        l’Opération de paiement non autorisée, l’Utilisateur étant en possession de ces Données.
      </p>
      <p>
        L’Utilisateur supporte toutes les pertes occasionnées par les Opérations non autorisées si
        ces pertes résultent d’un agissement frauduleux de sa part ou s’il n’a pas satisfait
        intentionnellement par négligence grave aux obligations de conserver la sécurité de ses
        Données de sécurité personnalisées et de notifier l’opposition en cas de perte, de vol ou de
        détournement de ces Données.
      </p>
      <p>
        Sauf agissement frauduleux de sa part, l’Utilisateur ne supporte aucune conséquence
        financière si l’Opération non autorisée a été effectuée sans que l’Emetteur n’exige une
        Authentification forte de l’Utilisateur, dans les cas où la règlementation prévoit que cette
        dernière est obligatoire.
      </p>
      <h2>9. Conditions financières</h2>
      <p>
        Les services fournis dans le cadre des présentes sont facturés par le Distributeur en son
        nom et pour le compte de l’Emetteur conformément aux Conditions Tarifaires.
      </p>
      <p>
        Toutes les commissions dues par l’Utilisateur sont automatiquement prélevées sur le Compte
        de monnaie électronique par l’Emetteur. L’Utilisateur autorise l’Emetteur à compenser à tout
        moment, y compris après la clôture du Compte, toute créance certaine, liquide et exigible
        qui resterait redevable, à quelque titre que ce soit. Il pourra compenser la provision du
        Compte de monnaie électronique avec tout montant dû, exigible et impayé par l’Utilisateur à
        l’Emetteur.
      </p>
      <h2>10. Durée et résiliation  </h2>
      <p>
        Le Contrat-cadre est conclu pour une durée indéterminée. Il entre en vigueur à compter de
        son acceptation par l’Utilisateur.
      </p>
      <p>
        L’Utilisateur peut à tout moment et moyennant le respect d’un préavis de trente (30) jours
        calendaires, procéder à la résiliation du Contrat-cadre. L’Emetteur peut à tout moment
        procéder à la résiliation du Contrat-Cadre, moyennant le respect d’un préavis de deux (2)
        mois fourni sur Support durable. Dans ce cas, les frais régulièrement imputés au titre des
        Services seront dus par l’Utilisateur au prorata de la période échue à la date de
        résiliation.
      </p>
      <p>
        Au-delà de six (6) mois, le Contrat-cadre peut être résilié sans frais. Dans les autres cas,
        des frais de résiliation peuvent éventuellement s’appliquer, conformément aux Conditions
        tarifaires.
      </p>
      <p>
        Chaque Partie doit, pour ce faire, adresser sa notification de résiliation des présentes à
        l’autre Partie, par lettre recommandée avec accusé de réception à l’adresse postale ou à
        l’adresse e-mail indiquée dans les Conditions Générales du Site.
      </p>
      <p>
        L’Utilisateur devra désigner dans le courrier de résiliation les coordonnées de son compte
        bancaire ou de paiement permettant à l’Emetteur de lui rembourser la Monnaie Electronique
        disponible. En l’absence d’indication, il appartient à l’Emetteur de suivre les instructions
        de Remboursement impliquant le remboursement par crédit de la Carte ayant servi à
        l’acquisition de la Monnaie Electronique. L’Emetteur est déchargé de toute obligation dès
        lors qu’il aura confirmé à l’Utilisateur le virement sur le compte indiqué ou le crédit sur
        sa Carte du montant de Monnaie Electronique.
      </p>
      <p>
        En cas de manquement grave, fraude, ou impayés de la part de l’Utilisateur, l’Emetteur se
        réserve le droit de suspendre ou résilier les présentes par l’envoi d’un email accompagné en
        cas de résiliation d’une lettre recommandée avec avis de réception.
      </p>
      <p>
        En cas de nomination d’un successeur de l’Emetteur pour émettre la Monnaie Electronique
        distribuée sur le Site, il appartient au Distributeur de recueillir l’accord exprès et écrit
        de l’Utilisateur sur ce changement, sur le montant de Monnaie électronique disponible et
        d’indiquer à l’Emetteur les modalités de transfert des fonds correspondants à la Monnaie
        Electronique disponible.
      </p>
      <h2>11. Modification du Contrat-cadre</h2>
      <p>
        L’Emetteur se réserve le droit, à tout moment, de modifier le Contrat-cadre. Tout projet de
        modification du Contrat-cadre est fourni à l’Utilisateur par le Distributeur sur Support
        durable, au plus tard deux (2) mois avant la date d’application proposée pour son entrée en
        vigueur. Toute nouvelle prestation proposée par l’Emetteur fera l’objet d’une modification
        du Contrat-cadre.
      </p>
      <p>
        Tout Utilisateur peut refuser les modifications proposées et doit notifier son refus au
        Service Client du Distributeur par lettre recommandée avec avis de réception 2 mois avant la
        date d’entrée en vigueur des modifications proposées (cachet de la Poste faisant foi) à
        l’adresse indiquée dans les Conditions Générales du Site.
      </p>
      <p>
        A défaut d’avoir notifié son refus avant la date d’entrée en vigueur indiquée, l’Utilisateur
        est réputé accepter les modifications proposées.
      </p>
      <p>
        Les relations entre les Parties après la date d’entrée en vigueur seront alors régies par la
        nouvelle version du Contrat-cadre.
      </p>
      <p>
        En cas de refus par l’Utilisateur, ce refus donnera lieu, sans frais, à la résiliation du
        Contrat-cadre, ainsi qu’au Remboursement des unités de Monnaie Electronique lui appartenant
        dans un délai de treize (13) mois suivant la date d’effet de la résiliation en vue de
        couvrir toutes contestations futures.
      </p>
      <p>
        Toutes dispositions législatives ou réglementaires qui rendraient nécessaire la modification
        de tout ou partie du Contrat-Cadre seront applicables dès leur date d’entrée en vigueur,
        sans préavis. L’Utilisateur en sera cependant informé.
      </p>
      <h2>12. Sécurité</h2>
      <p>
        L’Emetteur s’engage à assurer ses prestations dans le respect des lois et règlements
        applicables et des règles de l’art. Notamment, l’Emetteur mettra tout en œuvre pour assurer
        la sécurité et la confidentialité des données des Utilisateurs, conformément à la
        règlementation en vigueur.
      </p>
      <p>
        L’Emetteur se réserve le droit de suspendre temporairement l'accès au Compte en ligne pour
        des raisons techniques, de sécurité ou de maintenance sans que ces opérations n'ouvrent
        droit à une quelconque indemnité. Il s'engage à limiter ce type d'interruptions au strict
        nécessaire.
      </p>
      <p>
        L’Emetteur ne saurait toutefois être tenu responsable à l’égard de l’Utilisateur des
        éventuelles erreurs, omissions, interruptions ou retards des opérations réalisées via le
        Site résultant d’un accès non autorisé à ce dernier. L’Emetteur ne saurait d’avantage être
        tenu responsable des vols, destructions ou communications non autorisées de données
        résultant d’un accès non autorisé au Site. En outre, l’Emetteur demeure étranger au lien de
        droit existant entre l’Utilisateur et le Bénéficiaire de l’Opération de Paiement ou entre
        l’Utilisateur et le Site. L’Emetteur ne saurait être tenu responsable des fautes,
        manquements ou négligence de l’Utilisateur ou du Bénéficiaire l’un envers l’autre, ou du
        Site et de l’Utilisateur l’un envers l’autre.
      </p>
      <p>
        Si l’identifiant unique ou toute autre information nécessaire à l’exécution d’une Opération
        de paiement fourni par l’Utilisateur est inexact, l’Emetteur n’est pas responsable de la
        mauvaise exécution dudit Service.
      </p>
      <p>
        Le Distributeur est seul responsable de la sécurité et de la confidentialité des données
        échangées dans le cadre de l'utilisation du Site conformément aux Conditions Générales du
        Site, l’Emetteur étant responsable de la sécurité et de la confidentialité des données qu’il
        échange avec l’Utilisateur dans le cadre des présentes au titre de la création et de la
        gestion de son Compte, ainsi que des Opérations de paiement associées au Compte.
      </p>
      <h2>13. Limitation de responsabilité de l’Emetteur</h2>
      <p>
        L’Emetteur n'intervient en aucune manière dans les relations juridiques et commerciales et
        les éventuels litiges intervenant entre le Bénéficiaire et l’Utilisateur ou entre
        l’Utilisateur et le Distributeur. L’Emetteur n'exerce aucun contrôle sur la conformité, la
        sécurité, la licéité, les caractéristiques et le caractère approprié des produits objet
        d’une Opération de paiement. A cet égard, il appartient à l’Utilisateur de prendre toutes
        les informations utiles avant de procéder à l’achat d’un produit ou service, la collecte de
        fonds ou toute opération en toute connaissance de cause. Chaque opération réalisée par
        l’Utilisateur donne naissance à un contrat directement formé entre lui et le ou les
        Bénéficiaires auquel l’Emetteur est étranger. Ce dernier ne pourra en conséquence être tenu
        responsable de l’inexécution ou de la mauvaise exécution des obligations qui en résultent,
        ni des préjudices éventuels causé à l’Utilisateur à ce titre.
      </p>
      <p>
        Nonobstant toute disposition contraire dans le présent Contrat, la responsabilité de
        l’Emetteur à l’égard d’un Utilisateur est limitée à la réparation des dommages directs tels
        que prévus par la réglementation.
      </p>
      <h2>14. Engagements de l’Utilisateur</h2>
      <p>
        L’Utilisateur garantit qu’aucun élément de son Espace personnel sur le Site ne porte
        atteinte aux droits de tiers ni n'est contraire à la loi, à l’ordre public et aux bonnes
        mœurs. L’Utilisateur atteste de la conformité, la licéité et le caractère approprié du Don
        réalisé au profit du Bénéficiaire effectif, de l’achat du bien au Bénéficiaire.
      </p>
      <p>
        Il s’engage à ne pas exécuter le Contrat-cadre d’une manière illégale ou dans des conditions
        susceptibles d’endommager, de désactiver, de surcharger ou d’altérer le Site. Il s’engage à
        ne pas usurper l’identité d’une autre personne ou entité, de falsifier ou dissimuler son
        identité, son âge ou créer une identité.
      </p>
      <p>
        En cas de manquement à ces obligations l’Emetteur se réserve le droit de prendre toute
        mesure appropriée afin de faire cesser les agissements concernés. Il sera également en droit
        de suspendre, supprimer et/ou bloquer son accès au Compte. Sans préjudice des actions
        judiciaires engagées par des tiers, l’Emetteur est en droit d’exercer à titre personnel
        toute action en justice visant à réparer les préjudices qu’il aurait personnellement subis
        du fait des manquements de l’Utilisateur à ses obligations au titre du présent Contrat.
      </p>
      <h2>15. Droit de rétractation</h2>
      <h3>15.1. Dispositions applicables à tous les Utilisateurs</h3>
      <p>
        L’Utilisateur ayant été démarché au sens des articles L. 341-1 et suivants du Code monétaire
        et financier dispose d’un délai de quatorze (14) jours calendaires révolus pour exercer son
        droit de rétractation, sous réserve de répondre le cas échéant aux conditions de l’article
        D. 341-1 du même code, sans avoir à justifier de motif ni supporter de pénalité.
      </p>
      <p>
        Ce délai de rétractation commence à courir à compter du jour de son inscription en tant
        qu’Utilisateur.
      </p>

      <h3>15.2. Dispositions applicables à l’Utilisateur consommateur</h3>
      <p>
        Aux termes de l’article L. 222-7 du Code de la consommation, l’Utilisateur consommateur
        dispose d’un droit de rétractation pouvant être exercé dans un délai de quatorze (14) jours
        sans avoir à justifier de motif ni supporter de pénalité. Ce délai de rétractation commence
        à courir soit à compter du jour de la conclusion du Contrat-cadre, soit à compter de la
        réception des conditions et informations contractuelles, si cette dernière date est
        postérieure à celle de la conclusion du Contrat-cadre.
      </p>
      <p>
        Le Contrat-cadre ne pourra recevoir un commencement d’exécution avant l’expiration du délai
        de rétractation qu’avec l’accord de l’Utilisateur consommateur. L’Utilisateur consommateur
        reconnaît que l’utilisation des Services après la conclusion du Contrat-cadre sera
        constitutive d’une demande expresse de sa part pour commencer l’exécution du Contrat-cadre
        avant l’expiration du délai précité.
      </p>
      <p>
        L’exercice du droit de rétractation emporte résolution du Contrat-cadre qui, en cas de
        commencement d’exécution, prend la forme d’une résiliation et ne remet pas en cause les
        prestations antérieurement réalisées. Dans ce cas, l’Utilisateur consommateur ne sera tenu
        qu’au paiement proportionnel des Services effectivement fournis.
      </p>
      <h3>15.3. Exercice du droit de rétractation</h3>
      <p>
        L’Utilisateur doit notifier sa demande de rétractation dans le délai imparti au Service
        client du Distributeur par téléphone ou par email et adresser un courrier de confirmation à
        l’adresse du Service client du Distributeur. A cet effet, il peut utiliser le bordereau de
        rétractation mis à sa disposition par le Distributeur.
      </p>
      <h3>16. Règles de lutte contre le blanchiment et le financement du terrorisme</h3>
      <p>
        L’Emetteur est soumis à l’ensemble de la réglementation luxembourgeoise et française
        relative à la lutte contre le blanchiment et le financement du terrorisme.
      </p>
      <p>
        En application des dispositions de droit français et luxembourgeois, relatifs à la
        participation des organismes financiers à la lutte contre le blanchiment des capitaux et le
        financement des activités terroristes, l’Emetteur est tenu de s’informer auprès de tout
        Utilisateur pour toute opération ou relation d’affaires de l’origine, de l’objet et de la
        destination de l’opération ou de l’ouverture du Compte. Il doit, par ailleurs, réaliser
        toutes les diligences nécessaires à l’identification de l’Utilisateur et le cas échéant, du
        Bénéficiaire effectif du Compte et/ou des Opérations de paiement liées à celui-ci.
      </p>
      <p>
        L’Utilisateur reconnaît que l’Emetteur peut mettre un terme ou reporter à tout moment
        l’usage de Données de sécurité personnalisées, l’accès à un Compte ou l’exécution d’une
        opération ou d’un Remboursement en l’absence d’élément suffisant sur son objet ou sa nature.
        Il est informé qu’une opération réalisée dans le cadre des présentes peut faire l’objet de
        l’exercice du droit à la communication de la cellule de renseignement financier nationale.
      </p>
      <p>
        L’Utilisateur peut, conformément à la réglementation, accéder à toutes les informations
        ainsi communiquées sous réserve que ce droit d’accès ne remette pas en cause la finalité de
        lutte contre le blanchiment des capitaux et le financement du terrorisme lorsque ces données
        sont relatives au demandeur.
      </p>
      <p>
        Aucune poursuite et aucune action en responsabilité civile ne peut être intentée ni aucune
        sanction professionnelle prononcée contre l’Emetteur, ses dirigeants ou ses préposés qui ont
        fait de bonne foi les déclarations de soupçon auprès de leur autorité nationale.
      </p>
      <h2>17. Protection des Données personnelles</h2>
      <p>
        L’Emetteur collecte et traite toute Donnée personnelle en conformité avec la réglementation
        en vigueur applicable à la protection de ces Données, et notamment avec la loi n°78-17 du 6
        janvier 1978 modifiée et avec le Règlement européen 2016/679 du Parlement européen et du
        Conseil du 27 avril 2016.
        <br /> Les Données personnelles requises lors de la souscription sont nécessaires dans le
        cadre des services fournis conformément aux présentes. En cas d’absence de fourniture des
        Données personnelles obligatoires, le demandeur pourra se voir refuser l’accès aux services.{' '}
        <br /> La Personne concernée est informée que les Données personnelles sont notamment
        collectées pour les finalités suivantes : la fourniture des services fournis tels que
        décrits aux présentes ; la lutte contre le blanchiment de capitaux et le financement du
        terrorisme ; le traitement des demandes d’information et réclamations ; la réalisation de
        statistiques. Ces traitements sont notamment nécessaires à l’exécution du Contrat-cadre
        ainsi qu’au respect d'obligations légales auxquelles les responsables de traitement sont
        soumis. L’Emetteur et le Distributeur agissent en qualité de responsables conjoints de ces
        traitements. <br /> Les Données personnelles ne seront transmises à aucun tiers sans le
        consentement exprès des Personnes concernées. Toutefois, la Personne concernée est informée
        que les Données personnelles sont transmises à des sous-traitants de l’Emetteur, pour les
        besoins des finalités précitées. Lesdits sous-traitants n’agiront que sur instruction de
        l’Emetteur et exclusivement pour le compte de ces derniers. <br /> La Personne concernée
        peut accéder à la liste des sous-traitants en transmettant sa demande au Service client du
        Distributeur. Elle est informée que l’Emetteur s’assure que ses sous-traitants prennent
        toutes les mesures nécessaires afin de préserver la sécurité et la confidentialité des
        Données personnelles. En cas de survenance d’une violation de Données personnelles (perte,
        intrusion, destruction...) impliquant des risques élevés pour la Personne concernée, cette
        dernière en sera informée. <br /> L’Emetteur se réserve le droit de divulguer des Données
        personnelles sur requête d’une autorité légale pour se conformer à toute loi ou
        réglementation en vigueur, pour protéger ou défendre les droits du titulaire du Compte ou
        d’une Personne concernée, si des circonstances impérieuses le justifient ou pour protéger la
        sécurité du titulaire, des Services ou du public.
        <br /> Les Données personnelles traitées par l’Emetteur dans le cadre des services fournis
        conformément aux présentes sont conservées pendant la durée strictement nécessaire pour
        atteindre les finalités mentionnées ci-dessus. Sauf disposition contraire légale et
        réglementaire, les Données personnelles ne seront pas conservées au-delà de la date d’effet
        de la résiliation du Contrat. Il est notamment précisé que les Données personnelles
        relatives à l’identification sont conservées pendant une durée de cinq ans à compter de la
        fin de la relation contractuelle, en vertu de la réglementation applicable en matière de
        lutte contre le blanchiment de capitaux et le financement du terrorisme.
        <br />
        Les Personnes concernées disposent des droits suivants sur leurs Données personnelles, selon
        les conditions prévues par la réglementation : droit d’accès, droit de rectification, droit
        d’opposition, droit à l’effacement, droit à la limitation du traitement et droit à la
        portabilité. Une Personne concernée peut à tout moment exercer ses droits en s’adressant au
        Service client du Distributeur. Sa demande devra indiquer ses nom, prénom, et identifiant,
        et être accompagnée de la photocopie d’un document d’identité portant sa signature. <br />{' '}
        Une réponse sera adressée à la Personne concernée dans un délai d’un (1) mois suivant la
        réception de sa demande. Ce délai pourra être prolongé de deux (2) mois, compte tenu de la
        complexité et du nombre de demandes. Dans ce cas, la Personne concernée sera informée de la
        prolongation et des motifs du report dans un délai d'un (1) mois à compter de la réception
        de la demande. <br /> La Personne concernée est informée qu’elle dispose du droit
        d’introduire une réclamation auprès de l’autorité compétente pour toute demande en lien avec
        ses Données personnelles. <br /> Si la Personne concernée présente sa demande sous une forme
        électronique, la réponse lui sera fournie par voie électronique, à moins qu’elle ne demande
        expressément qu'il en soit autrement. <br /> Lorsque les Données personnelles sont relatives
        à une Personne concernée n’étant pas partie au Contrat- cadre et ont été transmises par
        l’Utilisateur, ce dernier fait son affaire de communiquer à la Personne concernée les
        informations du présent article. <br /> Des informations complémentaires sur les traitements
        de Données personnelles réalisés dans le cadre des présentes, les durées de conservation et
        sur les droits des Personnes concernées sont disponibles dans la politique de
        confidentialité du de l’Emetteur (accessible sur le site{' '}
        <a href="https://www.mangopay.com">www.mangopay.com</a>).
      </p>
      <h2>18. Secret professionnel</h2>
      <p>
        L’Emetteur est tenu au secret professionnel. Toutefois, ce secret peut être levé,
        conformément à la législation en vigueur, en vertu d’une obligation légale règlementaire et
        prudentielle, notamment à la demande des autorités de tutelle, de l’administration fiscale
        ou douanière, ainsi qu’à celle du juge pénal ou en cas de réquisition judiciaire notifiée au
        Distributeur. Nonobstant ce qui précède, l’Utilisateur a la faculté de relever le
        Distributeur du secret professionnel en lui indiquant expressément les tiers autorisés à
        recevoir des informations confidentielles le concernant.
      </p>
      <p>
        Il est précisé que le secret professionnel pourra être levé par l’effet de la réglementation
        au profit des sociétés fournissant au Distributeur des taches opérationnelles importantes
        dans le cadre des présentes.
      </p>
      <h2>19. Propriété intellectuelle</h2>
      <p>
        L’Emetteur conserve l’entière propriété des titres et droit de propriété quels qu’ils soient
        attachés aux Services proposés à l’Utilisateur. Aucun de ces droits de propriété n’est
        transféré à l’Utilisateur au titre des présentes.
      </p>
      <h2>20. Décès de l’Utilisateur et Comptes inactifs</h2>
      <h3>20.1. Décès de l’Utilisateur</h3>
      <p>
        Le décès de l’Utilisateur met fin au Contrat-Cadre, dès que celui-ci est porté à la
        connaissance de l’Emetteur. Les Opérations intervenant à compter du décès sont, sauf accord
        des ayants droit ou du notaire en charge de la succession, considérées comme n’ayant pas été
        autorisées.
      </p>
      <p>
        Le Compte d’unité de Monnaie Electronique reste ouvert le temps nécessaire au règlement de
        la succession et le Distributeur/l’Emetteur assure le règlement du solde avec l’accord des
        ayants droit ou du notaire en charge de la succession.
      </p>
      <h3>20.2. Comptes inactifs</h3>
      <p>
        Tout Compte d’unité de Monnaie Electronique inactif pourra faire l’objet d’une notification
        d’inactivité par e-mail de la part du Distributeur, suivie d’une relance un (1) mois plus
        tard. Le Compte de l’Utilisateur est considéré comme inactif lorsqu’à l’issue d’une période
        de douze (12) mois, il n’a fait l’objet d’aucune opération (hors prélèvement de frais de
        gestion) à l’initiative de l’Utilisateur (ou de tout mandataire) et qu’il ne s’est pas
        manifesté auprès du Distributeur sous quelque forme que ce soit.
      </p>
      <p>
        En l’absence de réponse ou d’utilisation de la Monnaie Electronique disponible dans ce
        délai, le Compte sera clôturé et maintenu à la seule fin de procéder au Remboursement de la
        Monnaie électronique. Des frais de gestion pourront être perçus par l’Emetteur.
      </p>
      <p>
        Le Compte ne pourra plus donner lieu à l’utilisation ultérieure de la Monnaie Electronique.
      </p>
      <h2>21. Force majeure</h2>
      <p>
        Les Parties ne seront pas tenues pour responsables, ou considérées comme ayant failli au
        titre des présentes, en cas de retard ou inexécution, lorsque leur cause est liée à la force
        majeure telle que définie par l’article 1218 du Code civil.
      </p>
      <h2>22. Indépendance des stipulations contractuelles</h2>
      <p>
        Si l'une quelconque des stipulations des présentes est tenue pour nulle ou sans objet, elle
        sera réputée non écrite et n'entraînera pas la nullité des autres stipulations.
      </p>
      <p>
        Si une ou plusieurs stipulations des présentes deviennent caduques ou sont déclarées comme
        telles en application d'une loi, d'un règlement ou à la suite d'une décision définitive
        rendue par une juridiction compétente, les autres stipulations conserveront leur force
        obligatoire et leur portée. Les stipulations déclarées nulles et non valides seront alors
        remplacées par les stipulations qui se rapprocheront le plus quant à leur sens et à leur
        portée des stipulations initialement convenues.
      </p>
      <h2>23. Protection des fonds collectés</h2>
      <p>
        Les fonds de l’Utilisateur sont déposés à chaque fin de Jour Ouvrable dans un compte ouvert
        auprès d’une Banque et sont cantonnés par celle-ci.
      </p>
      <p>
        Aux termes de l’article 24-10 (1) a) de la Loi du 20 mai 2011 publiée au Mémorial A n° 104
        du 24 mai 2011 du Grand-Duché du Luxembourg et de l’article 14 de la loi du 10 novembre 2009
        publiée au Mémorial A n° 215 du 11 novembre 2009 du Grand-Duché du Luxembourg, portant
        transposition de la directive 2009/110/CE du Parlement européen et du Conseil du 16
        septembre 2009 concernant l’accès à l’activité des établissements de monnaie électronique,
        les fonds collectés sont protégés et ne tombent pas dans la masse des avoirs de
        l’établissement de monnaie électronique en cas de liquidation, de faillite ou de toute autre
        situation de concours de ce dernier.
      </p>
      <h2>24. Incessibilité</h2>
      <p>
        Le Contrat-cadre ne peut faire l'objet d'une cession totale ou partielle par l’Utilisateur,
        à titre onéreux ou gratuit. Il est par suite interdit de céder à un tiers l'un quelconque
        des droits ou obligations qu’il tient des présentes. En cas de manquement à cette
        interdiction, outre la résiliation immédiate des présentes, sa responsabilité pourra être
        engagée par l’Emetteur.
      </p>
      <h2>25. Convention de preuve</h2>
      <p>
        Toutes les données reprises de façon inaltérable, fiable, et sécurisée dans la base de
        données informatique de l’Emetteur relatives notamment aux ordres de paiement et
        confirmations reçues de l’Utilisateur, aux notifications envoyées, aux accès, Retrait,
        Remboursement feront foi entre les parties jusqu’à preuve du contraire.
      </p>
      <h2>26. Réclamation et médiation</h2>
      <p>
        L’Utilisateur est invité à s’adresser au Service client du Distributeur, éventuellement
        indiqué sur le Site pour toute réclamation.
      </p>
      <p>
        Toute réclamation autre que celle prévue à l’article 8 portant sur la conclusion,
        l’exécution ou la résiliation du Contrat-cadre et des services d’émission et de gestion de
        Monnaie électronique devra être notifiée par e- mail à l’adresse suivante :
        <a href="mailto:legal@mangopay.com">legal@mangopay.com</a>.
      </p>
      <p>
        L’Utilisateur accepte que l’Emetteur réponde à ses réclamations sur un Support durable. La
        réponse sera transmise dans les meilleurs délais et au plus tard dans un délai de quinze
        (15) Jours Ouvrables suivant la réception de la réclamation par l’Emetteur. Cependant, pour
        des raisons échappant à son contrôle, l’Emetteur pourra se trouver dans l’impossibilité de
        répondre dans ce délai de quinze (15) jours.
      </p>
      <p>
        Dans ce cas, il communiquera à l’Utilisateur une réponse précisant les raisons de ce délai
        supplémentaire ainsi que la date à laquelle il enverra la réponse définitive. En tout état
        de cause, l’Utilisateur recevra une réponse définitive au plus tard dans un délai de
        trente-cinq (35) Jours Ouvrables suivant la réception de la réclamation.
      </p>
      <p>
        L’Utilisateur est informé que la CSSF (Commission de Surveillance du Secteur financier) est
        compétente pour régler sur une base extrajudiciaire les litiges relatifs à l’exécution du
        présent Contrat-Cadre. Pour plus d’informations sur la CSSF et sur les conditions d’un tel
        recours, l’Utilisateur peut s’adresser au Service client du Distributeur ou consulter le
        site internet de la CSFF (http://www.cssf.lu). Les demandes de médiation doivent être
        adressées à Monsieur le Médiateur de la Commission de Surveillance du Secteur Financier
        (CSSF), 283 route d’Arlon, L-1150 Luxembourg, (direction@cssf.lu) et ce, sans préjudice des
        autres voies d’actions légales. Cependant, le médiateur ne peut être saisi, si la demande
        est manifestement infondée ou abusive, si le litige a été précédemment examiné ou est en
        cours d'examen par un autre médiateur ou par un tribunal, si la demande auprès du médiateur
        est introduite dans un délai supérieur à un an à compter de la réclamation écrite auprès du
        professionnel, si le litige n'entre pas dans le champ de compétence du médiateur.
      </p>
      <h2>27. Langue – Droit applicable et juridiction compétente</h2>
      <p>
        Sauf en cas d'application d'une loi d’ordre public (laquelle ne s'appliquera que dans les
        strictes limites de son objet), il est expressément stipulé que l’anglaisest la langue
        choisie et utilisée par les Parties dans leurs relations précontractuelles et
        contractuelles, et que le Contrat-cadre est soumis à la loi française et que tout litige
        entre les Parties au titre de ce dernier sera soumis à la juridiction des tribunaux français
        compétents.
      </p>
    </div>
  );
};

export default MangoFr;
