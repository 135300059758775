import React from 'react';
import loadable from '@loadable/component';

import config from '../config';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import LoadingPage from '../containers/LoadingPage/LoadingPage';
// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ '../containers/AboutPage/AboutPage'),{ fallback: <LoadingPage /> });
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'),{ fallback: <LoadingPage /> });
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'),{ fallback: <LoadingPage /> });

const MangopayCheckoutPage = loadable(() => import(/* webpackChunkName: "MangopayCheckoutPage" */ '../containers/CheckoutPage/MangopayCheckoutPage'),{ fallback: <LoadingPage /> });

const MangopayCheckoutWithCard = loadable(() => import(/* webpackChunkName: "MangopayCheckoutWithCard" */ '../containers/CheckoutPage/MangopayCheckoutWithCard'),{ fallback: <LoadingPage /> });
const MangopayCheckoutWithCardAndWallet = loadable(() => import(/* webpackChunkName: "MangopayCheckoutWithCardAndWallet" */ '../containers/CheckoutPage/MangopayCheckoutWithCardAndWallet'),{ fallback: <LoadingPage /> });

const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'),{ fallback: <LoadingPage /> });
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'),{ fallback: <LoadingPage /> });
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'),{ fallback: <LoadingPage /> });
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'),{ fallback: <LoadingPage /> });
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'),{ fallback: <LoadingPage /> });
const ListingPageHeroImage = loadable(() => import(/* webpackChunkName: "ListingPageHeroImage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageHeroImage'),{ fallback: <LoadingPage /> });
const ListingPageFullImage = loadable(() => import(/* webpackChunkName: "ListingPageFullImage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageFullImage'),{ fallback: <LoadingPage /> });
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'),{ fallback: <LoadingPage /> });
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'),{ fallback: <LoadingPage /> });
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'),{ fallback: <LoadingPage /> });
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'),{ fallback: <LoadingPage /> });
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'),{ fallback: <LoadingPage /> });
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'),{ fallback: <LoadingPage /> });
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'),{ fallback: <LoadingPage /> });
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'),{ fallback: <LoadingPage /> });
const SearchPageWithMap = loadable(() => import(/* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithMap'),{ fallback: <LoadingPage /> });
const SearchPageWithList = loadable(() => import(/* webpackChunkName: "SearchPageWithList" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithList'),{ fallback: <LoadingPage /> });
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "MangopayPayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'),{ fallback: <LoadingPage /> });
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'),{ fallback: <LoadingPage /> });
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'),{ fallback: <LoadingPage /> });
const CategoryPage = loadable(() => import(/*webpackChunkName:"CategoryPage"*/ '../containers/CategoryPage/CategoryPage'),{ fallback: <LoadingPage /> });
const MangopayPaymentMethodsPage = loadable(() => import(/*webpackChunkName:"MangopayPaymentMethodsPage"*/ '../containers/MangopayPaymentMethodsPage/MangopayPaymentMethodsPage'),{ fallback: <LoadingPage /> });
const MangopayCardsPage = loadable(() => import(/*webpackChunkName:"CardsPage"*/ '../containers/CardsPage/CardsPage'),{ fallback: <LoadingPage /> });
const BalancePage = loadable(() => import(/*webpackChunkName:"BalancePage"*/ '../containers/BalancePage/BalancePage'),{ fallback: <LoadingPage /> });
const FaqPage = loadable(() => import(/*webpackChunkName:"FaqPage"*/ '../containers/FaqPage/FaqPage'),{ fallback: <LoadingPage /> });
// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'),{ fallback: <LoadingPage /> },{ fallback: <LoadingPage /> });
const MangopayPayoutPage = loadable(() => import(/* webpackChunkName: "MangopayPayoutPage" */ '../containers/MangopayPayoutPage/MangopayPayoutPage'),{ fallback: <LoadingPage /> },{ fallback: <LoadingPage /> });
const ContactUsPage = loadable(() => import(/* webpackChunkName: "ContactUsPage" */ '../containers/ContactUsPage/ContactUsPage'));
const SuccessPageContact = loadable(() => import(/* webpackChunkName: "SuccessPage" */ '../containers/ContactUsPage/SuccessPage'));
const MangopayPolicyPage = loadable(() => import(/* webpackChunkName: "MangopayPolicyPage" */ '../containers/MangopayPolicyPage/MangopayPolicyPage'),{ fallback: <LoadingPage /> });
const MangopayWalletComfirmation = loadable(() => import(/* webpackChunkName: "MangopayWalletComfirmation" */ '../containers/MangopayPayoutPage/MangopayWalletComfirmation'),{ fallback: <LoadingPage /> });
const SearchPage = config.searchPageVariant === 'map' ? SearchPageWithMap : SearchPageWithList;
const ListingPage = config.listingPageLayout === 'full-image' ? ListingPageFullImage : ListingPageHeroImage;
const MaintenancePage = loadable(() => import(/* webpackChunkName: "MaintenancePage" */ '../containers/MaintenancePage/MaintenancePage'),{ fallback: <LoadingPage /> });
const WishlistPage = loadable(() => import(/* webpackChunkName: "WishlistPage" */ /* webpackPrefetch: true */  '../containers/WishlistPage/WishlistPage'),{ fallback: <LoadingPage /> });
const BatchListPage = loadable(() => import(/* webpackChunkName: "BatchListPage" */ '../containers/BatchListPage/BatchListPage'),{ fallback: <LoadingPage /> });
const SellerFavouritePage = loadable(() => import(/* webpackChunkName: "WishlistPage" */ /* webpackPrefetch: true */  '../containers/WishlistPage/SellerFavouritePage'),{ fallback: <LoadingPage /> });
const ShippingMethodsPage = loadable(() => import(/* webpackChunkName: "ShippingMethodsPage" */ '../containers/ShippingMethodsPage/ShippingMethodsPage'),{ fallback: <LoadingPage /> });
const DiscountPage = loadable(() => import(/* webpackChunkName: "DiscountPage" */ '../containers/DiscountPage/DiscountPage'),{ fallback: <LoadingPage /> });
export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'MangopayPayoutPage',
  'MangopayWalletComfirmation',
  'MangopayPaymentMethodsPage',
];


const CheckoutDecider =(props) => {
  const {location} = props
  const [_,__, slug, id] = location.pathname.split('/')
  
  const mode = location?.state?.mode
  const Component = mode == config.PAYMENT_METHODS.CARD_WALLET ? MangopayCheckoutWithCardAndWallet : mode == config.PAYMENT_METHODS.CARD ? MangopayCheckoutWithCard : mode == config.PAYMENT_METHODS.WALLET ? MangopayCheckoutPage : null
  if(!Component) {
    return <NamedRedirect name="ListingPage" params={{
      slug,
      id
    }} />
  }
  return <Component {...props} />
}

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData:pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/contact',
      name: 'ContactUsPage',  
      component: ContactUsPage
    },
    {
      path: '/successContact',
      name: 'successPageContact',  
      component: SuccessPageContact
    },
    {
      path:'/faq',
      name:'FaqPage',
      component:FaqPage
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/account/payments',
      name: 'MangopayPayoutPage',
      component: MangopayPayoutPage,
    },
    {
      path:'/account/wallet',
      name:'MangopayWalletComfirmation',
      component:MangopayWalletComfirmation
    },
    {
      path: '/account/cards',
      name: 'CardsPage',
      component: MangopayCardsPage,
      loadData: pageDataLoadingAPI.CardsPage.loadData
    },
    {
      path: '/account/balance',
      name: 'BalancePage',
      component: BalancePage,
      loadData: pageDataLoadingAPI.BalancePage.loadData
    },
    {
      path: '/account/shipping-methods',
      name: 'ShippingMethodsPage',
      component: ShippingMethodsPage,
    },
    {
      path: '/account/discount',
      name: 'DiscountPage',
      component: DiscountPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
   
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutDecider,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
      loadData: pageDataLoadingAPI.CheckoutPage.loadData,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/listing/create/:category/:subcategory/:subSubCategory',
      name: 'NewListingPageWithcategory',
      auth: true,
      component: (props) => {
        return <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details',category:props.params.category, subcategory:props.params.subcategory,subSubCategory:props.params.subSubCategory }}
        />
      },
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: (props) => {
        console.log(props)
       return <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      },
    },
    {
      path: '/l/:slug/:id/:type/:tab/:category?/:subcategory?/:subSubCategory?',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    // {
    //   path: '/l/new',
    //   name: 'NewListingPage',
    //   auth: true,
    //   component: () => (
    //     <NamedRedirect
    //       name="EditListingPage"
    //       params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
    //     />
    //   ),
    // },
    // {
    //   path: '/l/:slug/:id/:type/:tab',
    //   name: 'EditListingPage',
    //   auth: true,
    //   component: EditListingPage,
    //   loadData: pageDataLoadingAPI.EditListingPage.loadData,
    // },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path:'/category',
      name:'CategoryPage',
      auth: true,
      component: CategoryPage
    },
    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/maintenance',
      name:'MaintenancePage',
      component:MaintenancePage
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings/:tab?',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
    path:'/batch-listings/:id',
    name:'BatchListPage',
    auth:true,
    authPage:'LoginPage',
    component:BatchListPage,
    loadData:pageDataLoadingAPI.BatchListPage.loadData
   
    },
    // {
    //   path: '/account/payments',
    //   name: 'StripePayoutPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: StripePayoutPage,
    //   loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    // },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'MangopayPaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: MangopayPaymentMethodsPage,
      loadData: pageDataLoadingAPI.CardsPage.loadData
      // loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/cgu-mangopay',
      name: 'MangopayPolicyPage',
      component: MangopayPolicyPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/wishlist',
      name: 'WishlistPage',
      component: WishlistPage,
      auth: true,
      loadData: pageDataLoadingAPI.WishlistPage.loadData,
    },
    {
      path: '/fav-sellers',
      name: 'SellerFavouritePage',
      component: SellerFavouritePage,
      auth: true,
      loadData: pageDataLoadingAPI.SellerFavouritePage.loadData,
    },
  ];
};

export default routeConfiguration;
