import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';
import PrivacyPolicyEnglish from './PrivacyPolicyEnglish';
import PrivacyPolicyFrench from './PrivacyPolicyFrench';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  let cookieValue;
  if (typeof window !== 'undefined') {
    cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'french';
  }
  return (
    <div className={classes}>
      {cookieValue == 'english' ? (
        <PrivacyPolicyEnglish classes={classes} />
      ) : (
        <PrivacyPolicyFrench />
      )}
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
