import React from 'react';
import classNames from 'classnames';
import css from './Tip.module.css';
import { AiFillInfoCircle } from 'react-icons/ai';

function InfoTip({ message }) {
  return (
    <div>
      <div className={classNames(css.verificiationBox, css.verficiationNeededBox)}>
        <div className={css.verificiationBoxTextWrapper}>
          <div className={css.verificationBoxTitle}>
            <AiFillInfoCircle className={css.tipIcon} />
            <div className={css.verificationBoxTitle}>{message}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoTip;
