import React from 'react';
import css from './CustomLoading.module.css';
function CustomLoading() {
  return (
    <div className={css.spinner}>
      <div className={css.bounce1}></div>
      <div className={css.bounce2}></div>
      <div className={css.bounce3}></div>
    </div>
  );
}

export default CustomLoading;
