import React from 'react';

function Error({ errors }) {
  const jsx = errors ? (
    Array.isArray(errors) ? (
      <ul className="pl-4 list-disc mt-0">
        {errors.map(error => (
          <li key={error} className="text-red-500">
            {error}
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-red-500 mt-0 leading-snug mb-2">Error: {errors}</p>
    )
  ) : null;
  return jsx;
}

export default Error;
