import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';
import TermsOfServiceEnglish from './TermsOfServiceEnglish';
import TermsOfServiceFrench from './TermsOfServiceFrench';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  let cookieValue;
  if (typeof window !== 'undefined') {
    cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'french';
  }
  return (
    <div className={classes}>
      {cookieValue == 'english' ? <TermsOfServiceEnglish /> : <TermsOfServiceFrench />}
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
