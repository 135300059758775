// ================ Action types ================ //

import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { addListingToWishlist, showFavouriteSellers } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ADD_WISHLIST_REQUEST = 'app/WishlistPage/ADD_WISHLIST_REQUEST';
export const ADD_WISHLIST_SUCCESS = 'app/WishlistPage/ADD_WISHLIST_SUCCESS';
export const ADD_WISHLIST_ERROR = 'app/WishlistPage/ADD_WISHLIST_ERROR';

export const FETCH_WISHLIST_REQUEST = 'app/WishlistPage/FETCH_WISHLIST_REQUEST';
export const FETCH_WISHLIST_SUCCESS = 'app/WishlistPage/FETCH_WISHLIST_SUCCESS';
export const FETCH_WISHLIST_ERROR = 'app/WishlistPage/FETCH_WISHLIST_ERROR';

export const REMOVE_WISHLIST_REQUEST = 'app/WishlistPage/REMOVE_WISHLIST_REQUEST';
export const REMOVE_WISHLIST_SUCCESS = 'app/WishlistPage/REMOVE_WISHLIST_SUCCESS';
export const REMOVE_WISHLIST_ERROR = 'app/WishlistPage/REMOVE_WISHLIST_ERROR';

export const FETCH_FAV_SELLER_REQUEST = 'app/WishlistPage/FETCH_FAV_SELLER_REQUEST';
export const FETCH_FAV_SELLER_SUCCESS = 'app/WishlistPage/FETCH_FAV_SELLER_SUCCESS';
export const FETCH_FAV_SELLER_ERROR = 'app/WishlistPage/FETCH_FAV_SELLER_ERROR';
// ================ Reducer ================ //
const RESULT_PAGE_SIZE = 24;
const initialState = {
  wishlist: null,
  fetchWishlistInProgress: false,
  fetchWishlistError: null,
  addWishlistInProgress: false,
  addWishlistError: null,
  wishlistId: null,
  wishlistCount: null,
  pagination: null,
  currentPageResultIds: [],
  removeWishlistLoading: false,
  removeWishlistError: null,
  removeListingId: null,
  favouriteSeller: [],
  favouriteSellerError: null,
  favouriteSellerLoading: false,
  sellerPagination: null,
};
const resultIds = data => data.data.map(l => l.id);
const currentUserWishList = cu =>
  cu?.attributes?.profile?.protectedData?.wishlist &&
  cu?.attributes?.profile?.protectedData?.wishlist?.length
    ? cu.attributes.profile.protectedData.wishlist
    : [];
const WishlistPage = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WISHLIST_REQUEST:
      return {
        ...state,
        fetchWishlistInProgress: true,
        fetchWishlistError: null,
      };
    case FETCH_WISHLIST_SUCCESS:
      return {
        ...state,
        // wishlist: payload,
        fetchWishlistInProgress: false,
        pagination: payload.data.meta,
        wishlist: payload.data,
        currentPageResultIds: resultIds(payload.data),
      };
    case FETCH_WISHLIST_ERROR:
      return {
        ...state,
        fetchWishlistInProgress: false,
        fetchWishlistError: payload,
      };
    case ADD_WISHLIST_REQUEST:
      return {
        ...state,
        addWishlistInProgress: true,
        addWishlistError: null,
        wishlistId: payload,
      };
    case ADD_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlistCount: {
          ...state.wishlistCount,
          [payload.listingId]: payload.wishlistCount,
        },
        addWishlistInProgress: false,
        wishlistId: null,
      };
    case ADD_WISHLIST_ERROR:
      return {
        ...state,
        addWishlistInProgress: false,
        addWishlistError: payload,
        wishlistId: null,
      };
    case REMOVE_WISHLIST_REQUEST:
      return {
        ...state,
        removeWishlistLoading: true,
        removeWishlistError: null,
        removeListingId: payload,
      };
    case REMOVE_WISHLIST_SUCCESS:
      return {
        ...state,
        removeWishlistLoading: false,
        removeWishlistError: null,
        currentPageResultIds: state.currentPageResultIds.filter(id => id !== payload),
        removeListingId: null,
      };
    case REMOVE_WISHLIST_ERROR:
      return {
        ...state,
        removeWishlistLoading: false,
        removeWishlistError: payload,
        removeListingId: null,
      };
    case FETCH_FAV_SELLER_REQUEST:
      return {
        ...state,
        favouriteSellerLoading: true,
        favouriteSellerError: null,
      };
    case FETCH_FAV_SELLER_SUCCESS:
      return {
        ...state,
        favouriteSellerLoading: false,
        favouriteSeller: payload,
        sellerPagination: payload.meta,
      };
    case FETCH_FAV_SELLER_ERROR:
      return {
        ...state,
        favouriteSellerLoading: false,
        favouriteSellerError: payload,
      };
    default:
      return state;
  }
};
export default WishlistPage;

// ================ Action creators ================ //

export const fetchWishlistRequest = () => ({
  type: FETCH_WISHLIST_REQUEST,
});

export const fetchWishlistSuccess = response => ({
  type: FETCH_WISHLIST_SUCCESS,
  payload: { data: response.data },
});

export const fetchWishlistError = e => ({
  type: FETCH_WISHLIST_ERROR,
  error: true,
  payload: e,
});

export const addWishlistRequest = listingId => ({
  type: ADD_WISHLIST_REQUEST,
  payload: listingId,
});

export const addWishlistSuccess = (wishlistCount, listingId) => ({
  type: ADD_WISHLIST_SUCCESS,
  payload: { wishlistCount, listingId },
});

export const addWishlistError = e => ({
  type: ADD_WISHLIST_ERROR,
  error: true,
  payload: e,
});

export const removeWishlistRequest = listingId => ({
  type: REMOVE_WISHLIST_REQUEST,
  payload: listingId,
});

export const removeWishlistSuccess = listingId => ({
  type: REMOVE_WISHLIST_SUCCESS,
  payload: listingId,
});

export const removeWishlistError = e => ({
  type: REMOVE_WISHLIST_ERROR,
  error: true,
  payload: e,
});

export const fetchFavouriteSellerRequest = () => ({
  type: FETCH_FAV_SELLER_REQUEST,
});

export const fetchFavouriteSellerSuccess = response => ({
  type: FETCH_FAV_SELLER_SUCCESS,
  payload: response.data,
});

export const fetchFavouriteSellerError = e => ({
  type: FETCH_FAV_SELLER_ERROR,
  error: true,
  payload: e,
});
// ================ Thunks ================ //
export const fetchListings = search => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { isAuthenticated } = getState().Auth;
  dispatch(fetchWishlistRequest());
  dispatch(fetchCurrentUser());
  if (!isAuthenticated) {
    // Make sure current user is null
    return Promise.resolve({});
  }
  const { page = 1 } = queryParams;
  return sdk.currentUser
    .show()
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      return currentUser;
    })
    .then(cu => {
      const IdsInWishlist = currentUserWishList(cu);

      const start = (page - 1) * RESULT_PAGE_SIZE;
      const end = page * RESULT_PAGE_SIZE;
      const listingIds = IdsInWishlist.slice(start, end);

      const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
      const aspectRatio = aspectHeight / aspectWidth;
      const searchParams = {
        // perPage: RESULT_PAGE_SIZE,
        // page,
        include: ['author', 'images', 'currentStock'],
        ids: listingIds,
        'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      };

      return sdk.listings
        .query(searchParams)
        .then(response => {
          // console.log('response', response);
          //filter no ids in wishlist
          const filterIdFromListingIds = listingIds.filter(id => {
            return response.data.data.find(item => {
              return item?.id?.uuid === id;
            });
          });
          // console.log('filterIdFromListingIds', filterIdFromListingIds);
          const updatedResponse = {
            ...response,
            data: {
              ...response.data,
              data: filterIdFromListingIds.map(id =>
                response.data.data.find(item => {
                  return item?.id?.uuid === id;
                })
              ),
            },
          };
          // console.log('updatedResponse', updatedResponse);
          dispatch(fetchWishlistSuccess(updatedResponse));

          dispatch(addMarketplaceEntities(updatedResponse));
          return updatedResponse;
        })
        .catch(e => {
          console.log('error', storableError(e));
          dispatch(fetchWishlistError(storableError(e)));
          //   throw e;
        });
    });
};
export const fetchFavoriteSellers = () => (dispatch, getState, sdk) => {
  dispatch(fetchFavouriteSellerRequest());
  console.log('fetchFavouriteSellerRequest');
  return showFavouriteSellers()
    .then(response => {
      // console.log('response', response);
      dispatch(fetchCurrentUser());
      dispatch(fetchFavouriteSellerSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchFavouriteSellerError(storableError(e)));
    });
};
export const addToWishlist = listingId => (dispatch, getState, sdk) => {
  dispatch(addWishlistRequest(listingId));
  return addListingToWishlist({ listingId })
    .then(response => {
      const wishlistCount = response.wishlistCount;
      dispatch(fetchCurrentUser());
      dispatch(addWishlistSuccess(wishlistCount, listingId));
    })
    .catch(e => {
      dispatch(addWishlistError(storableError(e)));
    });
};
export const removeFromWishlist = listingId => (dispatch, getState, sdk) => {
  dispatch(removeWishlistRequest(listingId));
  return addListingToWishlist({ listingId })
    .then(response => {
      dispatch(fetchListings());
      dispatch(fetchCurrentUser());
      dispatch(removeWishlistSuccess(listingId));

      return response;
    })
    .catch(e => {
      dispatch(removeWishlistError(storableError(e)));
    });
};
export const loadData = (params, search) => (dispatch, getState, sdk) => {
  if (typeof window !== 'undefined') {
    return Promise.all([dispatch(fetchFavoriteSellers()), dispatch(fetchListings(search))]);
  }
};
