import React from 'react';
import { useIntl } from '../../util/reactIntl';
import IconSpinner from '../IconSpinner/IconSpinner';

function Loading(props) {
  const { loadingText } = props;
  const intl = useIntl();
  return (
    <div className="flex items-center gap-4">
      <IconSpinner /> <span className="text-base">{intl.formatMessage({ id: loadingText })}</span>
    </div>
  );
}

Loading.defaultProps = {
  loadingText: 'Loading.loadingText',
};

export default Loading;
