import {
  fetchPayoutsFromAPI,
  getCurrentUserCards,
  getWalletBalance,
  getWalletBalanceForUserFromAPI,
} from '../../util/api';

const initialState = {
  payinBalance: {},
  payinBalanceInProgress: false,
  payinBalanceError: null,
  buyerBalance: {},
  buyerBalanceInProgress: false,
  buyerBalanceError: null,
  payouts: [],
  payoutsInProgress: false,
  payoutsError: null,
};

const FETCH_PAYIN_BALANCE_REQUEST = 'app/BalancePage/FETCH_PAYIN_BALANCE_REQUEST';
const FETCH_PAYIN_BALANCE_SUCCESS = 'app/BalancePage/FETCH_PAYIN_BALANCE_SUCCESS';
const FETCH_PAYIN_BALANCE_FAIL = 'app/BalancePage/FETCH_PAYIN_BALANCE_FAIL';

const FETCH_BUYER_BALANCE_REQUEST = 'app/BalancePage/FETCH_BUYER_BALANCE_REQUEST';
const FETCH_BUYER_BALANCE_SUCCESS = 'app/BalancePage/FETCH_BUYER_BALANCE_SUCCESS';
const FETCH_BUYER_BALANCE_FAIL = 'app/BalancePage/FETCH_BUYER_BALANCE_FAIL';

const FETCH_PAYOUTS_REQUEST = 'app/BalancePage/FETCH_PAYOUTS_REQUEST';
const FETCH_PAYOUTS_SUCCESS = 'app/BalancePage/FETCH_PAYOUTS_SUCCESS';
const FETCH_PAYOUTS_FAIL = 'app/BalancePage/FETCH_PAYOUTS_FAIL';

export default function balancePageReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case FETCH_BUYER_BALANCE_REQUEST:
      return {
        ...state,
        buyerBalanceInProgress: true,
        buyerBalanceError: null,
      };
    case FETCH_BUYER_BALANCE_SUCCESS:
      return {
        ...state,
        buyerBalance: payload,
        buyerBalanceInProgress: false,
        buyerBalanceError: null,
      };
    case FETCH_BUYER_BALANCE_FAIL:
      return {
        ...state,
        buyerBalanceInProgress: false,
        buyerBalanceError: payload,
      };
    case FETCH_PAYIN_BALANCE_REQUEST:
      return {
        ...state,
        payinBalanceInProgress: true,
        payinBalanceError: null,
      };
    case FETCH_PAYIN_BALANCE_SUCCESS:
      return {
        ...state,
        payinBalance: payload,
        payinBalanceInProgress: false,
        payinBalanceError: null,
      };
    case FETCH_PAYIN_BALANCE_FAIL:
      return {
        ...state,
        payinBalanceInProgress: false,
        payinBalanceError: payload,
      };
    case FETCH_PAYOUTS_REQUEST:
      return {
        ...state,
        payoutsInProgress: true,
        payoutsError: null,
      };
    case FETCH_PAYOUTS_SUCCESS:
      return {
        ...state,
        payouts: payload,
        payoutsInProgress: false,
        payoutsError: null,
      };
    case FETCH_PAYOUTS_FAIL:
      return {
        ...state,
        payoutsInProgress: false,
        payoutsError: payload,
      };
    default:
      return state;
  }
}

export const fetchPayinBalance = () => dispatch => {
  dispatch({ type: FETCH_PAYIN_BALANCE_REQUEST });
  const isBrowser = typeof window != 'undefined';
  if (!isBrowser) return Promise.resolve();
  // return getWalletBalance({ type: 'payin' })
  return getWalletBalanceForUserFromAPI()
    .then(res => {
      dispatch({ type: FETCH_PAYIN_BALANCE_SUCCESS, payload: res });
    })
    .catch(err => {
      const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
      dispatch({ type: FETCH_PAYIN_BALANCE_FAIL, payload: errors });
    });
};

export const fetchBuyerBalance = () => dispatch => {
  dispatch({ type: FETCH_BUYER_BALANCE_REQUEST });
  // return getWalletBalance({ type: 'buyer' })
  return getWalletBalance({ type: 'payin' })
    .then(res => {
      dispatch({ type: FETCH_BUYER_BALANCE_SUCCESS, payload: res });
    })
    .catch(err => {
      const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
      dispatch({ type: FETCH_BUYER_BALANCE_FAIL, payload: errors });
    });
};
export const fetchPayouts = () => dispatch => {
  dispatch({ type: FETCH_PAYOUTS_REQUEST });
  return fetchPayoutsFromAPI()
    .then(res => {
      dispatch({ type: FETCH_PAYOUTS_SUCCESS, payload: res });
    })
    .catch(err => {
      const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
      dispatch({ type: FETCH_PAYOUTS_FAIL, payload: errors });
    });
};

const fetchBalance = () => dispatch => {
  const isBrowser = typeof window != 'undefined';
  if (!isBrowser) return Promise.resolve();

  dispatch(fetchPayinBalance());
  // dispatch(fetchBuyerBalance());
  dispatch(fetchPayouts());
  return Promise.resolve();
};

export const loadData = (params, search) => {
  return fetchBalance();
};
