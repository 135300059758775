import React from 'react';
import css from './PrivacyPolicy.module.css';
const PrivacyPolicyFrench = () => {
  return (
    <div>
      <div>
        <h1>
          <b>
            <span lang="FR">Politique de confidentialité</span>
          </b>
        </h1>

        <p>
          <span>Dernière mise à jour : 1er décembre 2022.</span>
        </p>

        <p>
          <span>
            Cette politique de confidentialité décrit nos politiques et procédures sur la collecte,
            l'utilisation et la divulgation de vos informations lorsque vous utilisez le service et
            vous informe sur vos droits en matière de confidentialité et sur la manière dont la loi
            vous protège.
          </span>
        </p>
        <p>
          <span>
            Nous utilisons vos données personnelles pour fournir et améliorer le service. En
            utilisant le Service, Vous acceptez la collecte et l'utilisation d'informations
            conformément à la présente Politique de confidentialité.
          </span>
        </p>
        <p>
          <span>
            Veuillez lire attentivement cette politique de confidentialité avant d'utiliser notre
            service.
          </span>
        </p>

        <p>
          <span>1 - Interprétation et définitions</span>
        </p>
        <p>
          <span>1.1 - Interpr</span>
          <span lang="FR">é</span>
          <span>tation</span>
        </p>

        <p>
          <span>
            Les mots dont la lettre initiale est en majuscule ont des significations définies dans
            les conditions suivantes. Les définitions suivantes auront le même sens qu'elles
            apparaissent au singulier ou au pluriel.
          </span>
        </p>

        <p>
          <span>1.2 – D</span>
          <span>é</span>
          <span>finitions</span>
        </p>

        <p>
          <span>Aux fins de la présente politique de confidentialité&nbsp;:</span>
        </p>

        <li>
          <b>
            <span>Compte</span>
          </b>
          <span>
            {' '}
            désigne un compte unique créé pour vous permettre d'accéder à notre service ou à des
            parties de notre service.
          </span>
        </li>
        <li>
          <b>
            <span>Affilié</span>
          </b>
          <span>
            {' '}
            désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une
            partie, où "contrôle" signifie la propriété de 50&nbsp;% ou plus des actions, des
            participations ou d'autres titres habilités à voter pour l'élection des administrateurs
            ou autre autorité de gestion.
          </span>
        </li>
        <li>
          <b>
            <span>Application</span>
          </b>
          <span>
            {' '}
            désigne le logiciel fourni par la Société ou l'Exploitant téléchargé par Vous sur tout
            appareil électronique, nommé Sewtoo.
          </span>
        </li>
        <li>
          <b>
            <span>Entreprise</span>
          </b>
          <span>
            {' '}
            désigne la Société ou l'Opérateur en tant qu'entité juridique qui collecte les
            informations personnelles des Consommateurs et détermine les finalités et les moyens du
            traitement des informations personnelles des Consommateurs, ou pour le compte de
            laquelle ces informations sont collectées et cela seul, ou conjointement avec d'autres ,
            détermine les finalités et les moyens du traitement des informations personnelles des
            consommateurs.
          </span>
        </li>
        <li>
          <b>
            <span>Société</span>
          </b>
          <span> (ci-après dénommée «&nbsp;la Société&nbsp;», «&nbsp;Nous&nbsp;»</span>
          <span> </span>
          <span>
            ou «&nbsp;Notre&nbsp;» dans le présent Contrat) désigne Sewtoo, dont le siège social est
            situé à Vernouillet (28500), 8 rue André-Marie Ampère et immatriculée au au Registre du
            Commerce et des Sociétés de Chartres sous le numéro 921 691 937. Au sens du RGPD, la
            Société ou l'Exploitant est le Responsable de traitement.<b>Consumer</b>, refers to You.
          </span>
        </li>
        <li>
          <b>
            <span>Les cookies</span>
          </b>
          <span>
            {' '}
            sont de petits fichiers qui sont placés sur votre ordinateur, appareil mobile ou tout
            autre appareil par un site Web, contenant les détails de votre historique de navigation
            sur ce site Web parmi ses nombreuses utilisations.
          </span>
        </li>
        <li>
          <b>
            <span>Le pays</span>
          </b>
          <span> fait référence à la FRANCE</span>
        </li>
        <li>
          <b>
            <span>Responsable du Traitement</span>
          </b>
          <span>
            , aux fins du RGPD (Règlement Général sur la Protection des Données), désigne la Société
            ou l'Exploitant comme la personne morale qui, seule ou conjointement avec d'autres,
            détermine les finalités et les moyens du traitement des Données Personnelles.
          </span>
        </li>
        <li>
          <b>
            <span>Appareil</span>
          </b>
          <span>
            {' '}
            désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone
            portable ou une tablette numérique.
          </span>
        </li>
        <li>
          <b>
            <span>Do Not Track (DNT)</span>
          </b>
          <span>
            {' '}
            est un concept qui a été promu par les autorités réglementaires américaines, en
            particulier la Federal Trade Commission (FTC) des États-Unis, pour que l'industrie
            Internet développe et mette en œuvre un mécanisme permettant aux internautes de
            contrôler le suivi de leurs activités en ligne sur les sites Web.
          </span>
        </li>
        <li>
          <b>
            <span>L'Opérateur </span>
          </b>
          <span>(appelé soit «&nbsp;l'Opérateur&nbsp;», «&nbsp;Nous&nbsp;»</span>
          <span> </span>
          <span>
            ou «&nbsp;Notre&nbsp;» dans le présent Contrat) fait référence à Sewtoo. Aux fins du
            RGPD, la Société ou l'Exploitant est le Responsable du traitement.
          </span>
        </li>
        <li>
          <b>
            <span>Les données personnelles</span>
          </b>
          <span>
            sont toutes les informations relatives à une personne identifiée ou identifiable.
          </span>
        </li>

        <p>
          <span>
            Aux fins du RGPD, les Données Personnelles désignent toute information Vous concernant
            telle qu'un nom, un numéro d'identification, des données de localisation, un identifiant
            en ligne ou à un ou plusieurs facteurs propres à l'environnement physique,
            physiologique, génétique, mental, économique, culturel ou social. identité. Aux fins du
            CCPA, les données personnelles désignent toute information qui vous identifie, se
            rapporte à, décrit ou est susceptible d'être associée à, ou pourrait raisonnablement
            être liée, directement ou indirectement, à vous.
          </span>
        </p>

        <li>
          <b>
            <span>Vente</span>
          </b>
          <span>
            , désigne la vente, la location, la publication, la divulgation, la diffusion, la mise à
            disposition, le transfert ou la communication orale, écrite, électronique ou autre, des
            informations personnelles d'un Consommateur à une autre entreprise ou à un tiers à des
            fins monétaires ou autres. considération.
          </span>
        </li>
        <li>
          <b>
            <span>Service</span>
          </b>
          <span> fait référence à l'Application ou au Site Web ou aux deux.</span>
        </li>
        <li>
          <b>
            <span>Prestataire</span>
          </b>
          <span>
            {' '}
            désigne toute personne physique ou morale qui traite les données pour le compte de la
            Société ou de l'Exploitant. Il fait référence à des sociétés tierces ou à des personnes
            employées par la Société ou l'Opérateur pour faciliter le Service, pour fournir le
            Service au nom de la Société ou de l'Opérateur, pour fournir des services liés au
            Service ou pour aider la Société ou l'Opérateur à analyser comment le Le service est
            utilisé. Aux fins du RGPD, les fournisseurs de services sont considérés comme des
            processeurs de données.
          </span>
        </li>
        <li>
          <b>
            <span>Service de médias sociaux</span>
          </b>
          <span>
            tiers désigne tout site Web ou tout site Web de réseau social par lequel un utilisateur
            peut se connecter ou créer un compte pour utiliser le service.
          </span>
        </li>
        <li>
          <b>
            <span>Les données d'utilisation</span>
          </b>
          <span>
            font référence aux données collectées automatiquement, soit générées par l'utilisation
            du Service, soit à partir de l'infrastructure du Service elle-même (par exemple, la
            durée d'une visite de page).
          </span>
        </li>
        <li>
          <b>
            <span>Site </span>
          </b>
          <span>désigne Sewtoo, accessible depuis www.sewtoo.com.</span>
        </li>
        <li>
          <b>
            <span>Vous </span>
          </b>
          <span>
            désigne la personne accédant ou utilisant le Service, ou la société ou toute autre
            entité juridique au nom de laquelle cette personne accède ou utilise le Service, selon
            le cas.En vertu du RGPD (Règlement général sur la protection des données), vous pouvez
            être désigné comme la personne concernée ou comme l'utilisateur car vous êtes la
            personne qui utilise le service.
          </span>
        </li>

        <p>
          <span>2 - Contac</span>
          <span lang="FR">tez-Nous</span>
        </p>

        <p>
          <span>
            Si vous avez des questions concernant cette politique de confidentialité, vous pouvez
            nous contacter&nbsp;
          </span>
          <span lang="FR">:</span>
        </p>

        <li>
          <span lang="FR">Par</span>
          <span> email</span>
          <span> </span>
          <span>: </span>
          <span>contact@sewtoo.fr</span>
        </li>
        <li>
          <span lang="FR">En visitant notre site web </span>
          <span>:</span>
          <span>
            <a href="http://www.sewtoo.com">
              <span>www.sewtoo.com</span>
            </a>
          </span>
        </li>

        <p>
          <span>3 - </span>
          <span lang="FR">Collecte et utilisation de vos données personnelles</span>
        </p>

        <p>
          <span>3.1 - Types </span>
          <span lang="FR">de données collectées</span>
        </p>

        <p>
          <span>3.1.1 - </span>
          <span lang="FR">Données collectées</span>
        </p>

        <p>
          <span>
            Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir
            certaines informations personnellement identifiables qui peuvent être utilisées pour
            vous contacter ou vous identifier. Les informations personnellement identifiables
            peuvent inclure, mais sans s'y limiter&nbsp;:
          </span>
        </p>

        <p>
          <span>• Adresse e-mail</span>
        </p>
        <p>
          <span>• Prénom et nom</span>
        </p>
        <p>
          <span>• Numéro de téléphone</span>
        </p>
        <p>
          <span>• Adresse, État, Province, Code postal/ZIP, Ville</span>
        </p>
        <p>
          <span>• Information de compte bancaire</span>
        </p>
        <p>
          <span>• Numéro de carte de crédit et date d'expiration</span>
        </p>
        <p>
          <span>• Données d'utilisation</span>
        </p>

        <p>
          <span>3.1.2 - </span>
          <span lang="FR">Utilisation des données</span>
        </p>

        <p>
          <span>
            Les données d'utilisation sont collectées automatiquement lors de l'utilisation du
            service.
          </span>
        </p>
        <p>
          <span>
            Les données d'utilisation peuvent inclure des informations telles que l'adresse de
            protocole Internet de votre appareil (par exemple, l'adresse IP), le type de navigateur,
            la version du navigateur, les pages de notre service que vous visitez, l'heure et la
            date de votre visite, le temps passé sur ces pages, l'appareil unique identifiants et
            autres données de diagnostic.
          </span>
        </p>
        <p>
          <span>
            Lorsque vous accédez au service par ou via un appareil mobile, nous pouvons collecter
            certaines informations automatiquement, y compris, mais sans s'y limiter, le type
            d'appareil mobile que vous utilisez, l'identifiant unique de votre appareil mobile,
            l'adresse IP de votre appareil mobile, votre système d'exploitation, le type de
            navigateur Internet mobile que vous utilisez, les identifiants uniques de l'appareil et
            d'autres données de diagnostic.
          </span>
        </p>
        <p>
          <span>
            Nous pouvons également collecter des informations que votre navigateur envoie chaque
            fois que vous visitez notre service ou lorsque vous accédez au service par ou via un
            appareil mobile.
          </span>
        </p>

        <p>
          <span>3.1.3 - Informations provenant de services de médias sociaux tiers</span>
        </p>

        <p>
          <span>
            La société ou l'opérateur peut vous permettre de créer un compte et de vous connecter
            pour utiliser le service via les services de médias sociaux tiers suivants. Ces services
            de médias sociaux tiers peuvent inclure, mais sans s'y limiter&nbsp;:
          </span>
        </p>
        <p>
          <span>• Google</span>
        </p>
        <p>
          <span>• Facebook</span>
        </p>
        <p>
          <span>• Twitter</span>
        </p>
        <p>
          <span>• LinkedIn</span>
        </p>
        <p>
          <span>• </span>
          <span>Apple</span>
        </p>
        <p>
          <span>
            Si vous décidez de vous inscrire via ou de nous accorder l'accès à un service de médias
            sociaux tiers, nous pouvons collecter des données personnelles déjà associées au compte
            de votre service de médias sociaux tiers, telles que votre nom, votre adresse e-mail,
            vos activités ou Votre liste de contacts associée à ce compte.
          </span>
        </p>
        <p>
          <span>
            Vous pouvez également avoir la possibilité de partager des informations supplémentaires
            avec la société ou l'opérateur via le compte de votre service de médias sociaux tiers.
            Si vous choisissez de fournir ces informations et données personnelles, lors de
            l'inscription ou autrement, vous autorisez la société ou l'opérateur à les utiliser, les
            partager et les stocker d'une manière conforme à la présente politique de
            confidentialité.
          </span>
        </p>
        <p>
          <span>3.1.4 - Technologies de suivi et cookies</span>
        </p>
        <p>
          <span>
            Nous utilisons des cookies et des technologies de suivi similaires pour suivre
            l'activité sur notre service et stocker certaines informations. Les technologies de
            suivi utilisées sont des balises, des balises et des scripts pour collecter et suivre
            les informations et pour améliorer et analyser notre service. Les technologies que nous
            utilisons peuvent inclure :
          </span>
        </p>
        <p>
          <span>
            ·<span></span>
          </span>
          <b>
            <span>Cookies ou Cookies de navigateur</span>
          </b>
          <span>
            . Un cookie est un petit fichier placé sur Votre Appareil. Vous pouvez demander à Votre
            navigateur de refuser tous les Cookies ou de vous signaler l'envoi d'un Cookie.
            Cependant, si vous n'acceptez pas les cookies, vous ne pourrez peut-être pas utiliser
            certaines parties de notre service. À moins que vous n'ayez réglé les paramètres de
            votre navigateur pour qu'il refuse les cookies, notre service peut utiliser des cookies.
          </span>
        </p>
        <p>
          <span>
            ·<span></span>
          </span>
          <b>
            <span>Balises Web</span>
          </b>
          <span>
            . Certaines sections de notre Service et nos e-mails peuvent contenir de petits fichiers
            électroniques connus sous le nom de balises Web (également appelés gifs transparents,
            pixels invisibles et gifs à pixel unique) qui permettent à la Société ou à l'Opérateur,
            par exemple, de compter les utilisateurs qui ont visité ces pages ou ouvert un e-mail et
            pour d'autres statistiques d'applications ou de sites Web connexes (par exemple,
            enregistrer la popularité d'une certaine section et vérifier l'intégrité du système et
            du serveur).
          </span>
        </p>
        <p>
          <span>
            Les cookies peuvent être des cookies « persistants » ou « de session ». Les cookies
            persistants restent sur votre ordinateur personnel ou votre appareil mobile lorsque vous
            vous déconnectez, tandis que les cookies de session sont supprimés dès que vous fermez
            votre navigateur Web.
          </span>
        </p>
        <p>
          <span>
            Nous utilisons à la fois des cookies de session et des cookies persistants aux fins
            décrites ci-dessous&nbsp;:
          </span>
        </p>

        <p>
          <b>
            <span>• Cookies nécessaires/essentiels</span>
          </b>
        </p>
        <p>
          <span>Type&nbsp;: Cookies de session</span>
        </p>
        <p>
          <span>Administré par : Nous</span>
        </p>
        <p>
          <span>
            Finalité : Ces Cookies sont indispensables pour Vous fournir les services disponibles
            via l'Application ou le Site Internet et pour Vous permettre d'utiliser certaines de ses
            fonctionnalités. Ils aident à authentifier les utilisateurs et à empêcher l'utilisation
            frauduleuse des comptes d'utilisateurs. Sans ces cookies, les services que vous avez
            demandés ne peuvent pas être fournis, et nous n'utilisons ces cookies que pour vous
            fournir ces services.
          </span>
        </p>

        <p>
          <span>
            • <b>Politique relative aux cookies / Avis d'acceptation des cookies</b>
          </span>
        </p>
        <p>
          <span>Type&nbsp;: Cookies persistants</span>
        </p>
        <p>
          <span>Administré par : Nous</span>
        </p>
        <p>
          <span>
            Finalité : Ces Cookies permettent d'identifier si les utilisateurs ont accepté
            l'utilisation de cookies sur l'Application ou le Site Internet.
          </span>
        </p>

        <p>
          <span>
            • <b>Cookies de fonctionnalité</b>
          </span>
        </p>
        <p>
          <span>Type&nbsp;: Cookies persistants</span>
        </p>
        <p>
          <span>Administré par : Nous</span>
        </p>
        <p>
          <span>
            Finalité&nbsp;: ces cookies nous permettent de mémoriser les choix que vous faites
            lorsque vous utilisez l'application ou le site Web, tels que la mémorisation de vos
            informations de connexion ou de votre préférence de langue. Ces Cookies ont pour but de
            Vous offrir une expérience plus personnelle et de Vous éviter d'avoir à ressaisir vos
            préférences à chaque utilisation de l'Application ou du Site.
          </span>
        </p>

        <p>
          <span>
            • <b>Cookies de suivi et de performance</b>
          </span>
        </p>
        <p>
          <span>Type&nbsp;: Cookies persistants</span>
        </p>
        <p>
          <span>Administré par : Tiers</span>
        </p>
        <p>
          <span>
            Objectif&nbsp;: ces cookies sont utilisés pour suivre les informations sur le trafic
            vers l'application ou le site Web et sur la manière dont les utilisateurs utilisent
            l'application ou le site Web. Les informations recueillies via ces cookies peuvent vous
            identifier directement ou indirectement en tant que visiteur individuel. En effet, les
            informations collectées sont généralement liées à un identifiant pseudonyme associé à
            l'appareil que vous utilisez pour accéder à l'Application ou au Site Internet. Nous
            pouvons également utiliser ces cookies pour tester de nouvelles pages, fonctionnalités
            ou nouvelles fonctionnalités de l'application ou du site Web afin de voir comment nos
            utilisateurs y réagissent.
          </span>
        </p>

        <pre>
          <span>3.2 - </span>
          <span lang="FR">Utilisation de vos données personnelles</span>
        </pre>

        <p>
          <span>
            La Société ou l'Opérateur peut utiliser les Données personnelles aux fins
            suivantes&nbsp;:
          </span>
        </p>

        <li>
          <span>
            Pour fournir et maintenir notre Service, y compris pour surveiller l'utilisation de
            notre Service.
          </span>
        </li>
        <li>
          <span>
            Pour gérer Votre Compte : pour gérer Votre inscription en tant qu'utilisateur du
            Service. Les données personnelles que vous fournissez peuvent vous donner accès à
            différentes fonctionnalités du service qui sont à votre disposition en tant
            qu'utilisateur enregistré.
          </span>
        </li>
        <li>
          <span>
            Pour l'exécution d'un contrat : l'élaboration, la conformité et la réalisation du
            contrat d'achat des produits, articles ou services que Vous avez achetés ou de tout
            autre contrat avec Nous par le biais du Service.
          </span>
        </li>
        <li>
          <span>
            Pour vous contacter&nbsp;: pour vous contacter par e-mail, appels téléphoniques, SMS ou
            autres formes équivalentes de communication électronique, telles que les notifications
            push d'une application mobile concernant les mises à jour ou les communications
            informatives relatives aux fonctionnalités, produits ou services sous contrat, y compris
            les mises à jour de sécurité , lorsque cela est nécessaire ou raisonnable pour leur mise
            en œuvre.
          </span>
        </li>
        <li>
          <span>
            Pour vous fournir des actualités, des offres spéciales et des informations générales sur
            d'autres biens, services et événements que nous proposons et qui sont similaires à ceux
            que vous avez déjà achetés ou demandés, sauf si vous avez choisi de ne pas recevoir ces
            informations.
          </span>
        </li>
        <li>
          <span>Pour gérer Vos demandes : Pour assister et gérer Vos demandes à Nous.</span>
        </li>
        <li>
          <span>
            Pour vous proposer des publicités ciblées&nbsp;: nous pouvons utiliser vos informations
            pour développer et afficher du contenu et des publicités (et travailler avec des
            fournisseurs tiers qui le font) adaptés à vos intérêts et/ou à votre emplacement et pour
            mesurer leur efficacité.
          </span>
        </li>
        <li>
          <span>
            Pour les transferts d'entreprise&nbsp;: nous pouvons utiliser vos informations pour
            évaluer ou mener une fusion, une cession, une restructuration, une réorganisation, une
            dissolution ou toute autre vente ou transfert de tout ou partie de nos actifs, que ce
            soit dans le cadre d'une entreprise en activité ou dans le cadre d'une faillite, d'une
            liquidation , ou procédure similaire, dans laquelle les données personnelles que nous
            détenons sur les utilisateurs de nos services font partie des actifs transférés.
          </span>
        </li>
        <li>
          <span>
            À d'autres fins&nbsp;: nous pouvons utiliser vos informations à d'autres fins, telles
            que l'analyse de données, l'identification des tendances d'utilisation, la détermination
            de l'efficacité de nos campagnes promotionnelles et pour évaluer et améliorer notre
            service, nos produits, nos services, notre marketing et votre expérience.
          </span>
        </li>

        <p>
          <span>
            Nous pouvons partager vos informations personnelles dans les situations suivantes&nbsp;:
          </span>
        </p>

        <li>
          <span>
            Avec les fournisseurs de services&nbsp;: nous pouvons partager vos informations
            personnelles avec des fournisseurs de services pour surveiller et analyser l'utilisation
            de notre service, pour vous faire de la publicité sur des sites Web tiers après que vous
            avez visité notre service, pour le traitement des paiements, pour vous contacter.
          </span>
        </li>
        <li>
          <span>
            Pour les transferts d'entreprise&nbsp;: nous pouvons partager ou transférer vos
            informations personnelles dans le cadre de, ou pendant les négociations de, toute
            fusion, vente d'actifs de la société ou de l'opérateur, financement ou acquisition de
            tout ou partie de nos activités à une autre société.
          </span>
        </li>
        <li>
          <span>
            Avec les affiliés&nbsp;: nous pouvons partager vos informations avec nos affiliés,
            auquel cas nous exigerons de ces affiliés qu'ils respectent la présente politique de
            confidentialité. Les sociétés affiliées incluent notre société mère et toutes autres
            filiales, partenaires de coentreprise ou autres sociétés que nous contrôlons ou qui sont
            sous contrôle commun avec nous.
          </span>
        </li>
        <li>
          <span>
            Avec des partenaires commerciaux : nous pouvons partager vos informations avec nos
            partenaires commerciaux pour vous proposer certains produits, services ou promotions.
          </span>
        </li>
        <li>
          <span>
            Avec d'autres utilisateurs : lorsque vous partagez des informations personnelles ou
            interagissez autrement dans les zones publiques avec d'autres utilisateurs, ces
            informations peuvent être vues par tous les utilisateurs et peuvent être diffusées
            publiquement à l'extérieur. Si vous interagissez avec d'autres utilisateurs ou que vous
            vous inscrivez via un service de médias sociaux tiers, vos contacts sur le service de
            médias sociaux tiers peuvent voir votre nom, votre profil, vos photos et la description
            de votre activité. De même, les autres utilisateurs pourront voir les descriptions de
            Votre activité, communiquer avec Vous et consulter Votre profil.
          </span>
        </li>
        <li>
          <span>
            Avec votre consentement : nous pouvons divulguer vos informations personnelles à toute
            autre fin avec votre consentement.
          </span>
        </li>

        <p>
          <span>3.3 - Conservation de vos données personnelles</span>
        </p>

        <p>
          <span>
            La Société ou l'Opérateur ne conservera vos données personnelles que le temps nécessaire
            aux fins énoncées dans la présente politique de confidentialité. Nous conserverons et
            utiliserons vos données personnelles dans la mesure nécessaire pour nous conformer à nos
            obligations légales (par exemple, si nous sommes tenus de conserver vos données pour
            nous conformer aux lois applicables), résoudre les litiges et appliquer nos accords et
            politiques juridiques.
          </span>
        </p>
        <p>
          <span>
            La Société ou l'Opérateur conservera également les Données d'utilisation à des fins
            d'analyse interne. Les données d'utilisation sont généralement conservées pendant une
            période plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité
            ou pour améliorer la fonctionnalité de notre service, ou lorsque nous sommes légalement
            tenus de conserver ces données pendant des périodes plus longues.
          </span>
        </p>
        <p>
          <span>
            Lorsque vos données personnelles ne sont plus requises par la loi ou les droits ou
            obligations de nous ou de vous, nous supprimerons les données personnelles. Dans la
            plupart des cas, les données personnelles seront supprimées à la résiliation ou à
            l'expiration de l'accord entre vous et la société ou l'opérateur ou à votre demande
            écrite.
          </span>
        </p>

        <p>
          <span>3.4 - Transfert de vos données personnelles</span>
        </p>

        <p>
          <span>
            Vos informations, y compris les données personnelles, sont traitées dans les bureaux
            d'exploitation de la société ou de l'opérateur et dans tout autre lieu où se trouvent
            les parties impliquées dans le traitement. Cela signifie que ces informations peuvent
            être transférées et conservées sur des ordinateurs situés en dehors de votre état,
            province, pays ou autre juridiction gouvernementale où les lois sur la protection des
            données peuvent différer de celles de votre juridiction.
          </span>
        </p>

        <p>
          <span>
            Votre consentement à cette politique de confidentialité suivi de votre soumission de ces
            informations représente votre accord à ce transfert.
          </span>
        </p>

        <p>
          <span>
            La Société ou l'Opérateur prendra toutes les mesures raisonnablement nécessaires pour
            s'assurer que Vos données sont traitées en toute sécurité et conformément à la présente
            Politique de confidentialité et aucun transfert de Vos Données personnelles n'aura lieu
            vers une organisation ou un pays à moins qu'il n'y ait des contrôles adéquats en place,
            y compris le la sécurité de vos données et autres informations personnelles.
          </span>
        </p>

        <p>
          <span>3.5 - Supprimer vos données personnelles</span>
        </p>

        <p>
          <span>
            Vous avez le droit de supprimer ou de demander que nous vous aidions à supprimer les
            données personnelles que nous avons collectées à votre sujet.
          </span>
        </p>
        <p>
          <span>
            Notre service peut vous donner la possibilité de supprimer certaines informations vous
            concernant à partir du service.
          </span>
        </p>
        <p>
          <span>
            Vous pouvez mettre à jour, modifier ou supprimer vos informations à tout moment en vous
            connectant à votre compte, si vous en avez un, et en visitant la section des paramètres
            du compte qui vous permet de gérer vos informations personnelles. Vous pouvez également
            nous contacter pour demander l'accès, la correction ou la suppression de toute
            information personnelle que vous nous avez fournie.
          </span>
        </p>
        <p>
          <span>
            Veuillez noter, cependant, que nous pouvons avoir besoin de conserver certaines
            informations lorsque nous avons une obligation légale ou une base légale pour le faire.
          </span>
        </p>

        <p>
          <span>3.6 - Divulgation de vos données personnelles</span>
        </p>

        <p>
          <span>3.6.1 - Transactions commerciales</span>
        </p>

        <p>
          <span>
            Si la Société ou l'Opérateur est impliqué dans une fusion, une acquisition ou une vente
            d'actifs, Vos Données Personnelles peuvent être transférées. Nous vous aviserons avant
            que vos données personnelles ne soient transférées et soumises à une politique de
            confidentialité différente.
          </span>
        </p>

        <p>
          <span>3.6.2 - Forces de l'ordre</span>
        </p>

        <p>
          <span>
            Dans certaines circonstances, la Société ou l'Opérateur peut être tenu de divulguer Vos
            Données Personnelles si la loi l'exige ou en réponse à des demandes valables des
            autorités publiques (par exemple, un tribunal ou une agence gouvernementale).
          </span>
        </p>

        <p>
          <span>3.6.3 - Autres exigences légales</span>
        </p>

        <p>
          <span>
            La Société ou l'Opérateur peut divulguer Vos Données personnelles en croyant de bonne
            foi qu'une telle action est nécessaire pour&nbsp;:
          </span>
        </p>
        <p>
          <span>• Se conformer à une obligation légale</span>
        </p>
        <p>
          <span>
            • Protéger et défendre les droits ou la propriété de la Société ou de l'Opérateur
          </span>
        </p>
        <p>
          <span>
            • Prévenir ou enquêter sur d'éventuels actes répréhensibles en rapport avec le Service
          </span>
        </p>
        <p>
          <span>• Protéger la sécurité personnelle des Utilisateurs du Service ou du public</span>
        </p>
        <p>
          <span>• Protégez-vous contre la responsabilité légale</span>
        </p>

        <p>
          <span>3.7 - Sécurité de vos données personnelles</span>
        </p>

        <p>
          <span>
            La sécurité de vos données personnelles est importante pour nous, mais n'oubliez pas
            qu'aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est
            sécurisée à 100 %. Bien que nous nous efforcions d'utiliser des moyens commercialement
            acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur
            sécurité absolue.
          </span>
        </p>

        <p>
          <span>4 - Informations détaillées sur le traitement de vos données personnelles</span>
        </p>

        <p>
          <span>
            Les Prestataires de Services que Nous utilisons peuvent avoir accès à Vos Données
            Personnelles. Ces fournisseurs tiers collectent, stockent, utilisent, traitent et
            transfèrent des informations sur votre activité sur notre service conformément à leurs
            politiques de confidentialité.
          </span>
        </p>

        <p>
          <span>4.1 - Analytics</span>
        </p>
        <p>
          <span>
            Nous pouvons faire appel à des fournisseurs de services tiers pour surveiller et
            analyser l'utilisation de notre service. Ils peuvent inclure, mais ne sont pas limités
            à&nbsp;:
          </span>
        </p>

        <p>
          <span>• Google Analytics</span>
        </p>

        <p>
          <span>
            Google Analytics est un service d'analyse Web proposé par Google qui suit et signale le
            trafic du site Web. Google utilise les données collectées pour suivre et contrôler
            l'utilisation de notre Service. Ces données sont partagées avec d'autres services
            Google. Google peut utiliser les données collectées pour contextualiser et personnaliser
            les annonces de son propre réseau publicitaire.
          </span>
        </p>

        <p>
          <span>
            Vous pouvez refuser que votre activité sur le Service soit disponible pour Google
            Analytics en installant le module complémentaire de navigateur pour la désactivation de
            Google Analytics. Le module complémentaire empêche le JavaScript de Google Analytics
            (ga.js, analytics.js et dc.js) de partager des informations avec Google Analytics sur
            l'activité des visites.
          </span>
        </p>
        <p>
          <span>
            Vous pouvez désactiver certaines fonctionnalités de Google Analytics via les paramètres
            de votre appareil mobile, tels que les paramètres de publicité de votre appareil ou en
            suivant les instructions fournies par Google dans sa politique de confidentialité&nbsp;:{' '}
          </span>
          <a href="https://policies.google.com/privacy">
            <span>https://policies.google.com/privacy</span>
          </a>
        </p>

        <p>
          <span>
            Pour plus d'informations sur les pratiques de confidentialité de Google, veuillez
            visiter la page Web Google Privacy &amp; Terms:{' '}
          </span>
          <a href="https://policies.google.com/privacy">
            <span>https://policies.google.com/privacy</span>
          </a>
        </p>

        <p>
          <span>• Matomo</span>
        </p>
        <p>
          <span>
            Matomo est un service d'analyse Web. Vous pouvez visiter leur page de politique de
            confidentialité ici :{' '}
          </span>
          <a href="https://matomo.org/privacy-policy">
            <span>https://matomo.org/privacy-policy</span>
          </a>
        </p>

        <p>
          <span>• Brasse</span>
        </p>
        <p>
          <span>
            Fathom est un service d'analyse Web. Vous pouvez visiter leur page de politique de
            confidentialité ici :{' '}
          </span>
          <a href="https://www.fathomhq.com/privacy">
            <span>https://www.fathomhq.com/privacy</span>
          </a>
        </p>

        <p>
          <span>4.2 - Publicité par e-mail</span>
        </p>

        <p>
          <span>
            Nous pouvons utiliser vos données personnelles pour vous contacter avec des newsletters,
            du matériel marketing ou promotionnel et d'autres informations susceptibles de vous
            intéresser. Vous pouvez refuser de recevoir tout ou partie de ces communications de
            notre part en suivant le lien de désabonnement ou les instructions fournies dans tout
            e-mail que nous envoyons ou en nous contactant.
          </span>
        </p>

        <p>
          <span>
            Nous pouvons utiliser des fournisseurs de services de marketing par e-mail pour gérer et
            vous envoyer des e-mails. Ils peuvent inclure, mais ne sont pas limités à&nbsp;:
          </span>
        </p>

        <p>
          <span>•&nbsp;Mailchimp</span>
        </p>

        <p>
          <span>
            Mailchimp est un service d'envoi d'e-mails marketing fourni par The Rocket Science Group
            LLC.
          </span>
        </p>
        <p>
          <span>
            Pour plus d'informations sur les pratiques de confidentialité de Mailchimp, veuillez
            consulter leur politique de confidentialité :{' '}
          </span>
          <a href="https://mailchimp.com/legal/privacy/">
            <span>https://mailchimp.com/legal/privacy/</span>
          </a>
        </p>

        <p>
          <span>4.3 – Paiements</span>
        </p>

        <p>
          <span>
            Nous pouvons fournir des produits et/ou services payants dans le cadre du Service. Dans
            ce cas, nous pouvons utiliser des services tiers pour le traitement des paiements (par
            exemple, des processeurs de paiement).
          </span>
        </p>

        <p>
          <span>
            Nous ne stockerons ni ne collecterons les détails de votre carte de paiement. Ces
            informations sont fournies directement à nos processeurs de paiement tiers dont
            l'utilisation de vos informations personnelles est régie par leur politique de
            confidentialité. Ces processeurs de paiement respectent les normes établies par PCI-DSS,
            gérées par le Conseil des normes de sécurité PCI, qui est un effort conjoint de marques
            telles que Visa, Mastercard, American Express et Discover. Les exigences PCI-DSS
            contribuent à garantir le traitement sécurisé des informations de paiement.
          </span>
        </p>

        <p>
          <span>Ils peuvent inclure, mais ne sont pas limités à&nbsp;:</span>
        </p>

        <p>
          <span>• Mangopay</span>
        </p>

        <p>
          <span>Leur politique de confidentialité peut être consultée sur </span>
          <a href="https://www.mangopay.com/privacy_doc/Privacy_FR.pdf">
            <span>https://www.mangopay.com/privacy_doc/Privacy_FR.pdf</span>
          </a>
        </p>

        <p>
          <span>4.4 - Remarketing comportemental</span>
        </p>

        <p>
          <span>
            La Société ou l'Opérateur utilise des services de remarketing pour vous faire de la
            publicité après avoir accédé ou visité notre Service. Nous et nos fournisseurs tiers
            utilisons des cookies et des technologies autres que les cookies pour nous aider à
            reconnaître votre appareil et à comprendre comment vous utilisez notre service afin que
            nous puissions améliorer notre service pour refléter vos intérêts et vous proposer des
            publicités susceptibles de vous intéresser davantage. à toi.
          </span>
        </p>
        <p>
          <span>
            Ces fournisseurs tiers collectent, stockent, utilisent, traitent et transfèrent des
            informations sur votre activité sur notre service conformément à leurs politiques de
            confidentialité et pour nous permettre de&nbsp;:
          </span>
        </p>
        <p>
          <span>• Mesurer et analyser le trafic et l'activité de navigation sur Notre Service</span>
        </p>
        <p>
          <span>
            • Vous montrer des publicités pour nos produits et/ou services sur des sites Web ou des
            applications tiers
          </span>
        </p>
        <p>
          <span>• Mesurer et analyser les performances de Nos campagnes publicitaires</span>
        </p>
        <p>
          <span>
            Certains de ces fournisseurs tiers peuvent utiliser des technologies autres que les
            cookies qui peuvent ne pas être affectées par les paramètres du navigateur qui bloquent
            les cookies. Votre navigateur peut ne pas vous permettre de bloquer ces technologies.
            Vous pouvez utiliser les outils tiers suivants pour refuser la collecte et l'utilisation
            d'informations dans le but de vous proposer des publicités ciblées par centres
            d'intérêt&nbsp;:
          </span>
        </p>
        <p>
          <span>• La plate-forme d'opt-out du NAI&nbsp;: </span>
          <a href="http://www.networkadvertising.org/choices/">
            <span>http://www.networkadvertising.org/choices/</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>• La plate-forme d'opt-out de l'EDAA </span>
          <a href="http://www.youronlinechoices.com/">
            <span>http://www.youronlinechoices.com/</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>• La plateforme d'opt-out du DAA&nbsp;: </span>
          <a href="http://optout.aboutads.info/?c=2&lang=EN">
            <span>http://optout.aboutads.info/?c=2&amp;lang=EN</span>
          </a>
        </p>
        <p>
          <span>
            Vous pouvez désactiver toutes les publicités personnalisées en activant des
            fonctionnalités de confidentialité sur votre appareil mobile, telles que Limiter le
            suivi des publicités (iOS) et Désactiver la personnalisation des publicités (Android).
            Consultez le système d'aide de votre appareil mobile pour plus d'informations.
          </span>
        </p>
        <p>
          <span>
            Nous pouvons partager des informations, telles que des adresses e-mail hachées (si
            disponibles) ou d'autres identifiants en ligne collectés sur Notre Service avec ces
            fournisseurs tiers. Cela permet à nos fournisseurs tiers de reconnaître et de vous
            diffuser des publicités sur tous les appareils et navigateurs. Pour en savoir plus sur
            les technologies utilisées par ces fournisseurs tiers et leurs capacités
            multi-appareils, veuillez consulter la politique de confidentialité de chaque
            fournisseur répertorié ci-dessous.
          </span>
        </p>
        <p>
          <span>
            Les fournisseurs tiers que nous pouvons utiliser sont, mais sans s'y limiter&nbsp;:
          </span>
        </p>

        <p>
          <span>•&nbsp;Google&nbsp;Ads (AdWords)</span>
        </p>

        <p>
          <span>Le service de remarketing Google Ads (AdWords) est fourni par Google Inc.</span>
        </p>
        <p>
          <span>
            Vous pouvez désactiver Google Analytics pour la publicité display et personnaliser les
            annonces du Réseau Display de Google en visitant la page des paramètres de Google Ads :{' '}
          </span>
          <a href="http://www.google.com/settings/ads">
            <span>http://www.google.com/settings/ads</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>
            Google recommande également d'installer le module complémentaire de navigateur pour la
            désactivation de Google Analytics -{' '}
          </span>
          <a href="https://tools.google.com/dlpage/gaoptout">
            <span>https://tools.google.com/dlpage/gaoptout</span>
          </a>
          <span> </span>
          <span>
            &nbsp;- pour votre navigateur Web. Le module complémentaire de navigateur pour la
            désactivation de Google Analytics offre aux visiteurs la possibilité d'empêcher la
            collecte et l'utilisation de leurs données par Google Analytics.
          </span>
        </p>
        <p>
          <span>
            Pour plus d'informations sur les pratiques de confidentialité de Google, veuillez
            visiter la page Web Google Privacy &amp; Terms:{' '}
          </span>
          <a href="https://policies.google.com/privacy">
            <span>https://policies.google.com/privacy</span>
          </a>
        </p>

        <p>
          <span>• Twitter</span>
        </p>

        <p>
          <span>Le service de remarketing Twitter est fourni par Twitter Inc.</span>
        </p>
        <p>
          <span>
            Vous pouvez désactiver les publicités basées sur les centres d'intérêt de Twitter en
            suivant leurs instructions :{' '}
          </span>
          <a href="https://support.twitter.com/articles/20170405">
            <span>https://support.twitter.com/articles/20170405</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>
            Vous pouvez en savoir plus sur les pratiques et politiques de confidentialité de Twitter
            en visitant leur page Politique de confidentialité :
          </span>
          <a href="https://twitter.com/privacy">
            <span>https://twitter.com/privacy</span>
          </a>
          <span> </span>
        </p>

        <p>
          <span>• Facebook/Méta</span>
        </p>

        <p>
          <span>
            Le service de remarketing Facebook ou Meta est fourni par Facebook Inc. et Meta Inc.
          </span>
        </p>
        <p>
          <span>
            Vous pouvez en savoir plus sur la publicité ciblée par centres d'intérêt de Facebook en
            visitant cette page :{' '}
          </span>
          <a href="https://www.facebook.com/help/516147308587266">
            <span>https://www.facebook.com/help/516147308587266</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>
            Pour vous désinscrire des publicités basées sur les centres d'intérêt de Facebook,
            suivez ces instructions de Facebook&nbsp;:{' '}
          </span>
          <a href="https://www.facebook.com/help/568137493302217">
            <span>https://www.facebook.com/help/568137493302217</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>
            Facebook adhère aux principes d'autorégulation pour la publicité comportementale en
            ligne établis par la Digital Advertising Alliance. Vous pouvez également vous
            désinscrire de Facebook et d'autres entreprises participantes via la Digital Advertising
            Alliance aux États-Unis{' '}
          </span>
          <a href="http://www.aboutads.info/choices/">
            <span>http://www.aboutads.info/choices/</span>
          </a>
          <span> </span>
          <span>
            , la Digital Advertising Alliance of Canada au Canada http://youradchoices.ca/ ou
            l'Alliance européenne de la publicité numérique interactive en Europe{' '}
          </span>
          <a href="http://www.youronlinechoices.eu/">
            <span>http://www.youronlinechoices.eu/</span>
          </a>
          <span> </span>
          <span>, ou opt-out en utilisant les paramètres de votre appareil mobile.</span>
        </p>
        <p>
          <span>
            Pour plus d'informations sur les pratiques de confidentialité de Facebook, veuillez
            consulter la politique de données de Facebook&nbsp;:{' '}
          </span>
          <a href="https://www.facebook.com/privacy/explanation">
            <span>https://www.facebook.com/privacy/explanation</span>
          </a>
          <span> </span>
        </p>

        <p>
          <span>• Pinterest</span>
        </p>

        <p>
          <span>Le service de remarketing de Pinterest est fourni par Pinterest Inc.</span>
        </p>
        <p>
          <span>
            Vous pouvez désactiver les publicités basées sur les centres d'intérêt de Pinterest en
            activant la fonctionnalité "Ne pas suivre" de votre navigateur Web ou en suivant les
            instructions de Pinterest&nbsp;:{' '}
          </span>
          <a href="http://help.pinterest.com/en/articles/personalization-and-data">
            <span>http://help.pinterest.com/en/articles/personalization-and-data</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>
            Vous pouvez en savoir plus sur les pratiques et politiques de confidentialité de
            Pinterest en visitant leur page Politique de confidentialité :{' '}
          </span>
          <a href="https://about.pinterest.com/en/privacy-policy">
            <span>https://about.pinterest.com/en/privacy-policy</span>
          </a>
        </p>

        <p>
          <span>4.5 - Utilisation, performances et divers</span>
        </p>

        <p>
          <span>
            Nous pouvons utiliser des fournisseurs de services tiers pour fournir une meilleure
            amélioration de notre service. Ils peuvent inclure, mais ne sont pas limités à&nbsp;:
          </span>
        </p>
        <p>
          <b>
            <span>
              Intercom
              <br />
            </span>
          </b>
          <span>Their Privacy Policy can be viewed at </span>
          <a href="https://www.intercom.com/legal/privacy">
            <span>https://www.intercom.com/legal/privacy</span>
          </a>
        </p>
        <p>
          <b>
            <span>
              Facebook Messenger
              <br />
            </span>
          </b>
          <span>Their Privacy Policy can be viewed at </span>
          <a href="https://www.facebook.com/privacy/policy">
            <span>https://www.facebook.com/privacy/policy</span>
          </a>
        </p>
        <p>
          <b>
            <span>
              Zendesk
              <br />
            </span>
          </b>
          <span>Their Privacy Policy can be viewed at </span>
          <a href="https://www.zendesk.com/company/agreements-and-terms/privacy-notice/">
            <span>https://www.zendesk.com/company/agreements-and-terms/privacy-notice/</span>
          </a>
        </p>
        <p>
          <b>
            <span>
              Tawk
              <br />
            </span>
          </b>
          <span>Their Privacy Policy can be viewed at </span>
          <a href="https://www.tawk.to/privacy-policy/">
            <span>https://www.tawk.to/privacy-policy/</span>
          </a>
        </p>

        <p>
          <span>5 - Liens vers d'autres sites Web</span>
        </p>

        <p>
          <span>
            Notre service peut contenir des liens vers d'autres sites Web qui ne sont pas exploités
            par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers.
            Nous vous conseillons vivement de consulter la politique de confidentialité de chaque
            site que vous visitez.
          </span>
        </p>
        <p>
          <span>
            Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux
            politiques de confidentialité ou aux pratiques des sites ou services tiers.
          </span>
        </p>

        <p>
          <span>6 - Confidentialité RGPD</span>
        </p>

        <p>
          <span>
            6.1 - Base juridique du traitement des données personnelles dans le cadre du RGPD
          </span>
        </p>

        <p>
          <span>Nous pouvons traiter les Données Personnelles dans les conditions suivantes :</span>
        </p>

        <p>
          <span>
            • Consentement : Vous avez donné Votre consentement au traitement des Données
            Personnelles pour une ou plusieurs finalités spécifiques.
          </span>
        </p>
        <p>
          <span>
            • Exécution d'un contrat : La fourniture de Données Personnelles est nécessaire à
            l'exécution d'un accord avec Vous et/ou pour toute obligation précontractuelle de
            celui-ci.
          </span>
        </p>
        <p>
          <span>
            • Obligations légales : Le Traitement des Données Personnelles est nécessaire au respect
            d'une obligation légale à laquelle la Société ou l'Exploitant est soumis.
          </span>
        </p>
        <p>
          <span>
            • Intérêts vitaux : le traitement des données personnelles est nécessaire afin de
            protéger vos intérêts vitaux ou ceux d'une autre personne physique.
          </span>
        </p>
        <p>
          <span>
            • Intérêt public : le traitement des données personnelles est lié à une tâche effectuée
            dans l'intérêt public ou dans l'exercice de l'autorité publique dont est investie la
            société ou l'opérateur.
          </span>
        </p>
        <p>
          <span>
            • Intérêts légitimes : Le traitement des Données Personnelles est nécessaire aux fins
            des intérêts légitimes poursuivis par la Société ou l'Exploitant.
          </span>
        </p>
        <p>
          <span>
            Dans tous les cas, la Société ou l'Opérateur se fera un plaisir de vous aider à
            clarifier la base juridique spécifique qui s'applique au traitement, et en particulier
            si la fourniture de Données personnelles est une exigence légale ou contractuelle, ou
            une exigence nécessaire pour conclure un contrat.
          </span>
        </p>

        <p>
          <span>6.2 - Vos droits en vertu du RGPD</span>
        </p>

        <p>
          <span>
            La Société ou l'Exploitant s'engage à respecter la confidentialité de Vos Données
            Personnelles et à Vous garantir l'exercice de Vos droits.
          </span>
        </p>

        <p>
          <span>
            Vous avez le droit, en vertu de la présente politique de confidentialité, et
            conformément à la loi si vous êtes dans l'UE, de&nbsp;:
          </span>
        </p>

        <p>
          <span>
            • Demander l'accès à Vos Données Personnelles. Le droit d'accéder, de mettre à jour ou
            de supprimer les informations que Nous avons sur Vous. Chaque fois que cela est
            possible, vous pouvez accéder, mettre à jour ou demander la suppression de vos données
            personnelles directement dans la section des paramètres de votre compte. Si vous n'êtes
            pas en mesure d'effectuer ces actions vous-même, veuillez nous contacter pour vous
            aider. Cela vous permet également de recevoir une copie des données personnelles que
            nous détenons à votre sujet.
          </span>
        </p>
        <p>
          <span>
            • Demander la correction des données personnelles que nous détenons à votre sujet. Vous
            avez le droit de faire corriger toute information incomplète ou inexacte que nous
            détenons à votre sujet.
          </span>
        </p>
        <p>
          <span>
            • S'opposer au traitement de Vos Données Personnelles. Ce droit existe lorsque nous nous
            appuyons sur un intérêt légitime comme base juridique pour notre traitement et qu'il
            existe quelque chose dans votre situation particulière qui vous donne envie de vous
            opposer à notre traitement de vos données personnelles pour ce motif. Vous avez
            également le droit de vous opposer lorsque nous traitons vos données personnelles à des
            fins de marketing direct.
          </span>
        </p>
        <p>
          <span>
            • Demander l'effacement de Vos Données Personnelles. Vous avez le droit de nous demander
            de supprimer ou de supprimer des données personnelles lorsqu'il n'y a aucune raison
            valable pour nous de continuer à les traiter.
          </span>
        </p>
        <p>
          <span>
            • Demander le transfert de Vos Données Personnelles. Nous vous fournirons, à vous ou à
            un tiers que vous avez choisi, vos données personnelles dans un format structuré,
            couramment utilisé et lisible par machine. Veuillez noter que ce droit ne s'applique
            qu'aux informations automatisées que vous nous avez initialement autorisées à utiliser
            ou lorsque nous avons utilisé les informations pour exécuter un contrat avec vous.
          </span>
        </p>
        <p>
          <span>
            • Retirer Votre consentement. Vous avez le droit de retirer votre consentement à
            l'utilisation de vos données personnelles. Si Vous retirez Votre consentement, Nous
            pourrions ne pas être en mesure de Vous donner accès à certaines fonctionnalités
            spécifiques du Service.
          </span>
        </p>

        <p>
          <span>6.3 - Exercice de vos droits de protection des données GDPR</span>
        </p>

        <p>
          <span>
            Vous pouvez exercer vos droits d'accès, de rectification, d'annulation et d'opposition
            en nous contactant. Veuillez noter que nous pouvons vous demander de vérifier votre
            identité avant de répondre à de telles demandes. Si vous faites une demande, nous ferons
            de notre mieux pour vous répondre dans les plus brefs délais.
          </span>
        </p>
        <p>
          <span>
            Vous avez le droit de porter plainte auprès d'une autorité de protection des données
            concernant notre collecte et notre utilisation de vos données personnelles. Pour plus
            d'informations, si vous êtes dans l'Espace économique européen (EEE), veuillez contacter
            votre autorité locale de protection des données dans l'EEE.
          </span>
        </p>

        <p>
          <span>7 - CCPA, CalOPPA, </span>
          <span lang="FR">et</span>
          <span> California Privacy Rights</span>
        </p>

        <p>
          <span>
            Cette section d'avis de confidentialité pour les résidents de Californie complète les
            informations contenues dans notre politique de confidentialité et s'applique uniquement
            à tous les visiteurs, utilisateurs et autres personnes résidant dans l'État de
            Californie.
          </span>
        </p>

        <p>
          <span>7.1 - Catégories de renseignements personnels recueillis</span>
        </p>

        <p>
          <span>
            Nous recueillons des informations qui identifient, se rapportent à, décrivent, font
            référence, sont susceptibles d'être associées à, ou pourraient raisonnablement être
            liées, directement ou indirectement, à un Consommateur ou un Appareil particulier. Ce
            qui suit est une liste des catégories d'informations personnelles que nous pouvons
            collecter ou qui ont pu être collectées auprès des résidents de Californie au cours des
            douze (12) derniers mois.
          </span>
        </p>
        <p>
          <span>
            Veuillez noter que les catégories et exemples fournis dans la liste ci-dessous sont ceux
            définis dans le CCPA. Cela ne signifie pas que nous avons en fait collecté tous les
            exemples de cette catégorie d'informations personnelles, mais reflète notre conviction
            de bonne foi au meilleur de notre connaissance que certaines de ces informations de la
            catégorie applicable peuvent être et peuvent avoir été collectées. Par exemple,
            certaines catégories d'informations personnelles ne seraient collectées que si vous nous
            fournissez ces informations personnelles directement.
          </span>
        </p>

        <li>
          <span>Catégorie A : Identifiants.</span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: un nom réel, un pseudonyme, une adresse postale, un identifiant
            personnel unique, un identifiant en ligne, une adresse de protocole Internet, une
            adresse e-mail, un nom de compte, un numéro de permis de conduire, un numéro de
            passeport ou d'autres identifiants similaires.
          </span>
        </li>
        <li>
          <span>Collecté : Oui.</span>
        </li>

        <li>
          <span>
            Catégorie B : catégories d'informations personnelles répertoriées dans la loi
            californienne sur les dossiers des clients (Cal. Civ. Code § 1798.80(e)).
          </span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: un nom, une signature, un numéro de sécurité sociale, des
            caractéristiques physiques ou une description, une adresse, un numéro de téléphone, un
            numéro de passeport, un numéro de permis de conduire ou de carte d'identité nationale,
            un numéro de police d'assurance, une formation, un emploi, des antécédents
            professionnels, un numéro de compte bancaire, un numéro de carte de crédit , numéro de
            carte de débit ou toute autre information financière, médicale ou d'assurance maladie.
            Certaines informations personnelles incluses dans cette catégorie peuvent chevaucher
            d'autres catégories.
          </span>
        </li>
        <li>
          <span>Collecté : Oui.</span>
        </li>

        <li>
          <span>
            Catégorie C : caractéristiques de classification protégées en vertu de la loi
            californienne ou fédérale.
          </span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: âge (40&nbsp;ans ou plus), race, couleur, ascendance, origine nationale,
            citoyenneté, religion ou croyance, état civil, état de santé, handicap physique ou
            mental, sexe (y compris le sexe, l'identité de genre, l'expression de genre, la
            grossesse ou l'accouchement et conditions médicales connexes), l'orientation sexuelle,
            le statut d'ancien combattant ou de militaire, la génétique information (including
            familial genetic information).
            <br />
            Collected: No.
          </span>
        </li>

        <li>
          <span>Catégorie D : Informations commerciales.</span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: enregistrements et historique des produits ou services achetés ou
            envisagés.
          </span>
        </li>
        <li>
          <span>Collecté : Oui.</span>
        </li>

        <li>
          <span>Catégorie E : Informations biométriques.</span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: caractéristiques génétiques, physiologiques, comportementales et
            biologiques, ou modèles d'activité utilisés pour extraire un modèle ou un autre
            identifiant ou des informations d'identification, telles que les empreintes digitales,
            les empreintes faciales et les empreintes vocales, les scans de l'iris ou de la rétine,
            la frappe au clavier, la démarche ou d'autres modèles physiques , et des données sur le
            sommeil, la santé ou l'exercice.
          </span>
        </li>
        <li>
          <span>Collecté : Non.</span>
        </li>

        <li>
          <span>Catégorie F : Internet ou autre activité de réseau similaire.</span>
        </li>
        <li>
          <span>Exemples : Interaction avec notre Service ou publicité.</span>
        </li>
        <li>
          <span>Collecté : Oui.</span>
        </li>

        <li>
          <span>Catégorie G : Données de géolocalisation.</span>
        </li>
        <li>
          <span>Exemples&nbsp;: Emplacement physique approximatif.</span>
        </li>
        <li>
          <span>Collecté : Non.</span>
        </li>

        <li>
          <span>Catégorie H : Données sensorielles.</span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: informations sonores, électroniques, visuelles, thermiques, olfactives
            ou similaires.
          </span>
        </li>
        <li>
          <span>Collecté : Non.</span>
        </li>

        <li>
          <span>Catégorie I : Informations professionnelles ou liées à l'emploi.</span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: antécédents professionnels actuels ou passés ou évaluations de
            performances.
          </span>
        </li>
        <li>
          <span>Collecté : Non.</span>
        </li>

        <li>
          <span>
            Catégorie&nbsp;J&nbsp;: informations éducatives non publiques (conformément à la loi sur
            les droits à l'éducation et la confidentialité de la famille (20 U.S.C. Section 1232g,
            34 C.F.R. Part 99)).
          </span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: Dossiers scolaires directement liés à un étudiant conservés par un
            établissement d'enseignement ou une partie agissant en son nom, tels que les notes, les
            relevés de notes, les listes de classe, les horaires des étudiants, les codes
            d'identification des étudiants, les informations financières des étudiants ou les
            dossiers disciplinaires des étudiants.
          </span>
        </li>
        <li>
          <span>Collecté : Non.</span>
        </li>

        <li>
          <span>• Catégorie K : Inférences tirées d'autres informations personnelles.</span>
        </li>
        <li>
          <span>
            Exemples&nbsp;: Profil reflétant les préférences, les caractéristiques, les tendances
            psychologiques, les prédispositions, le comportement, les attitudes, l'intelligence, les
            capacités et les aptitudes d'une personne.
          </span>
        </li>
        <li>
          <span>Collecté : Non.</span>
        </li>

        <p>
          <span>En vertu du CCPA, les informations personnelles n'incluent pas&nbsp;:</span>
        </p>

        <p>
          <span>
            •&nbsp;Informations accessibles au public à partir des archives gouvernementales
          </span>
        </p>
        <p>
          <span>•&nbsp;Informations anonymisées ou agrégées sur les consommateurs</span>
        </p>
        <p>
          <span>• Les informations exclues du champ d'application du CCPA, telles que :</span>
        </p>

        <p>
          <span>
            o Informations médicales ou de santé couvertes par la Health Insurance Portability and
            Accountability Act de 1996 (HIPAA) et la California Confidentiality of Medical
            Information Act (CMIA) ou données d'essais cliniques
          </span>
        </p>
        <p>
          <span>
            o Informations personnelles couvertes par certaines lois sectorielles sur la
            confidentialité, notamment la Fair Credit Reporting Act (FRCA), la Gramm-Leach-Bliley
            Act (GLBA) ou la California Financial Information Privacy Act (FIPA) et la Driver's
            Privacy Protection Act de 1994
          </span>
        </p>

        <p>
          <span>7.2 - Sources des informations personnelles</span>
        </p>

        <p>
          <span>
            Nous obtenons les catégories d'informations personnelles énumérées ci-dessus à partir
            des catégories de sources suivantes&nbsp;:
          </span>
        </p>

        <p>
          <span>
            • Directement de vous. Par exemple, à partir des formulaires que vous remplissez sur
            notre service, des préférences que vous exprimez ou fournissez via notre service, ou de
            vos achats sur notre service.
          </span>
        </p>
        <p>
          <span>
            • Indirectement de vous. Par exemple, en observant Votre activité sur notre Service.
          </span>
        </p>
        <p>
          <span>
            • Automatiquement de vous. Par exemple, par le biais de cookies que Nous ou nos
            Fournisseurs de services installons sur Votre Appareil lorsque Vous naviguez sur notre
            Service.
          </span>
        </p>
        <p>
          <span>
            • Des fournisseurs de services. Par exemple, des fournisseurs tiers pour surveiller et
            analyser l'utilisation de notre service, des fournisseurs tiers pour vous proposer des
            publicités ciblées, des fournisseurs tiers pour le traitement des paiements ou d'autres
            fournisseurs tiers que nous utilisons pour{' '}
          </span>
          <span lang="FR">te </span>
          <span>fournir le service.</span>
        </p>

        <p>
          <span>
            7.3 - Utilisation des renseignements personnels à des fins professionnelles ou à des
            fins commerciales
          </span>
        </p>

        <p>
          <span>
            Nous pouvons utiliser ou divulguer les informations personnelles que nous recueillons à
            des «&nbsp;fins commerciales&nbsp;» ou à des «&nbsp;fins commerciales&nbsp;» (telles que
            définies par le CCPA), qui peuvent inclure les exemples suivants&nbsp;:
          </span>
        </p>

        <p>
          <span>• Pour exploiter notre service et vous fournir notre service.</span>
        </p>
        <p>
          <span>
            • Pour vous fournir une assistance et répondre à vos demandes, y compris pour enquêter
            et répondre à vos préoccupations et surveiller et améliorer notre service.
          </span>
        </p>
        <p>
          <span>
            • Pour remplir ou répondre à la raison pour laquelle vous avez fourni les informations.
            Par exemple, si vous partagez vos coordonnées pour poser une question sur notre service,
            nous utiliserons ces informations personnelles pour répondre à votre demande. Si vous
            fournissez vos informations personnelles pour acheter un produit ou un service, nous
            utiliserons ces informations pour traiter votre paiement et faciliter la livraison.
          </span>
        </p>
        <p>
          <span>
            • Pour répondre aux demandes d'application de la loi et tel que requis par la loi
            applicable, l'ordonnance du tribunal ou les réglementations gouvernementales.
          </span>
        </p>
        <p>
          <span>
            • Tel que décrit lors de la collecte de vos informations personnelles ou autrement
            stipulé dans le CCPA.
          </span>
        </p>
        <p>
          <span>• À des fins d'administration et d'audit internes.</span>
        </p>
        <p>
          <span>
            • Pour détecter les incidents de sécurité et se protéger contre les activités
            malveillantes, trompeuses, frauduleuses ou illégales, y compris, si nécessaire,
            poursuivre les responsables de ces activités.
          </span>
        </p>
        <p>
          <span>
            Veuillez noter que les exemples fournis ci-dessus sont illustratifs et ne se veulent pas
            exhaustifs. Pour plus de détails sur la façon dont nous utilisons ces informations,
            veuillez vous référer à la section "Utilisation de vos données personnelles".
          </span>
        </p>
        <p>
          <span>
            Si nous décidons de collecter des catégories supplémentaires d'informations personnelles
            ou d'utiliser les informations personnelles que nous avons collectées à des fins
            matériellement différentes, sans rapport ou incompatibles, nous mettrons à jour cette
            politique de confidentialité.
          </span>
        </p>

        <p>
          <span>
            7.4 - Divulgation de renseignements personnels à des fins commerciales ou à des fins
            commerciales
          </span>
        </p>

        <p>
          <span>
            Nous pouvons utiliser ou divulguer et pouvons avoir utilisé ou divulgué au cours des
            douze (12) derniers mois les catégories d'informations personnelles suivantes à des fins
            professionnelles ou commerciales&nbsp;:
          </span>
        </p>

        <p>
          <span>• Catégorie&nbsp;A&nbsp;: Identifiants</span>
        </p>
        <p>
          <span>
            • Catégorie&nbsp;B&nbsp;: catégories d'informations personnelles répertoriées dans la
            loi californienne sur les dossiers des clients (Cal. Civ. Code §&nbsp;1798.80(e))
          </span>
        </p>
        <p>
          <span>• Catégorie&nbsp;D&nbsp;: Informations commerciales</span>
        </p>
        <p>
          <span>
            •&nbsp;Catégorie&nbsp;F&nbsp;: Internet ou toute autre activité de réseau similaire
          </span>
        </p>

        <p>
          <span>
            Veuillez noter que les catégories énumérées ci-dessus sont celles définies dans le CCPA.
            Cela ne signifie pas que tous les exemples de cette catégorie d'informations
            personnelles ont en fait été divulgués, mais reflète notre conviction de bonne foi au
            meilleur de notre connaissance que certaines de ces informations de la catégorie
            applicable peuvent être et peuvent avoir été divulguées.
          </span>
        </p>
        <p>
          <span>
            Lorsque nous divulguons des informations personnelles à des fins professionnelles ou
            commerciales, nous concluons un contrat qui décrit l'objectif et oblige le destinataire
            à la fois à garder ces informations personnelles confidentielles et à ne pas les
            utiliser à d'autres fins que l'exécution du contrat.
          </span>
        </p>

        <p>
          <span>7.5 - Vente d'informations personnelles</span>
        </p>

        <p>
          <span>
            Selon la définition de la CCPA, «&nbsp;vendre&nbsp;» et «&nbsp;vente&nbsp;» désignent la
            vente, la location, la publication, la divulgation, la diffusion, la mise à disposition,
            le transfert ou la communication orale, par écrit, électronique ou autre, des
            informations personnelles d'un consommateur par le entreprise à un tiers moyennant une
            contrepartie valable. Cela signifie que nous avons peut-être reçu une sorte d'avantage
            en échange du partage d'informations personnelles, mais pas nécessairement un avantage
            monétaire.
          </span>
        </p>

        <p>
          <span>
            Veuillez noter que les catégories énumérées ci-dessous sont celles définies dans le
            CCPA. Cela ne signifie pas que tous les exemples de cette catégorie d'informations
            personnelles ont en fait été vendus, mais reflète notre conviction de bonne foi au
            meilleur de notre connaissance que certaines de ces informations de la catégorie
            applicable peuvent être et peuvent avoir été partagées pour une valeur en retour .
          </span>
        </p>

        <p>
          <span>
            Nous pouvons vendre et avoir vendu au cours des douze (12) derniers mois les catégories
            d'informations personnelles suivantes&nbsp;:
          </span>
        </p>

        <p>
          <span>• Catégorie&nbsp;A&nbsp;: Identifiants</span>
        </p>
        <p>
          <span>
            • Catégorie&nbsp;B&nbsp;: catégories d'informations personnelles répertoriées dans la
            loi californienne sur les dossiers des clients (Cal. Civ. Code §&nbsp;1798.80(e))
          </span>
        </p>
        <p>
          <span>• Catégorie&nbsp;D&nbsp;: Informations commerciales</span>
        </p>
        <p>
          <span>
            •&nbsp;Catégorie&nbsp;F&nbsp;: Internet ou toute autre activité de réseau similaire
          </span>
        </p>

        <p>
          <span>7.6 - Partage des informations personnelles</span>
        </p>

        <p>
          <span>
            Nous pouvons partager vos informations personnelles identifiées dans les catégories
            ci-dessus avec les catégories de tiers suivantes&nbsp;:
          </span>
        </p>

        <p>
          <span>•</span>
          <span> </span>
          <span>Les fournisseurs de services</span>
        </p>
        <p>
          <span>• Processeurs de paiement</span>
        </p>
        <p>
          <span>• Nos affiliés</span>
        </p>
        <p>
          <span>• Nos partenaires commerciaux</span>
        </p>
        <p>
          <span>
            • Les fournisseurs tiers auxquels vous ou vos agents nous autorisez à divulguer vos
            informations personnelles en rapport avec les produits ou services que nous vous
            fournissons
          </span>
        </p>

        <p>
          <span>7.7 - Vente d'informations personnelles de mineurs de moins de 16 ans</span>
        </p>

        <p>
          <span>
            Nous ne collectons pas sciemment des informations personnelles de mineurs de moins de 16
            ans via notre Service, bien que certains sites Web tiers auxquels nous sommes liés
            puissent le faire. Ces sites Web tiers ont leurs propres conditions d'utilisation et
            politiques de confidentialité et nous encourageons les parents et les tuteurs légaux à
            surveiller l'utilisation d'Internet par leurs enfants et à demander à leurs enfants de
            ne jamais fournir d'informations sur d'autres sites Web sans leur permission.
          </span>
        </p>

        <p>
          <span>
            Nous ne vendons pas les informations personnelles des Consommateurs dont Nous savons
            qu'ils ont moins de 16 ans, sauf si Nous recevons l'autorisation expresse (le "droit
            d'opt-in") soit du Consommateur âgé de 13 à 16 ans, soit le parent ou le tuteur d'un
            Consommateur de moins de 13 ans. Les consommateurs qui acceptent la vente d'informations
            personnelles peuvent se retirer des ventes futures à tout moment. Pour exercer le droit
            de refus, vous (ou votre représentant autorisé) pouvez nous soumettre une demande en
            nous contactant.
          </span>
        </p>
        <p>
          <span>
            Si vous avez des raisons de croire qu'un enfant de moins de 13 (ou 16) nous a fourni des
            informations personnelles, veuillez nous contacter avec suffisamment de détails pour
            nous permettre de supprimer ces informations.
          </span>
        </p>

        <p>
          <span>7.8 - Vos droits en vertu du CCPA</span>
        </p>

        <p>
          <span>
            Le CCPA accorde aux résidents de Californie des droits spécifiques concernant leurs
            informations personnelles. Si vous résidez en Californie, vous disposez des droits
            suivants&nbsp;:
          </span>
        </p>

        <li>
          <b>
            <span>Le droit d'avis.</span>
          </b>
          <span>
            {' '}
            Vous avez le droit d'être informé des catégories de données personnelles qui sont
            collectées et des finalités pour lesquelles les données personnelles sont utilisées.
          </span>
        </li>
        <li>
          <b>
            <span>Le droit de demander.</span>
          </b>
          <span>
            En vertu du CCPA, vous avez le droit de demander que nous vous divulguions des
            informations sur notre collecte, utilisation, vente, divulgation à des fins commerciales
            et partage d'informations personnelles. Une fois que nous aurons reçu et confirmé votre
            demande, nous vous divulguerons&nbsp;:
          </span>
        </li>

        <p>
          <span>
            o Les catégories d'informations personnelles que nous avons collectées à votre sujet
          </span>
        </p>
        <p>
          <span>
            o Les catégories de sources des informations personnelles que Nous avonscollectées à
            votre sujet
          </span>
        </p>
        <p>
          <span>
            o Notre objectif commercial ou commercial pour la collecte ou la vente de ces
            informations personnelles
          </span>
        </p>
        <p>
          <span>
            o Les catégories de tiers avec lesquels nous partageons ces informations personnelles
          </span>
        </p>
        <p>
          <span>
            o Les informations personnelles spécifiques que nous avons collectées à votre sujet
          </span>
        </p>
        <p>
          <span>
            o Si nous avons vendu vos informations personnelles ou divulgué vos informations
            personnelles à des fins commerciales, nous vous divulguerons&nbsp;:
          </span>
        </p>
        <p>
          <span> Les catégories d'informations personnelles vendues</span>
        </p>
        <p>
          <span> Les catégories de catégories d'informations personnelles divulguées</span>
        </p>

        <li>
          <b>
            <span>Le droit de refuser</span>
          </b>
          <span>
            la vente de Données Personnelles (opt-out). Vous avez le droit de nous demander de ne
            pas vendre vos informations personnelles. Pour soumettre une demande de désinscription,
            veuillez nous contacter.
          </span>
        </li>
        <li>
          <b>
            <span>Le droit de supprimer</span>
          </b>
          <span>
            les Données Personnelles. Vous avez le droit de demander la suppression de Vos Données
            Personnelles, sous réserve de certaines exceptions. Une fois que nous aurons reçu et
            confirmé votre demande, nous supprimerons (et demanderons à nos fournisseurs de services
            de supprimer) vos informations personnelles de nos dossiers, sauf exception. Nous
            pouvons refuser votre demande de suppression si la conservation des informations est
            nécessaire pour nous ou nos fournisseurs de services pour&nbsp;:
          </span>
        </li>

        <p>
          <span>
            o Terminer la transaction pour laquelle nous avons collecté les informations
            personnelles, fournir un bien ou un service que vous avez demandé, prendre des mesures
            raisonnablement anticipées dans le cadre de notre relation commerciale continue avec
            vous, ou exécuter autrement notre contrat avec vous.
          </span>
        </p>
        <p>
          <span>
            o Détecter les incidents de sécurité, protéger contre les activités malveillantes,
            trompeuses, frauduleuses ou illégales, ou poursuivre les responsables de ces activités.
          </span>
        </p>
        <p>
          <span>
            o Déboguer les produits pour identifier et réparer les erreurs qui nuisent aux
            fonctionnalités prévues existantes.
          </span>
        </p>
        <p>
          <span>
            o Exercer la liberté d'expression, garantir le droit d'un autre consommateur d'exercer
            son droit à la liberté d'expression ou exercer un autre droit prévu par la loi.
          </span>
        </p>
        <p>
          <span>
            o Respecter la loi californienne sur la confidentialité des communications électroniques
            (Cal. Code pénal § 1546 et suivants).
          </span>
        </p>
        <p>
          <span>
            o S'engager dans des recherches scientifiques, historiques ou statistiques publiques ou
            évaluées par des pairs dans l'intérêt public qui adhèrent à toutes les autres lois
            applicables en matière d'éthique et de confidentialité, lorsque la suppression des
            informations peut probablement rendre impossible ou sérieusement compromettre la
            réalisation de la recherche, si vous avez précédemment fourni des informations
            consentement.
          </span>
        </p>
        <p>
          <span>
            o Autoriser uniquement les utilisations internes raisonnablement alignées sur les
            attentes des consommateurs en fonction de votre relation avec nous.
          </span>
        </p>
        <p>
          <span>o Se conformer à une obligation légale.</span>
        </p>
        <p>
          <span>
            o Faire d'autres usages internes et licites de ces informations qui sont compatibles
            avec le contexte dans lequel vous les avez fournies.
          </span>
        </p>
        <p>
          <b>
            <span>• Le droit de ne pas être discriminé</span>
          </b>
          <span>
            . Vous avez le droit de ne pas faire l'objet de discrimination pour avoir exercé l'un de
            vos droits de consommateur, notamment en&nbsp;:
          </span>
        </p>
        <p>
          <span>o Vous refuser des biens ou des services</span>
        </p>
        <p>
          <span>
            o Facturation de prix ou de tarifs différents pour des biens ou des services, y compris
            l'utilisation de remises ou d'autres avantages ou l'imposition de pénalités
          </span>
        </p>
        <p>
          <span>o Vous fournir un niveau ou une qualité différente de biens ou de services</span>
        </p>
        <p>
          <span>
            o Suggérer que vous recevrez un prix ou un tarif différent pour des biens ou des
            services ou un niveau ou une qualité différente de biens ou de services
          </span>
        </p>

        <p>
          <span>7.9 - Exercice de vos droits de protection des données CCPA</span>
        </p>

        <p>
          <span>
            Afin d'exercer l'un de vos droits en vertu du CCPA, et si vous êtes un résident de
            Californie, vous pouvez nous contacter.
          </span>
        </p>

        <p>
          <span>
            Vous seul, ou une personne enregistrée auprès du Secrétariat d'État de Californie que
            vous autorisez à agir en votre nom, pouvez faire une demande vérifiable liée à vos
            informations personnelles.
          </span>
        </p>

        <p>
          <span>Votre demande doit&nbsp;:</span>
        </p>

        <p>
          <span>
            • Fournir des informations suffisantes qui nous permettent de vérifier raisonnablement
            que vous êtes la personne à propos de laquelle nous avons collecté des informations
            personnelles ou un représentant autorisé
          </span>
        </p>
        <p>
          <span>
            • Décrivez votre demande avec suffisamment de détails pour nous permettre de la
            comprendre, de l'évaluer et d'y répondre correctement
          </span>
        </p>
        <p>
          <span>
            Nous ne pouvons pas répondre à Votre demande ou Vous fournir les informations requises
            si Nous ne pouvons pas&nbsp;:
          </span>
        </p>
        <p>
          <span>• Vérifiez votre identité ou votre autorité pour faire la demande</span>
        </p>
        <p>
          <span>• Et confirmez que les informations personnelles vous concernent</span>
        </p>
        <p>
          <span>
            Nous divulguerons et fournirons gratuitement les informations requises dans les 45 jours
            suivant la réception de votre demande vérifiable. Le délai pour fournir les informations
            requises peut être prolongé une fois de 45 jours supplémentaires lorsque cela est
            raisonnablement nécessaire et avec un préavis.
          </span>
        </p>
        <p>
          <span>
            Toute divulgation que nous fournirons ne couvrira que la période de 12 mois précédant la
            réception de la demande vérifiable.
          </span>
        </p>
        <p>
          <span>
            Pour les demandes de portabilité des données, nous sélectionnerons un format pour
            fournir vos informations personnelles qui est facilement utilisable et devrait vous
            permettre de transmettre les informations d'une entité à une autre sans entrave.
          </span>
        </p>

        <p>
          <span>7.10 - Ne vendez pas mes informations personnelles</span>
        </p>

        <p>
          <span>
            Vous avez le droit de vous opposer à la vente de vos informations personnelles. Une fois
            que nous aurons reçu et confirmé une demande de consommateur vérifiable de votre part,
            nous cesserons de vendre vos informations personnelles. Pour exercer votre droit de
            refus, veuillez nous contacter.
          </span>
        </p>
        <p>
          <span>
            Les fournisseurs de services avec lesquels nous collaborons (par exemple, nos
            partenaires d'analyse ou de publicité) peuvent utiliser une technologie sur le service
            qui vend des informations personnelles telles que définies par la loi CCPA. Si vous
            souhaitez refuser l'utilisation de vos informations personnelles à des fins de publicité
            ciblée par centres d'intérêt et ces ventes potentielles telles que définies par la loi
            CCPA, vous pouvez le faire en suivant les instructions ci-dessous.
          </span>
        </p>
        <p>
          <span>
            Veuillez noter que toute désactivation est spécifique au navigateur que vous utilisez.
            Vous devrez peut-être vous désinscrire sur chaque navigateur que vous utilisez.
          </span>
        </p>

        <p>
          <span>7.10.1 - Site Internet</span>
        </p>

        <p>
          <span>
            Vous pouvez refuser de recevoir des publicités personnalisées telles que diffusées par
            nos fournisseurs de services en suivant nos instructions présentées sur le
            service&nbsp;:
          </span>
        </p>

        <p>
          <span>• La plate-forme d'opt-out du NAI&nbsp;: </span>
          <a href="http://www.networkadvertising.org/choices/">
            <span>http://www.networkadvertising.org/choices/</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>• La plate-forme d'opt-out de l'EDAA </span>
          <a href="http://www.youronlinechoices.com/">
            <span>http://www.youronlinechoices.com/</span>
          </a>
          <span> </span>
        </p>
        <p>
          <span>• La plateforme d'opt-out du DAA&nbsp;: </span>
          <a href="http://optout.aboutads.info/?c=2&lang=EN">
            <span>http://optout.aboutads.info/?c=2&amp;lang=EN</span>
          </a>
          <span> </span>
        </p>

        <p>
          <span>
            L'opt-out placera un cookie sur votre ordinateur qui est unique au navigateur que vous
            utilisez pour vous désinscrire. Si vous changez de navigateur ou supprimez les cookies
            enregistrés par votre navigateur, vous devrez à nouveau vous désinscrire.
          </span>
        </p>

        <p>
          <span>7.10.2 - Appareils mobiles</span>
        </p>

        <p>
          <span>
            Votre appareil mobile peut vous donner la possibilité de refuser l'utilisation des
            informations sur les applications que vous utilisez afin de vous proposer des publicités
            ciblées sur vos centres d'intérêt&nbsp;:
          </span>
        </p>

        <p>
          <span>
            • "Désactiver les annonces ciblées par centres d'intérêt" ou "Désactiver la
            personnalisation des annonces" sur les appareils Android
          </span>
        </p>
        <p>
          <span>• "Limiter le suivi des publicités" sur les appareils iOS</span>
        </p>

        <p>
          <span>
            Vous pouvez également arrêter la collecte d'informations de localisation à partir de
            votre appareil mobile en modifiant les préférences de votre appareil mobile.
          </span>
        </p>

        <p>
          <span>
            7.11 - Politique "Ne pas suivre" comme l'exige la loi californienne sur la protection de
            la vie privée en ligne (CalOPPA)
          </span>
        </p>

        <p>
          <span>Notre service ne répond pas aux signaux Do Not Track.</span>
        </p>

        <p>
          <span>
            Cependant, certains sites Web tiers gardent une trace de vos activités de navigation. Si
            vous visitez de tels sites Web, vous pouvez définir vos préférences dans votre
            navigateur Web pour informer les sites Web que vous ne souhaitez pas être suivi. Vous
            pouvez activer ou désactiver DNT en visitant la page des préférences ou des paramètres
            de votre navigateur Web.
          </span>
        </p>

        <p>
          <span>
            7.12 - Vos droits à la vie privée en Californie (loi californienne Shine the Light)
          </span>
        </p>

        <p>
          <span>
            En vertu de l'article 1798 du Code civil de Californie (loi californienne Shine the
            Light), les résidents de Californie ayant une relation commerciale établie avec nous
            peuvent demander des informations une fois par an sur le partage de leurs données
            personnelles avec des tiers à des fins de marketing direct.
          </span>
        </p>

        <p>
          <span>
            Si vous souhaitez demander plus d'informations en vertu de la loi California Shine the
            Light, et si vous êtes un résident californien, vous pouvez nous contacter en utilisant
            les coordonnées fournies ci-dessous.
          </span>
        </p>

        <p>
          <span>8 - Droits à la vie privée des enfants et des utilisateurs mineurs</span>
        </p>

        <p>
          <span>8.1 - Droits généraux à la vie privée pour les enfants</span>
        </p>

        <p>
          <span>
            Notre service ne s'adresse pas aux personnes de moins de 13 ans. Nous ne collectons pas
            sciemment d'informations personnellement identifiables auprès de personnes de moins de
            13 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a
            fourni des données personnelles, veuillez Nous contacter. Si nous apprenons que nous
            avons collecté des données personnelles auprès d'une personne de moins de 13 ans sans
            vérification du consentement parental, nous prenons des mesures pour supprimer ces
            informations de nos serveurs.
          </span>
        </p>

        <p>
          <span>
            Si nous devons nous fier au consentement comme base légale pour le traitement de vos
            informations et que votre pays exige le consentement d'un parent, nous pouvons exiger le
            consentement de votre parent avant de collecter et d'utiliser ces informations.
          </span>
        </p>

        <p>
          <span>
            8.2 - Droits de confidentialité de la Californie pour les utilisateurs mineurs
            (California Business and Professions Code Section 22581)
          </span>
        </p>

        <p>
          <span>
            La section 22581 du California Business and Professions Code permet aux résidents
            californiens de moins de 18 ans qui sont des utilisateurs enregistrés de sites, services
            ou applications en ligne de demander et d'obtenir la suppression du contenu ou des
            informations qu'ils ont publiés publiquement.
          </span>
        </p>
        <p>
          <span>
            Pour demander la suppression de ces données, et si vous résidez en Californie, vous
            pouvez nous contacter en utilisant les coordonnées fournies ci-dessous et inclure
            l'adresse e-mail associée à votre compte.
          </span>
        </p>
        <p>
          <span>
            Sachez que votre demande ne garantit pas la suppression complète ou complète du contenu
            ou des informations publiés en ligne et que la loi peut ne pas autoriser ou exiger la
            suppression dans certaines circonstances.
          </span>
        </p>

        <p>
          <span>9 - Modifications de cette politique de confidentialité</span>
        </p>

        <p>
          <span>
            Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous
            vous informerons de tout changement en publiant la nouvelle politique de confidentialité
            sur cette page.
          </span>
        </p>
        <p>
          <span>
            Nous vous informerons par e-mail et/ou par un avis visible sur notre service, avant que
            le changement ne devienne effectif et mettrons à jour la date de «&nbsp;dernière mise à
            jour&nbsp;» en haut de cette politique de confidentialité.
          </span>
        </p>
        <p>
          <span>
            Il vous est conseillé de consulter périodiquement cette politique de confidentialité
            pour tout changement. Les modifications apportées à cette politique de confidentialité
            entrent en vigueur lorsqu'elles sont publiées sur cette page.
          </span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyFrench;
