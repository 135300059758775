import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes an order for a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// Provider can mark the product shipped/delivered
export const TRANSITION_MARK_DELIVERED = 'transition/mark-delivered';

// Customer can mark the product received (e.g. picked up from provider)
export const TRANSITION_MARK_RECEIVED_FROM_PURCHASED = 'transition/mark-received-from-purchased';

// Automatic cancellation happens if none marks the delivery happened
export const TRANSITION_AUTO_CANCEL = 'transition/auto-cancel';

// Operator can cancel the purchase before product has been marked as delivered / received
export const TRANSITION_CANCEL = 'transition/cancel';

// If provider has marked the product delivered (e.g. shipped),
// customer can then mark the product received
export const TRANSITION_MARK_RECEIVED = 'transition/mark-received';

// If customer doesn't mark the product received manually, it can happen automatically
export const TRANSITION_AUTO_MARK_RECEIVED = 'transition/auto-mark-received';

// When provider has marked the product delivered, customer can dispute the transaction
export const TRANSITION_DISPUTE = 'transition/dispute';

// If nothing is done to disputed transaction it ends up to Canceled state
export const TRANSITION_AUTO_CANCEL_FROM_DISPUTED = 'transition/auto-cancel-from-disputed';

// Operator can cancel disputed transaction manually
export const TRANSITION_CANCEL_FROM_DISPUTED = 'transition/cancel-from-disputed';

// Operator can mark the disputed transaction as received
export const TRANSITION_MARK_RECEIVED_FROM_DISPUTED = 'transition/mark-received-from-disputed';

// System moves transaction automatically from received state to complete state
// This makes it possible to to add notifications to that single transition.
export const TRANSITION_AUTO_COMPLETE = 'transition/auto-complete';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';
export const TRANSITION_CUSTOMER_CANCEL = 'transition/customer-cancel';
export const TRANSITION_PROVIDER_CANCEL = 'transition/provider-cancel';
export const TRANSITION_MARK_COMPLETE = 'transition/mark-complete';
export const TRANSITION_AUTO_MARK_COMPLETE = 'transition/auto-mark-complete';
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_AUTO_ACCEPT = 'transition/auto-cancel';
export const TRANSITION_NOT_SHIPPED = 'transition/not-shipped';
export const TRANSITION_ORDER_SHIPPED = 'transition/order-shipped';
export const TRANSITION_ORDER_DELIVERED = 'transition/order-delivered';
export const TRANSITION_REPORT_A_PROBLEM = 'transition/report-a-problem';
export const TRANSITION_REFUND_HALF_AMOUNT_DECIDE = 'transition/refund-half-amount-product';
export const TRANSITION_REFUND_FULL_AMOUNT_DECIDE = 'transition/refund-full-amount-product';
export const TRANSITION_RETURN_PRODUCT = 'transition/return-a-product';
export const TRANSITION_HALF_REFUND_ACCEPT_BUYER =
  'transition/refund-half-amount-product-buyer-accept';
export const TRANSITION_FULL_REFUND_ACCEPT_BUYER =
  'transition/refund-full-amount-product-buyer-accept';
export const TRANSITION_AUTO_COMPLETE_AFTER_RETURN = 'transition/auto-complete-after-return';
export const TRANSITION_PRINT_RETURN_LABEL = 'transition/print-return-label';
export const TRANSITION_RETURN_SHIPPED = 'transition/return-order-shipped';
export const TRANSITION_RETURN_DELIVERED = 'transition/return-order-delivered';
export const TRANSITION_CONFIRM_RETURN = 'transition/return-confirm';
export const TRANSITION_RETURN_NOT_CONFIRMED = 'transition/return-not-confirm';
export const TRANSITION_REFUND_HALF_TRANSFER = 'transition/refund-half-amount-transfer';
export const TRANSITION_REFUND_FULL_TRANSFER = 'transition/refund-full-amount-transfer';
export const TRANSITION_ORDER_CANCELLED_BY_OPERATOR = 'transition/order-cancelled-by-operator';
export const TRANSITION_REFUND_FULL_OPERATOR = 'transition/refund-full-amount-product-operator';
export const TRANSITION_EXPIRE_PARCEL = 'transition/expire-parcel';
export const TRANSITION_AFTER_COMPLETE_AFTER_REPORT_A_PROBLEM =
  'transition/complete-after-report-a-problem';

export const TRANSITION_REJECT = 'transition/reject';
export const TRANSITION_REJECT_FULL = 'transition/reject-full-amount';

export const TRANSITION_COMPLETE_AFTER_PAYOUT = 'transition/complete-after-payout';
export const TRANSITION_COMPLETE_AFTER_RETURN = 'transition/complete-after-return';
export const TRANSITION_COMPLETE_AFTER_RETURN_PAYOUT = 'transition/complete-after-return-payout';
export const TRANSITION_CREATE_BATCH = 'transition/enquire-for-lot';
export const TRANSITION_APPROVE_BATCH = 'transition/vendor-accept-lot';
export const TRANSITION_MODIFY_BATCH = 'transition/vendor-modify-lot';
export const TRANSITION_REJECT_BATCH = 'transition/vendor-reject-lot';
export const TRANSITION_CANCEL_BATCH = 'transition/cancel-enquiry-for-lot';
export const TRANSITION_CANCEL_AFTER_ACCEPT = 'transition/customer-cancel-after-accept';
export const TRANSITION_CANCEL_AFTER_MODIFY = 'transition/customer-cancel-after-modify';
/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'expire-payment';
const STATE_PURCHASED = 'purchased';
const STATE_DELIVERED = 'delivered';
const STATE_RECEIVED = 'received';
const STATE_DISPUTED = 'disputed';
const STATE_CANCELED = 'canceled';
const STATE_COMPLETED = 'completed';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_ACCEPTED = 'accepted';
const STATE_AUTO_CANCEL = 'auto-cancel';
const STATE_SHIPPED = 'shipped';
const STATE_ORDER_DELIVERED = 'delivered';
const STATE_REPORT_A_PROBLEM = 'report-problem';
const STATE_HALF_AMOUNT_DECIDE = 'refund-half-amount-decided';
const STATE_FULL_AMOUNT_DECIDE = 'refund-full-amount-decided';
const STATE_RETURN_PRODUCT = 'return-product';
const STATE_HALF_REFUND_ACCEPT_BUYER = 'refund-half-amount-buyer-accept';
const STATE_FULL_REFUND_ACCEPT_BUYER = 'refund-full-amount-buyer-accept';
const STATE_PRINT_RETURN_LABEL = 'return-label-printed';
const STATE_RETURN_SHIPPED = 'return-shipped';
const STATE_RETURN_DELIVERED = 'return-delivered';
const STATE_RETURN_COMPLETED = 'return-completed';
const STATE_RETURN_NOT_CONFIRMED = 'return-not-completed';
const STATE_EXPIRE_PARCEL = 'expire-parcel';

const STATE_PAYOUT = 'payout';
const STATE_PAYOUT_AFTER_RETURN = 'payout-after-return';
const STATE_PAYOUT_NOT_SHIPPED_RETURN = 'payout-not-shipped-return';
const STATE_CREATE_BATCH = 'enquiry-for-lot';
const STATE_APPROVE_BATCH = 'accepted-lot';
const STATE_MODIFY_BATCH = 'modified-lot';
const STATE_REJECT_BATCH = 'rejected-lot';
const STATE_CANCELLED = 'cancelled';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-product-default-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_CREATE_BATCH]: STATE_CREATE_BATCH,
      },
    },
    [STATE_CREATE_BATCH]: {
      on: {
        [TRANSITION_APPROVE_BATCH]: STATE_APPROVE_BATCH,
        [TRANSITION_MODIFY_BATCH]: STATE_MODIFY_BATCH,
        [TRANSITION_REJECT_BATCH]: STATE_REJECT_BATCH,
        [TRANSITION_CANCEL_BATCH]: STATE_CANCELLED,
      },
    },
    [STATE_MODIFY_BATCH]: {
      on: {
        [TRANSITION_CANCEL_AFTER_MODIFY]: STATE_CANCELLED,
      },
    },
    [STATE_APPROVE_BATCH]: {
      on: {
        [TRANSITION_CANCEL_AFTER_ACCEPT]: STATE_CANCELLED,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PURCHASED,
        [TRANSITION_EXPIRE_PARCEL]: STATE_EXPIRE_PARCEL,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PURCHASED]: {
      on: {
        [TRANSITION_MARK_DELIVERED]: STATE_DELIVERED,
        [TRANSITION_MARK_RECEIVED_FROM_PURCHASED]: STATE_RECEIVED,
        [TRANSITION_AUTO_CANCEL]: STATE_CANCELED,
        [TRANSITION_CANCEL]: STATE_CANCELED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED,
        [TRANSITION_PROVIDER_CANCEL]: STATE_CANCELED,
        [TRANSITION_CUSTOMER_CANCEL]: STATE_CANCELED,
        // [TRANSITION_MARK_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_AUTO_CANCEL]: STATE_AUTO_CANCEL,
      },
    },

    [STATE_CANCELED]: {},
    [STATE_ACCEPTED]: {
      on: {
        [TRANSITION_NOT_SHIPPED]: STATE_AUTO_CANCEL,
        [TRANSITION_ORDER_SHIPPED]: STATE_SHIPPED,
      },
    },
    [STATE_SHIPPED]: {
      on: {
        [TRANSITION_ORDER_DELIVERED]: STATE_ORDER_DELIVERED,
        [TRANSITION_ORDER_CANCELLED_BY_OPERATOR]: STATE_AUTO_CANCEL,
      },
    },
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_MARK_COMPLETE]: STATE_PAYOUT,
        [TRANSITION_MARK_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_AUTO_MARK_COMPLETE]: STATE_PAYOUT,
        [TRANSITION_REPORT_A_PROBLEM]: STATE_REPORT_A_PROBLEM,
      },
    },
    [STATE_PAYOUT]: {
      on: {
        [TRANSITION_COMPLETE_AFTER_PAYOUT]: STATE_COMPLETED,
      },
    },
    [STATE_REPORT_A_PROBLEM]: {
      on: {
        [TRANSITION_REFUND_HALF_AMOUNT_DECIDE]: STATE_HALF_AMOUNT_DECIDE,
        [TRANSITION_REFUND_FULL_AMOUNT_DECIDE]: STATE_FULL_AMOUNT_DECIDE,
        [TRANSITION_RETURN_PRODUCT]: STATE_RETURN_PRODUCT,
        [TRANSITION_REFUND_FULL_OPERATOR]: STATE_FULL_AMOUNT_DECIDE,
        [TRANSITION_AFTER_COMPLETE_AFTER_REPORT_A_PROBLEM]: STATE_PAYOUT,
      },
    },
    [STATE_HALF_AMOUNT_DECIDE]: {
      on: {
        [TRANSITION_HALF_REFUND_ACCEPT_BUYER]: STATE_HALF_REFUND_ACCEPT_BUYER,
        [TRANSITION_REJECT]: STATE_REPORT_A_PROBLEM,
      },
    },
    [STATE_HALF_REFUND_ACCEPT_BUYER]: {
      on: {
        [TRANSITION_REFUND_HALF_TRANSFER]: STATE_COMPLETED,
      },
    },
    [STATE_FULL_AMOUNT_DECIDE]: {
      on: {
        [TRANSITION_FULL_REFUND_ACCEPT_BUYER]: STATE_FULL_REFUND_ACCEPT_BUYER,
        [TRANSITION_REJECT_FULL]: STATE_REPORT_A_PROBLEM,
      },
    },
    [STATE_FULL_REFUND_ACCEPT_BUYER]: {
      on: {
        [TRANSITION_REFUND_FULL_TRANSFER]: STATE_COMPLETED,
      },
    },
    [STATE_RETURN_PRODUCT]: {
      on: {
        [TRANSITION_AUTO_COMPLETE_AFTER_RETURN]: STATE_PAYOUT_AFTER_RETURN,
        [TRANSITION_PRINT_RETURN_LABEL]: STATE_PRINT_RETURN_LABEL,
      },
    },
    [STATE_PAYOUT_AFTER_RETURN]: {
      on: {
        [TRANSITION_COMPLETE_AFTER_RETURN]: STATE_COMPLETED,
      },
    },
    [STATE_PRINT_RETURN_LABEL]: {
      on: {
        [TRANSITION_RETURN_SHIPPED]: STATE_RETURN_SHIPPED,
      },
    },
    [STATE_RETURN_SHIPPED]: {
      on: {
        [TRANSITION_RETURN_DELIVERED]: STATE_RETURN_DELIVERED,
      },
    },
    [STATE_PAYOUT_NOT_SHIPPED_RETURN]: {
      on: {
        [TRANSITION_COMPLETE_AFTER_RETURN_PAYOUT]: STATE_COMPLETED,
      },
    },
    [STATE_RETURN_DELIVERED]: {
      on: {
        [TRANSITION_CONFIRM_RETURN]: STATE_RETURN_COMPLETED,
        [TRANSITION_RETURN_NOT_CONFIRMED]: STATE_RETURN_NOT_CONFIRMED,
      },
    },
    [STATE_DISPUTED]: {
      on: {
        [TRANSITION_AUTO_CANCEL_FROM_DISPUTED]: STATE_CANCELED,
        [TRANSITION_CANCEL_FROM_DISPUTED]: STATE_CANCELED,
        [TRANSITION_MARK_RECEIVED_FROM_DISPUTED]: STATE_RECEIVED,
      },
    },

    [STATE_RECEIVED]: {
      on: {
        [TRANSITION_AUTO_COMPLETE]: STATE_COMPLETED,
      },
    },

    [STATE_COMPLETED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PURCHASED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

export const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsBatchApproved = tx =>
  getTransitionsToState(STATE_APPROVE_BATCH).includes(txLastTransition(tx));

export const txIsBatchModified = tx =>
  getTransitionsToState(STATE_MODIFY_BATCH).includes(txLastTransition(tx));

export const txIdBatchRejected = tx =>
  getTransitionsToState(STATE_REJECT_BATCH).includes(txLastTransition(tx));

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));
export const txIsCreateBatch = tx =>
  getTransitionsToState(STATE_CREATE_BATCH).includes(txLastTransition(tx));
export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));
export const txIsPayout = tx => getTransitionsToState(STATE_PAYOUT).includes(txLastTransition(tx));
export const txIsPayoutAfterReturn = tx =>
  getTransitionsToState(STATE_PAYOUT_AFTER_RETURN).includes(txLastTransition(tx));
export const txIsPayoutNotShippedReturn = tx =>
  getTransitionsToState(STATE_PAYOUT_NOT_SHIPPED_RETURN).includes(txLastTransition(tx));
export const txIsPurchased = tx =>
  getTransitionsToState(STATE_PURCHASED).includes(txLastTransition(tx));
export const txIsShipped = tx =>
  getTransitionsToState(STATE_SHIPPED).includes(txLastTransition(tx));

export const txIsCanceledBatch = tx => {
  return getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx));
};
export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));
export const txIsAutoCanceled = tx =>
  getTransitionsToState(STATE_AUTO_CANCEL).includes(txLastTransition(tx));
export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));
export const txIsExpireParcel = tx =>
  getTransitionsToState(STATE_EXPIRE_PARCEL).includes(txLastTransition(tx));
export const txIsDisputed = tx =>
  getTransitionsToState(STATE_DISPUTED).includes(txLastTransition(tx));
export const txIsBuyerHalfRefundAccept = tx =>
  getTransitionsToState(STATE_HALF_REFUND_ACCEPT_BUYER).includes(txLastTransition(tx));
export const txIsBuyerFullRefundAccept = tx =>
  getTransitionsToState(STATE_FULL_REFUND_ACCEPT_BUYER).includes(txLastTransition(tx));
export const txIsRefundFullDecided = tx =>
  getTransitionsToState(STATE_FULL_AMOUNT_DECIDE).includes(txLastTransition(tx));
export const txIsReceived = tx =>
  getTransitionsToState(STATE_RECEIVED).includes(txLastTransition(tx));
export const txIsReturnNotConfirmed = tx =>
  getTransitionsToState(STATE_RETURN_NOT_CONFIRMED).includes(txLastTransition(tx));

export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsReviewedByCustomer = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx));

export const txIsReviewedByProvider = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));

export const txIsReturnPrintLabel = tx =>
  getTransitionsToState(STATE_PRINT_RETURN_LABEL).includes(txLastTransition(tx));

export const txIsReturnShipped = tx =>
  getTransitionsToState(STATE_RETURN_SHIPPED).includes(txLastTransition(tx));

export const txIsConfirmReturnReceived = tx =>
  getTransitionsToState(STATE_RETURN_COMPLETED).includes(txLastTransition(tx));

export const txIsReturnDelivered = tx =>
  getTransitionsToState(STATE_RETURN_DELIVERED).includes(txLastTransition(tx));

export const txIsReportAProblem = tx =>
  getTransitionsToState(STATE_REPORT_A_PROBLEM).includes(txLastTransition(tx));
export const txIsHalfAmoundDecided = tx =>
  getTransitionsToState(STATE_HALF_AMOUNT_DECIDE).includes(txLastTransition(tx));
export const txIsProviderCanceled = tx => txLastTransition(tx) == TRANSITION_PROVIDER_CANCEL;
export const txIsCustomerCanceled = tx => txLastTransition(tx) == TRANSITION_CUSTOMER_CANCEL;
export const txIsMarkedComplete = tx => txLastTransition(tx) == TRANSITION_MARK_COMPLETE;

export const txIsReturned = tx =>
  getTransitionsToState(STATE_RETURN_PRODUCT).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

// Helper function to check if the transaction has passed a certain state
export const txHasPassedPaymentPending = hasPassedStateFn(STATE_PENDING_PAYMENT);
export const txHasBeenReceived = hasPassedStateFn(STATE_RECEIVED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_AUTO_CANCEL,
    TRANSITION_CANCEL,
    TRANSITION_ACCEPT,
    TRANSITION_MARK_COMPLETE,
    TRANSITION_PROVIDER_CANCEL,
    TRANSITION_CUSTOMER_CANCEL,
    TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
    TRANSITION_MARK_DELIVERED,
    TRANSITION_DISPUTE,
    TRANSITION_MARK_RECEIVED,
    TRANSITION_AUTO_MARK_RECEIVED,
    TRANSITION_AUTO_COMPLETE_AFTER_RETURN,
    TRANSITION_AUTO_MARK_COMPLETE,
    TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
    TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
    TRANSITION_CANCEL_FROM_DISPUTED,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_ORDER_SHIPPED,
    TRANSITION_ORDER_DELIVERED,
    TRANSITION_REPORT_A_PROBLEM,
    TRANSITION_REFUND_HALF_AMOUNT_DECIDE,
    // TRANSITION_HALF_REFUND_ACCEPT_BUYER,
    TRANSITION_REFUND_FULL_AMOUNT_DECIDE,
    // TRANSITION_FULL_REFUND_ACCEPT_BUYER,
    TRANSITION_RETURN_PRODUCT,
    TRANSITION_RETURN_SHIPPED,
    TRANSITION_RETURN_DELIVERED,
    TRANSITION_REFUND_HALF_TRANSFER,
    TRANSITION_REFUND_FULL_TRANSFER,
    TRANSITION_REFUND_FULL_OPERATOR,
    TRANSITION_CREATE_BATCH,
    TRANSITION_APPROVE_BATCH,
    TRANSITION_MODIFY_BATCH,
    TRANSITION_REJECT_BATCH,
    TRANSITION_CANCEL_BATCH,
    TRANSITION_CANCEL_AFTER_ACCEPT,
    TRANSITION_CANCEL_AFTER_MODIFY,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_ACCEPT,
    TRANSITION_RETURN_PRODUCT,
    TRANSITION_PRINT_RETURN_LABEL,
    'transition/request-payment-accept-lot',
    'transition/request-payment-modify-lot',
  ].includes(transition);
};
