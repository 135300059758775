import React, { useLayoutEffect, useRef, useState } from 'react';
import { injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import config from '../../config';
import css from './MangopayPayoutPage.module.css';
import facebookImage from '../../assets/sneakertimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/sneakertimeTwitter-600x314.jpg';
import {
  Footer,
  IconSpinner,
  LayoutSideNavigation,
  LayoutSingleColumn,
  LayoutWrapperAccountSettingsSideNav,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  UserNav,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { connect, useDispatch } from 'react-redux';
import PayoutForm from './PayoutForm';
import {
  connectMangoPayWithAPI,
  createBankAccountWithAPI,
  validateKYCWithAPI,
} from '../../util/api';
import Steps from 'rc-steps';
import { ensureUser } from '../../util/data';
import BankAccountForm from './BankAccountForm';
import KycForm from './KycForm';
import SuccessStep from './SuccessStep';
import { fetchCurrentUser } from '../../ducks/user.duck';

import InfoTip from './InfoTip';
import FailureTip from './FailureTip';
import SuccessTip from './SuccessTip';
import { withRouter } from 'react-router-dom';

export const PAYOUT_STEPS = {
  CREATE_USER: 'CREATE_USER',
  CREATE_WALLET: 'CREATE_WALLET',
  CREATE_BANK_ACCOUNT: 'CREATE_BANK_ACCOUNT',
  KYC_VALIDATION_ASKED: 'KYC_VALIDATION_ASKED',
  KYC_SUCCEEDED: 'KYC_SUCCEEDED',
  KYC_FAILED: 'KYC_FAILED',
  ADD_CARD: 'ADD_CARD',
};

function MangopayPayoutPage(props) {
  const { intl, scrollingDisabled, user, userLoading, location, history } = props;
  const currentUser = ensureUser(user);
  const dispatch = useDispatch();

  const fromRef = useRef(location?.state?.from ?? null);

  const siteTitle = config.siteTitle;
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mangopayConnectError, setMangopayConnectError] = useState(null);
  const schemaTitle = intl.formatMessage({ id: 'MangopayPayoutPage.title' });

  const steps_completed = currentUser.attributes?.profile?.metadata?.steps_completed ?? [];
  const mangopay_address = currentUser.attributes?.profile?.privateData?.mangopay_address ?? {};
  const isWalledCreated = currentUser?.attributes?.profile?.metadata?.mangopay_wallet_id ?? false;
  const isBankAccountAdded =
    currentUser?.attributes?.profile?.metadata?.mangopay_bank_account_id ?? false;
  const initialValues = { ...mangopay_address };

  const lastStep = steps_completed.length > 0 ? steps_completed[steps_completed.length - 1] : null;
  useLayoutEffect(() => {
    switch (lastStep) {
      case PAYOUT_STEPS.CREATE_WALLET:
        setActive(1);
        break;
      case PAYOUT_STEPS.ADD_CARD:
        if (isBankAccountAdded) {
          setActive(2);
        } else if (isWalledCreated) {
          setActive(1);
        } else {
          setActive(0);
        }
        break;

      case PAYOUT_STEPS.CREATE_BANK_ACCOUNT:
        // case PAYOUT_STEPS.ADD_CARD:
        setActive(2);
        break;
      case PAYOUT_STEPS.KYC_VALIDATION_ASKED:
      case PAYOUT_STEPS.KYC_SUCCEEDED:
      case PAYOUT_STEPS.KYC_FAILED:
        setActive(2);
        break;
      default:
        setActive(0);
        break;
    }
  }, [user]);

  const walletConnected = steps_completed.includes(PAYOUT_STEPS.CREATE_WALLET);
  const bankAccountCreated = steps_completed.includes(PAYOUT_STEPS.CREATE_BANK_ACCOUNT);
  const kycSubmitted = steps_completed.includes(PAYOUT_STEPS.KYC_VALIDATION_ASKED);

  const onSubmitToAPI = values => {
    setLoading(true);
    setMangopayConnectError(null);
    connectMangoPayWithAPI(values)
      .then(res => {
        setActive(prev => prev + 1);
        dispatch(fetchCurrentUser());
      })
      .catch(err => {
        const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
        setMangopayConnectError(errors);
      })
      .finally(() => setLoading(false));
  };

  const onCreateBankAccountToAPI = values => {
    setLoading(true);
    setMangopayConnectError(null);
    createBankAccountWithAPI(values)
      .then(res => {
        dispatch(fetchCurrentUser());
        setActive(prev => prev + 1);
      })
      .catch(err => {
        const errors = err?.errors ?? err?.response?.data?.message ?? err?.message;
        setMangopayConnectError(errors);
      })
      .finally(() => setLoading(false));
  };

  const onKycValidationToAPI = values => {
    setLoading(true);
    setMangopayConnectError(null);
    const { kycFile, kycFileBack } = values;
    const formData = new FormData();
    formData.append('kycFile', kycFile);
    formData.append('kycFileBack', kycFileBack);
    validateKYCWithAPI(formData)
      .then(res => {
        dispatch(fetchCurrentUser());
        if (fromRef.current) history.push(fromRef.current);
      })
      .catch(err => {
        const errors = err?.response?.data?.errors;
        const apiErrors = errors ?? err?.response?.data?.message ?? err?.message;
        setMangopayConnectError(apiErrors);
      })
      .finally(() => setLoading(false));
  };

  const kycValidationPendingMessage = intl.formatMessage({
    id: 'MangopayPayoutPage.kycValidationPendingMessage',
  });
  const kycValidationSuccessMessage = intl.formatMessage({
    id: 'MangopayPayoutPage.kycValidationSuccessMessage',
  });
  const kycValidationFailedMessage = intl.formatMessage({
    id: 'MangopayPayoutPage.kycValidationFailedMessage',
  });
  const kycValidateAgainMessage = intl.formatMessage({
    id: 'MangopayPayoutPage.kycValidateAgainMessage',
  });

  const kycvalidation = steps_completed.includes(PAYOUT_STEPS.KYC_SUCCEEDED) ? (
    <SuccessTip message={kycValidationSuccessMessage} />
  ) : steps_completed.includes(PAYOUT_STEPS.KYC_FAILED) ? (
    <>
      <FailureTip message={kycValidationFailedMessage} />
      <InfoTip message={kycValidateAgainMessage} />

      <KycForm
        onSubmit={onKycValidationToAPI}
        mangopayConnectError={mangopayConnectError}
        inProgress={loading}
        setActive={setActive}
        intl={intl}
      />
    </>
  ) : (
    <InfoTip message={kycValidationPendingMessage} />
  );

  const kycFailed = steps_completed.includes(PAYOUT_STEPS.KYC_FAILED);

  const kycTabStatus = steps_completed.includes(PAYOUT_STEPS.KYC_SUCCEEDED)
    ? 'success'
    : steps_completed.includes(PAYOUT_STEPS.KYC_FAILED)
    ? 'error'
    : 'wait';

  const step1Label = intl.formatMessage({ id: 'MangopayPayoutPage.step1Label' });
  const step2Label = intl.formatMessage({ id: 'MangopayPayoutPage.step2Label' });
  const step3Label = intl.formatMessage({ id: 'MangopayPayoutPage.step3Label' });

  const successStep1Text = intl.formatMessage({ id: 'MangopayPayoutPage.successStep1Text' });
  const successStep2Text = intl.formatMessage({ id: 'MangopayPayoutPage.successStep2Text' });
  const successStep3Text = intl.formatMessage({ id: 'MangopayPayoutPage.successStep3Text' });
  const loadingText = intl.formatMessage({ id: 'MangopayPayoutPage.loadingText' });

  const steps = (
    <Steps current={active} direction="vertical">
      {/* <Steps.Step
        title={step1Label}
        description={
          active == 0 ? (
            walletConnected ? (
              <SuccessStep message={successStep1Text} />
            ) : (
              <PayoutForm
                onSubmit={onSubmitToAPI}
                inProgress={loading}
                mangopayConnectError={mangopayConnectError}
                intl={intl}
              />
            )
          ) : null
        }
      /> */}
      <Steps.Step
        title={step2Label}
        description={
          active == 1 ? (
            bankAccountCreated ? (
              <SuccessStep message={successStep2Text} />
            ) : isWalledCreated ? (
              <BankAccountForm
                onSubmit={onCreateBankAccountToAPI}
                inProgress={loading}
                setActive={setActive}
                mangopayConnectError={mangopayConnectError}
                initialValues={initialValues}
                intl={intl}
                isWalledCreated={walletConnected}
              />
            ) : null
          ) : null
        }
      />
      <Steps.Step
        title={step3Label}
        // status={kycTabStatus}
        description={
          active == 2 ? (
            kycSubmitted ? (
              <>
                {kycFailed ? null : <SuccessStep message={successStep3Text} />}
                {kycvalidation}
              </>
            ) : isBankAccountAdded ? (
              <KycForm
                onSubmit={onKycValidationToAPI}
                mangopayConnectError={mangopayConnectError}
                inProgress={loading}
                setActive={setActive}
                intl={intl}
              />
            ) : null
          ) : null
        }
      />
    </Steps>
  );

  return (
    <Page scrollingDisabled={scrollingDisabled} title={schemaTitle}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="MangopayPayoutPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="MangopayPayoutPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccountSettingsSideNav currentTab="MangopayPayoutPage" />
        <LayoutWrapperMain>
          <div className={css.root}>
            <h1>{schemaTitle}</h1>
            {!isWalledCreated ? (
              <p className={css.note}>
                {intl.formatMessage({ id: 'MangopayPayoutPage.MangopayPayoutPage.note' })}:
                <NamedLink name="MangopayWalletComfirmation" className={css.link}>
                  {intl.formatMessage({
                    id: 'MangopayPayoutPage.MangopayPayoutPage.createWalletLink',
                  })}
                </NamedLink>
              </p>
            ) : null}
            {userLoading ? (
              <div className="flex items-center gap-4">
                <IconSpinner /> <span>{loadingText}</span>
              </div>
            ) : (
              steps
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
}

const mapStateToProps = state => {
  const { currentUser, userLoading } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    user: currentUser,
    userLoading,
  };
};
const mapDispatchToProps = null;

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MangopayPayoutPage);
