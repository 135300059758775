import React, { useState } from 'react';
import css from './Generic.module.css';
import { Form as FinalForm } from 'react-final-form';
import { FieldTextInput, Form, PrimaryButton } from '../../../components';
import Payment from 'payment';
import {
  composeValidators,
  required,
  validCardNumber,
  validCvc,
  validExpiry,
} from '../../../util/validators';
import { useIntl } from 'react-intl';

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        15
      )}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        14
      )}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12
      )} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === 'amex' ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

function AddCardForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { handleSubmit, form, inProgress, mangopayConnectError, errors } = formRenderProps;

        const onAutoFill = () => {
          form.batch(() => {
            form.change('cardNumber', '5033951000000004');
            form.change('cardCvx', '123');
            form.change('cardExpirationDate', '12/27');
          });
        };
        const intl = useIntl();
        const submitDisabled = Object.keys(errors).length > 0 || inProgress;
        const errorsMaybe = mangopayConnectError ? (
          Array.isArray(mangopayConnectError) ? (
            <ul className="pl-4 list-disc mt-0">
              {mangopayConnectError.map(error => (
                <li key={error} className="text-red-500">
                  {error}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-red-500 mt-0">{mangopayConnectError}</p>
          )
        ) : null;

        return (
          <Form onSubmit={handleSubmit}>
            {errorsMaybe}
            <div className={css.field}>
              <FieldTextInput
                id="cardNumber"
                name="cardNumber"
                label={intl.formatMessage({ id: 'forms.AddCardForm.cardNumberLabel' })}
                placeholder={intl.formatMessage({ id: 'forms.AddCardForm.cardNumberPlaceholder' })}
                format={formatCreditCardNumber}
                validate={composeValidators(
                  required(
                    intl.formatMessage({ id: 'forms.AddCardForm.cardNumberRequiredMessage' })
                  ),
                  validCardNumber(
                    intl.formatMessage({ id: 'forms.AddCardForm.invalidCardNumberMessage' })
                  )
                )}
              />
            </div>
            <div className={css.field}>
              <FieldTextInput
                id="cardExpirationDate"
                name="cardExpirationDate"
                label={intl.formatMessage({ id: 'forms.AddCardForm.cardExpirationDateLabel' })}
                placeholder={intl.formatMessage({
                  id: 'forms.AddCardForm.cardExpirationPlaceholder',
                })}
                pattern="\d\d/\d\d"
                format={formatExpirationDate}
                validate={composeValidators(
                  required(
                    intl.formatMessage({ id: 'forms.AddCardForm.cardExpirationRequiredMessage' })
                  ),
                  validExpiry(
                    intl.formatMessage({ id: 'forms.AddCardForm.cardExpirationDateValidMessage' })
                  )
                )}
                maxLength={5}
              />
            </div>

            <div className={css.field}>
              <FieldTextInput
                id="cardCvx"
                name="cardCvx"
                label={intl.formatMessage({ id: 'forms.AddCardForm.cvcLabel' })}
                placeholder={intl.formatMessage({ id: 'forms.AddCardForm.cvcPlaceholder' })}
                format={formatCVC}
                validate={composeValidators(
                  required(intl.formatMessage({ id: 'forms.AddCardForm.cvcRequiredMessage' })),
                  validCvc(intl.formatMessage({ id: 'forms.AddCardForm.cvcInvalidMessage' }))
                )}
                maxLength={4}
              />
            </div>

            {process.env.REACT_APP_ENV == 'development' && (
              <button
                type="button"
                onClick={onAutoFill}
                className="text-white font-semibold text-base tracking-wide shadow-md bg-blue-500 rounded-full cursor-pointer hover:bg-blue-600 transition-colors ease-linear duration-150 border-solid border-blue-500 mt-4 col-span-2 w-full py-2"
              >
                Autofill test details
              </button>
            )}

            <div className={css.submitButtonWrapper}>
              <PrimaryButton disabled={submitDisabled} inProgress={inProgress}>
                {intl.formatMessage({ id: 'forms.AddCardForm.submitMessage' })}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default AddCardForm;
