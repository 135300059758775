import { types as sdkTypes } from '../util/sdkLoader';

export default function useSdkTypes() {
  const { Money, LatLng, LatLngBounds } = sdkTypes;
  return {
    Money,
    LatLng,
    LatLngBounds,
  };
}
