import React from 'react';
import {
  FieldAirbnbDateTimePicker,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import { Form as FinalForm } from 'react-final-form';
import getCountryCodes from '../../translations/countryCodes';
import config from '../../config';
import css from './MangopayPayoutPage.module.css';
import { composeValidators, required, validateIBAN } from '../../util/validators';

/* 
 AddressLine1,
    City,
    Region,
    PostalCode,
    Country,
    IBAN,
    BIC,
    OwnerName,
*/
function BankAccountForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          handleSubmit,
          form,
          inProgress,
          errors,
          setActive,
          mangopayConnectError,
          intl,
        } = formRenderProps;
        const countryCodes = getCountryCodes(config.locale);

        const autoFill = () => {
          form.batch(() => {
            form.change('AddressLine1', '1 Mangopay Street');
            form.change('AddressLine2', 'The Loop');
            form.change('City', 'Paris');
            form.change('Region', 'Ile de France');
            form.change('PostalCode', '75001');
            form.change('Country', 'FR');
            form.change('IBAN', 'FR7630004000031234567890143');
            form.change('BIC', 'BNPAFRPP');
            form.change('OwnerName', 'John Doe');
          });
        };

        const submitDisabled = Object.keys(errors).length > 0 || inProgress;
        const errorsMaybe = mangopayConnectError ? (
          Array.isArray(mangopayConnectError) ? (
            <ul className="pl-4 list-disc mt-0">
              {mangopayConnectError.map(error => (
                <li key={error} className="text-red-500">
                  {error}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-red-500 mt-0">{mangopayConnectError}</p>
          )
        ) : null;

        const addressLine1Label = intl.formatMessage({ id: 'BankAccountForm.addressLine1Label' });
        const addressLine1Placeholder = intl.formatMessage({
          id: 'BankAccountForm.addressLine1Placeholder',
        });
        const addressLine1Required = intl.formatMessage({
          id: 'BankAccountForm.addressLine1Required',
        });
        const addressLine2Label = intl.formatMessage({ id: 'BankAccountForm.addressLine2Label' });
        const addressLine2Placeholder = intl.formatMessage({
          id: 'BankAccountForm.addressLine2Placeholder',
        });
        const addressLine2Required = intl.formatMessage({
          id: 'BankAccountForm.addressLine2Required',
        });
        const cityLabel = intl.formatMessage({ id: 'BankAccountForm.cityLabel' });
        const cityPlaceholder = intl.formatMessage({ id: 'BankAccountForm.cityPlaceholder' });
        const cityRequired = intl.formatMessage({ id: 'BankAccountForm.cityRequired' });
        const regionLabel = intl.formatMessage({ id: 'BankAccountForm.regionLabel' });
        const regionPlaceholder = intl.formatMessage({ id: 'BankAccountForm.regionPlaceholder' });
        const regionRequired = intl.formatMessage({ id: 'BankAccountForm.regionRequired' });
        const postalCodeLabel = intl.formatMessage({ id: 'BankAccountForm.postalCodeLabel' });
        const postalCodePlaceholder = intl.formatMessage({
          id: 'BankAccountForm.postalCodePlaceholder',
        });
        const postalCodeRequired = intl.formatMessage({ id: 'BankAccountForm.postalCodeRequired' });
        const countryLabel = intl.formatMessage({ id: 'BankAccountForm.countryLabel' });
        const countryPlaceholder = intl.formatMessage({ id: 'BankAccountForm.countryPlaceholder' });
        const countryRequired = intl.formatMessage({ id: 'BankAccountForm.countryRequired' });
        const ibanLabel = intl.formatMessage({ id: 'BankAccountForm.ibanLabel' });
        const ibanPlaceholder = intl.formatMessage({ id: 'BankAccountForm.ibanPlaceholder' });
        const ibanRequired = intl.formatMessage({ id: 'BankAccountForm.ibanRequired' });
        const bicLabel = intl.formatMessage({ id: 'BankAccountForm.bicLabel' });
        const bicPlaceholder = intl.formatMessage({ id: 'BankAccountForm.bicPlaceholder' });
        const bicRequired = intl.formatMessage({ id: 'BankAccountForm.bicRequired' });
        const ownerNameLabel = intl.formatMessage({ id: 'BankAccountForm.ownerNameLabel' });
        const ownerNamePlaceholder = intl.formatMessage({
          id: 'BankAccountForm.ownerNamePlaceholder',
        });
        const ownerNameRequired = intl.formatMessage({ id: 'BankAccountForm.ownerNameRequired' });
        const nextButtonLabel = intl.formatMessage({ id: 'BankAccountForm.nextButtonLabel' });

        return (
          <Form onSubmit={handleSubmit}>
            {errorsMaybe}
            <div className={css.grid}>
              <FieldTextInput
                id="AddressLine1"
                name="AddressLine1"
                label={addressLine1Label}
                placeholder={addressLine1Placeholder}
                validate={required(addressLine1Required)}
              />
              <FieldTextInput
                id="AddressLine2"
                name="AddressLine2"
                label={addressLine2Label}
                placeholder={addressLine2Placeholder}
                // validate={required(addressLine2Required)}
              />

              <FieldTextInput
                id="City"
                name="City"
                label={cityLabel}
                placeholder={cityPlaceholder}
                validate={required(cityRequired)}
              />
              <FieldTextInput
                id="Region"
                name="Region"
                label={regionLabel}
                placeholder={regionPlaceholder}
                // validate={required(regionRequired)}
              />

              <FieldTextInput
                id="PostalCode"
                name="PostalCode"
                label={postalCodeLabel}
                placeholder={postalCodePlaceholder}
                validate={required(postalCodeRequired)}
              />
              <FieldSelect
                id={`Country`}
                name="Country"
                label={countryLabel}
                validate={required(cityRequired)}
              >
                <option disabled value="">
                  {countryPlaceholder}
                </option>
                {countryCodes.map(country => {
                  return (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </FieldSelect>
              <FieldTextInput
                id="IBAN"
                name="IBAN"
                label={ibanLabel}
                className={css.span2}
                placeholder={ibanPlaceholder}
                validate={composeValidators(
                  required(ibanRequired),
                  validateIBAN(
                    intl.formatMessage({ id: 'MangopayPayoutPage.BankAccountForm.ibanInvalid' })
                  )
                )}
              />
              <FieldTextInput
                id="BIC"
                name="BIC"
                label={bicLabel}
                placeholder={bicPlaceholder}
                validate={required(bicRequired)}
              />
              <FieldTextInput
                id="OwnerName"
                name="OwnerName"
                label={ownerNameLabel}
                placeholder={ownerNamePlaceholder}
                validate={required(ownerNameRequired)}
              />
            </div>

            {process.env.REACT_APP_ENV == 'development' && (
              <button
                type="button"
                onClick={autoFill}
                className="text-white font-semibold text-base tracking-wide shadow-md bg-blue-500 rounded-full cursor-pointer hover:bg-blue-600 transition-colors ease-linear duration-150 border-solid border-blue-500 mt-4 col-span-2 w-full py-2"
              >
                Autofill Test Credentials
              </button>
            )}

            <div className="my-8">
              <PrimaryButton inProgress={inProgress} disabled={submitDisabled}>
                {nextButtonLabel}
              </PrimaryButton>
              {/* <SecondaryButton
                className="mt-4"
                disabled={inProgress}
                onClick={() => setActive(active => active - 1)}
              >
                Prev: Personal details
              </SecondaryButton> */}
            </div>
          </Form>
        );
      }}
    />
  );
}

export default BankAccountForm;
