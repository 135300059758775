import React, { useState, useEffect } from 'react';
import {
  FieldAirbnbDateTimePicker,
  FieldCheckbox,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
  ExternalLink,
} from '../../components';
import { Form as FinalForm } from 'react-final-form';
import getCountryCodes from '../../translations/countryCodes';
import config from '../../config';
import css from './MangopayPayoutPage.module.css';
import { required } from '../../util/validators';
import { FormattedMessage } from 'react-intl';

function PayoutForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          handleSubmit,
          form,
          inProgress,
          errors,
          mangopayConnectError,
          intl,
          values,
        } = formRenderProps;
        const countryCodes = getCountryCodes(config.locale);
        const [show, setshow] = useState(false);
        useEffect(() => {
          setshow(!show);
        }, [values?.TermsAndConditions]);

        const onAutoFill = () => {
          const random = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;

          form.batch(() => {
            form.change('FirstName', 'Victor');
            form.change('LastName', 'Hugo');
            form.change('AddressLine1', '1 rue des Misérables');
            form.change('Country', 'FR');
            form.change('PostalCode', '75000');
            form.change('City', 'Paris');
            form.change('Birthday', 13001865800);
            form.change('Nationality', 'FR');
            form.change('CountryOfResidence', 'FR');
            form.change('Occupation', 'Writer');
            form.change('Email', `test${random}@random.com`);
          });
        };
        const submitDisabled = Object.keys(errors).length > 0 || inProgress || show;

        const errorsMaybe = mangopayConnectError ? (
          Array.isArray(mangopayConnectError) ? (
            <ul className="pl-4 list-disc mt-0">
              {mangopayConnectError.map(error => (
                <li key={error} className="text-red-500">
                  {error}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-red-500 mt-0">{mangopayConnectError}</p>
          )
        ) : null;

        const firstNameLabel = intl.formatMessage({ id: 'PayoutForm.firstNameLabel' });
        const firstNamePlaceholder = intl.formatMessage({ id: 'PayoutForm.firstNamePlaceholder' });
        const firstNameRequired = intl.formatMessage({ id: 'PayoutForm.firstNameRequired' });
        const lastNameLabel = intl.formatMessage({ id: 'PayoutForm.lastNameLabel' });
        const lastNamePlaceholder = intl.formatMessage({ id: 'PayoutForm.lastNamePlaceholder' });
        const lastNameRequired = intl.formatMessage({ id: 'PayoutForm.lastNameRequired' });
        const birthdayLabel = intl.formatMessage({ id: 'PayoutForm.birthdayLabel' });
        const birthdayPlaceholder = intl.formatMessage({ id: 'PayoutForm.birthdayPlaceholder' });
        const birthdayRequired = intl.formatMessage({ id: 'PayoutForm.birthdayRequired' });
        const nationalityLabel = intl.formatMessage({ id: 'PayoutForm.nationalityLabel' });
        const nationalityPlaceholder = intl.formatMessage({
          id: 'PayoutForm.nationalityPlaceholder',
        });
        const nationalityRequired = intl.formatMessage({ id: 'PayoutForm.nationalityRequired' });
        const countryOfResidenceLabel = intl.formatMessage({
          id: 'PayoutForm.countryOfResidenceLabel',
        });
        const countryOfResidencePlaceholder = intl.formatMessage({
          id: 'PayoutForm.countryOfResidencePlaceholder',
        });
        const countryOfResidenceRequired = intl.formatMessage({
          id: 'PayoutForm.countryOfResidenceRequired',
        });
        const occupationLabel = intl.formatMessage({ id: 'PayoutForm.occupationLabel' });
        const occupationPlaceholder = intl.formatMessage({
          id: 'PayoutForm.occupationPlaceholder',
        });
        const emailLabel = intl.formatMessage({ id: 'PayoutForm.emailLabel' });
        const emailPlaceholder = intl.formatMessage({ id: 'PayoutForm.emailPlaceholder' });
        const emailRequired = intl.formatMessage({ id: 'PayoutForm.emailRequired' });
        const addressLine1Label = intl.formatMessage({ id: 'PayoutForm.addressLine1Label' });
        const addressLine1Placeholder = intl.formatMessage({
          id: 'PayoutForm.addressLine1Placeholder',
        });
        const cityLabel = intl.formatMessage({ id: 'PayoutForm.cityLabel' });
        const cityPlaceholder = intl.formatMessage({ id: 'PayoutForm.cityPlaceholder' });
        const postalCodeLabel = intl.formatMessage({ id: 'PayoutForm.postalCodeLabel' });
        const postalCodePlaceholder = intl.formatMessage({
          id: 'PayoutForm.postalCodePlaceholder',
        });
        const countryLabel = intl.formatMessage({ id: 'PayoutForm.countryLabel' });
        const countryPlaceholder = intl.formatMessage({ id: 'PayoutForm.countryPlaceholder' });
        const nextButtonLabel = intl.formatMessage({
          id: 'MangopayPayoutPage.PayoutForm.buttonLabel',
        });

        return (
          <Form onSubmit={handleSubmit}>
            {errorsMaybe}
            <div className={css.grid}>
              <FieldTextInput
                id="FirstName"
                name="FirstName"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={required(firstNameRequired)}
              />
              <FieldTextInput
                id="LastName"
                name="LastName"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={required(lastNameRequired)}
              />
              <FieldAirbnbDateTimePicker
                id="Birthday"
                name="Birthday"
                placeholder={birthdayPlaceholder}
                label={birthdayLabel}
                validate={required(birthdayRequired)}
              />
              <FieldSelect
                id={`Nationality`}
                name="Nationality"
                label={nationalityLabel}
                validate={required(nationalityRequired)}
              >
                <option disabled value="">
                  {nationalityPlaceholder}
                </option>
                {countryCodes.map(country => {
                  return (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </FieldSelect>
              <FieldSelect
                id={`CountryOfResidence`}
                name="CountryOfResidence"
                label={countryOfResidenceLabel}
                validate={required(countryOfResidenceRequired)}
              >
                <option disabled value="">
                  {countryOfResidencePlaceholder}
                </option>
                {countryCodes.map(country => {
                  return (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </FieldSelect>
              <FieldTextInput
                id="Occupation"
                name="Occupation"
                label={occupationLabel}
                placeholder={occupationPlaceholder}
              />
              <FieldTextInput
                type="email"
                id="Email"
                name="Email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={required(emailRequired)}
              />

              <h3 className={css.span2}>
                {intl.formatMessage({ id: 'MangopayPayoutPage.PayoutForm.billingAddressLabel' })}
              </h3>
              <FieldTextInput
                id="AddressLine1"
                name="AddressLine1"
                label={addressLine1Label}
                placeholder={addressLine1Placeholder}
              />
              <FieldTextInput
                id="City"
                name="City"
                label={cityLabel}
                placeholder={cityPlaceholder}
              />
              <FieldTextInput
                id="PostalCode"
                name="PostalCode"
                label={postalCodeLabel}
                placeholder={postalCodePlaceholder}
              />
              <FieldSelect id={`Country`} name="Country" label={countryLabel}>
                <option disabled value="">
                  {countryPlaceholder}
                </option>
                {countryCodes.map(country => {
                  return (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </FieldSelect>
            </div>

            {/* 
   FirstName: "Victor",
   LastName: "Hugo",
   Address: {
     AddressLine1: "1 rue des Misérables",
     Country: "FR",
     PostalCode: "75000",
     City: "Paris",
   },
   Birthday: 1300186358,
   Nationality: "FR",
   CountryOfResidence: "FR",
   Occupation: "Writer",
   IncomeRange: "6",
   ProofOfIdentity: null,
   ProofOfAddress: null,
   PersonType: "NATURAL",
   Email: "victor@hugo.com",
   Tag: "custom tag",
          */}

          {
             process.env.REACT_APP_ENV=='development' && <button
             type="button"
             onClick={onAutoFill}
             className="text-white font-semibold text-base tracking-wide shadow-md bg-blue-500 rounded-full cursor-pointer hover:bg-blue-600 transition-colors ease-linear duration-150 border-solid border-blue-500 mt-4 col-span-2 w-full py-2"
           >
             Autofill test details
           </button>
          }


            <div className="my-8">
              <FieldCheckbox
                className={css.termsAndConditions}
                id="TermsAndConditions"
                name="TermsAndConditions"
                label={
                  <p className={css.paragraph}>
                    {intl.formatMessage({ id: 'MangopayPayoutPage.PayoutForm.termsOfServiceOne' })}
                    <ExternalLink href="/cgu-mangopay" className={css.link}>
                      {intl.formatMessage({
                        id: 'MangopayPayoutPage.PayoutForm.termsOfServiceLink',
                      })}
                    </ExternalLink>
                  </p>
                }
                validate={required(
                  <FormattedMessage id="MangopayPayoutPage.PayoutForm.termsOfServiceRequired" />
                )}
              />
              <br />
              <PrimaryButton inProgress={inProgress} disabled={submitDisabled}>
                {nextButtonLabel}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default PayoutForm;
