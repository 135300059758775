import React from 'react';
import classNames from 'classnames';
import css from './Tip.module.css';
import { MdOutlineError } from 'react-icons/md';

function FailureTip({ message }) {
  return (
    <div>
      <div className={classNames(css.verificiationBox, css.verficiationErrorBox)}>
        <div className={css.verificiationBoxTextWrapper}>
          <div className={css.verificationBoxTitle}>
            <MdOutlineError className={css.tipIcon} />
            <div className={css.verificationBoxTitle}>{message}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FailureTip;
