/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  ChangeLanguageForm,
} from '../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;
  const intl = useIntl();
  const user = ensureCurrentUser(currentUser);

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <ChangeLanguageForm />
          <div>
            <p className={css.fadeText}>
              <FormattedMessage id="TopbarMobileMenu.exploreProducts" />
            </p>

            <ul>
              <li className={css.listItem}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: '?pub_category=fabrics',
                  }}
                  className={css.navigationLinkFilter}
                >
                  <FormattedMessage id="LandingPage.fabrics" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: '?pub_category=notions',
                  }}
                  className={css.navigationLinkFilter}
                >
                  <FormattedMessage id="LandingPage.notions" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: '?pub_category=machinesTools',
                  }}
                  className={css.navigationLinkFilter}
                >
                  <FormattedMessage id="LandingPage.MachineTool" />
                </NamedLink>
              </li>
            </ul>
            <p className={css.listItem}>
              <NamedLink name="AboutPage" className={css.link}>
                <FormattedMessage id="LandingPage.aboutUs" />
              </NamedLink>
            </p>
            <p>
              <FormattedMessage id="LandingPage.ourPlatForm" />
            </p>
            <ul>
              <li className={css.listItem}>
                <NamedLink className={css.platFormLink} name="TermsOfServicePage">
                  {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.termsOfService' })}
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink className={css.platFormLink} name="PrivacyPolicyPage">
                  {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.privacyPolicies' })}
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink className={css.platFormLink} name="MangopayPolicyPage">
                  {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.mangoPay' })}
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink className={css.platFormLink} name="AboutPage">
                  {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.howItWorks' })}
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink className={css.platFormLink} name="FaqPage">
                  {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.helpCenter' })}
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink className={css.platFormLink} name="ContactUsPage">
                  {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.contactUs' })}
                </NamedLink>
              </li>
            </ul>
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="CategoryPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <div>
          <p className={css.fadeText}>
            <FormattedMessage id="TopbarMobileMenu.exploreProducts" />
          </p>

          <ul>
            <li className={css.listItem}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: '?pub_category=fabrics',
                }}
                className={css.navigationLinkFilter}
              >
                <FormattedMessage id="LandingPage.fabrics" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: '?pub_category=notions',
                }}
                className={css.navigationLinkFilter}
              >
                <FormattedMessage id="LandingPage.notions" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink
                name="SearchPage"
                to={{
                  search: '?pub_category=machinesTools',
                }}
                className={css.navigationLinkFilter}
              >
                <FormattedMessage id="LandingPage.MachineTool" />
              </NamedLink>
            </li>
          </ul>
        </div>
        <ChangeLanguageForm />
        <br />
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        <NamedLink
          className={classNames(css.inbox, currentPageClass('WishlistPage'))}
          name="WishlistPage"
        >
          <FormattedMessage id="TopbarMobileMenu.TopbarMobileMenu.favorites" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
          to={{
            search: '?tab=published',
          }}
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        <p className={css.aboutus}>
          <NamedLink name="AboutPage" className={css.link}>
            <FormattedMessage id="LandingPage.aboutUs" />
          </NamedLink>
        </p>
        <p className={css.aboutus}>
          <FormattedMessage id="LandingPage.ourPlatForm" />
        </p>
        <ul className={css.platForm}>
          <li className={css.listItem}>
            <NamedLink className={css.platFormLink} name="TermsOfServicePage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.termsOfService' })}
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink className={css.platFormLink} name="PrivacyPolicyPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.privacyPolicies' })}
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink className={css.platFormLink} name="MangopayPolicyPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.mangoPay' })}
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink className={css.platFormLink} name="AboutPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.howItWorks' })}
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink className={css.platFormLink} name="FaqPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.helpCenter' })}
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink className={css.platFormLink} name="ContactUsPage">
              {intl.formatMessage({ id: 'LandingPage.PlatFormMenu.contactUs' })}
            </NamedLink>
          </li>
        </ul>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="CategoryPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
