import React from 'react';
import {
  FieldAirbnbDateTimePicker,
  FieldSelect,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../../components';
import { Form as FinalForm } from 'react-final-form';
import getCountryCodes from '../../../translations/countryCodes';
import config from '../../../config';
import css from './Generic.module.css';
import { required } from '../../../util/validators';

function UserInfoForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          handleSubmit,
          form,
          inProgress,
          errors,
          mangopayConnectError,
          intl,
        } = formRenderProps;
        const countryCodes = getCountryCodes(config.locale);

        const onAutoFill = () => {
          const random = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;

          form.batch(() => {
            form.change('FirstName', 'Victor');
            form.change('LastName', 'Hugo');
            form.change('AddressLine1', '1 rue des Misérables');
            form.change('Country', 'FR');
            form.change('PostalCode', '75000');
            form.change('City', 'Paris');
            form.change('Birthday', 13001865800);
            form.change('Nationality', 'FR');
            form.change('CountryOfResidence', 'FR');
            form.change('Occupation', 'Writer');
            form.change('Email', `test${random}@random.com`);
          });
        };
        const submitDisabled = Object.keys(errors).length > 0 || inProgress;

        const errorsMaybe = mangopayConnectError ? (
          Array.isArray(mangopayConnectError) ? (
            <ul className="pl-4 list-disc mt-0">
              {mangopayConnectError.map(error => (
                <li key={error} className="text-red-500">
                  {error}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-red-500 mt-0">{mangopayConnectError}</p>
          )
        ) : null;

        const firstNameLabel = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.firstNamePlaceholder',
        });
        const firstNameRequired = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.firstNameRequired',
        });
        const lastNameLabel = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.lastNamePlaceholder',
        });
        const lastNameRequired = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.lastNameRequired',
        });
        const birthdayLabel = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.birthdayLabel',
        });
        const birthdayPlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.birthdayPlaceholder',
        });
        const birthdayRequired = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.birthdayRequired',
        });
        const nationalityLabel = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.nationalityLabel',
        });
        const nationalityPlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.nationalityPlaceholder',
        });
        const nationalityRequired = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.nationalityRequired',
        });
        const countryOfResidenceLabel = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.countryOfResidenceLabel',
        });
        const countryOfResidencePlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.countryOfResidencePlaceholder',
        });
        const countryOfResidenceRequired = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.countryOfResidenceRequired',
        });
        const occupationLabel = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.occupationLabel',
        });
        const occupationPlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.occupationPlaceholder',
        });
        const emailLabel = intl.formatMessage({ id: 'MangopayPaymentMethodsPage.emailLabel' });
        const emailPlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.emailPlaceholder',
        });
        const emailRequired = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.emailRequired',
        });
        const addressLine1Label = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.addressLine1Label',
        });
        const addressLine1Placeholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.addressLine1Placeholder',
        });
        const cityLabel = intl.formatMessage({ id: 'MangopayPaymentMethodsPage.cityLabel' });
        const cityPlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.cityPlaceholder',
        });
        const postalCodeLabel = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.postalCodeLabel',
        });
        const postalCodePlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.postalCodePlaceholder',
        });
        const countryLabel = intl.formatMessage({ id: 'MangopayPaymentMethodsPage.countryLabel' });
        const countryPlaceholder = intl.formatMessage({
          id: 'MangopayPaymentMethodsPage.countryPlaceholder',
        });
        const nextButtonLabel = intl.formatMessage({ id: 'UserInfoForm.nextButtonLabel' });

        return (
          <Form onSubmit={handleSubmit}>
            {errorsMaybe}
            <div className={css.grid}>
              <FieldTextInput
                id="FirstName"
                name="FirstName"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={required(firstNameRequired)}
              />
              <FieldTextInput
                id="LastName"
                name="LastName"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={required(lastNameRequired)}
              />
              <FieldAirbnbDateTimePicker
                id="Birthday"
                name="Birthday"
                placeholder={birthdayPlaceholder}
                label={birthdayLabel}
                validate={required(birthdayRequired)}
              />
              <FieldSelect
                id={`Nationality`}
                name="Nationality"
                label={nationalityLabel}
                validate={required(nationalityRequired)}
              >
                <option disabled value="">
                  {nationalityPlaceholder}
                </option>
                {countryCodes.map(country => {
                  return (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </FieldSelect>
              <FieldSelect
                id={`CountryOfResidence`}
                name="CountryOfResidence"
                label={countryOfResidenceLabel}
                validate={required(countryOfResidenceRequired)}
              >
                <option disabled value="">
                  {countryOfResidencePlaceholder}
                </option>
                {countryCodes.map(country => {
                  return (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </FieldSelect>
              <FieldTextInput
                id="Occupation"
                name="Occupation"
                label={occupationLabel}
                placeholder={occupationPlaceholder}
              />
              <FieldTextInput
                type="email"
                id="Email"
                name="Email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                validate={required(emailRequired)}
              />

              <h3 className={css.span2}>Address</h3>
              <FieldTextInput
                id="AddressLine1"
                name="AddressLine1"
                label={addressLine1Label}
                placeholder={addressLine1Placeholder}
              />
              <FieldTextInput
                id="City"
                name="City"
                label={cityLabel}
                placeholder={cityPlaceholder}
              />
              <FieldTextInput
                id="PostalCode"
                name="PostalCode"
                label={postalCodeLabel}
                placeholder={postalCodePlaceholder}
              />
              <FieldSelect id={`Country`} name="Country" label={countryLabel}>
                <option disabled value="">
                  {countryPlaceholder}
                </option>
                {countryCodes.map(country => {
                  return (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </FieldSelect>
            </div>

            {/* 
   FirstName: "Victor",
   LastName: "Hugo",
   Address: {
     AddressLine1: "1 rue des Misérables",
     Country: "FR",
     PostalCode: "75000",
     City: "Paris",
   },
   Birthday: 1300186358,
   Nationality: "FR",
   CountryOfResidence: "FR",
   Occupation: "Writer",
   IncomeRange: "6",
   ProofOfIdentity: null,
   ProofOfAddress: null,
   PersonType: "NATURAL",
   Email: "victor@hugo.com",
   Tag: "custom tag",
          */}

          {
            process.env.REACT_APP_ENV=='development' && <button
            type="button"
            onClick={onAutoFill}
            className="text-white font-semibold text-base tracking-wide shadow-md bg-blue-500 rounded-full cursor-pointer hover:bg-blue-600 transition-colors ease-linear duration-150 border-solid border-blue-500 mt-4 col-span-2 w-full py-2"
          >
            Autofill test details
          </button>
          }

            <div className="my-8">
              <PrimaryButton inProgress={inProgress} disabled={submitDisabled}>
                {nextButtonLabel}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default UserInfoForm;
