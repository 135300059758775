// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import axios from 'axios';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};
const get = (path, params) => {
  const url = `${apiBaseUrl()}${path}${params}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };

  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    console.log('contentType', contentType);
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const fileUpload = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  return axios
    .post(url, body, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};
export const initiateCardPrivileged = body => {
  return post('/api/initiate-card-privileged', body);
};
export const initiatePayment = body => {
  return post('/api/initiate-payment', body);
};

export const initiateCardAndWalletPrivileged = body =>
  post('/api/initiate-priveleged-with-card-and-wallet', body);
export const initiatePaymentWithCardAndWallet = body =>
  post('/api/initiate-payment-with-card-and-wallet', body);
export const initiateWalletConfirm = body => post('/api/confirm-wallet-payment', body);
// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const connectMangoPayWithAPI = body => post('/api/connect-mangopay', body);
export const connectMangoPayPayerWithAPI = body => post('/api/connect-payer-mangopay', body);
export const setUpMangoPayPaymentMethodWithAPI = body =>
  post('/api/setup-mangopay-payment-method', body);
export const createBankAccountWithAPI = body => post('/api/create-bank-account', body);
export const validateKYCWithAPI = body => fileUpload('/api/validate-kyc', body, true);

export const registerCard = (body = {}) => post('/api/register-card', body);
export const updateCardRegistrationData = body => post('/api/update-card-registration-data', body);
export const getCurrentUserCards = queryParams => {
  const params = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';
  return get(`/api/current-user-cards`, params);
};

export const addAmountToWallet = body => post('/api/add-amount-to-wallet', body);
export const getWalletBalance = queryParams => {
  const params = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';
  return get('/api/wallet-balance', params);
};
export const getWalletBalanceForUserFromAPI = (queryParams = {}) => {
  const params = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';
  return get('/api/fetch-user-wallet-balance', params);
};

export const payoutToBankAccount = body => post('/api/payout-to-bank-account', body);
export const fetchPayoutsFromAPI = (queryParams = {}) => {
  const params = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';
  return get('/api/payouts', params);
};

export const cancelOrderFromAPI = body => post('/api/cancel', body);

export const completeOrderFromAPI = body => post('/api/complete-transaction', body);

export const createParcelLabel = body => post('/api/create-parcel-label', body);
export const createReturnParcel = body => post('/api/return-parcel', body);

// paypal payment with cancel for parcel return

export const createPaypalOrder = body => post('/api/create-paypal-order', body);
export const capturePaypalOrder = body => post('/api/capture-paypal-payment', body);
export const refundPaypalOrder = body => post('/api/refund-paypal-payment', body);
export const fetchSellerWalletBalance = body => post('/api/seller-wallet-balance', body);
export const addListingToWishlist = body => post('/api/add-to-wishlist', body);
export const addAndRemoveSellerToFavourites = body => post('/api/add-seller-in-favourites', body);
export const showFavouriteSellers = body => post('/api/show-fav-seller', body);
export const getOldTransactionData = params => {
  return post('/api/check-transaction', params);
};
export const createBatchOrder = body => post('/api/create-batch', body);
export const approveModifyOrder = body => post('/api/approve-modify', body);

export const holidayModeApi = body => post('/api/holiday-mode', body);
export const queryOwnListingsFromAPI = queryParams => {
  return post(`/api/query-own-listings`, queryParams);
};
